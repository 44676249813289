<template>
  <div class="h-screen">
    <div>
      <nav-bar
        title="Login"
        srcPictureRight="faqIcon.svg"
        srcPictureLeft="backIcon.svg"
        :onClickBack="goBack"
      />
      <div class="font-semibold text-xl mt-5 mb-3 text-center">Set up PIN</div>
      <div class="text-center mb-2">
        <otp-input
          class="mx-auto"
          type="password"
          placeholder="__"
          :isValid="isCodeValid"
          :digits="digits"
          :keyNumber="keyNumber"
          @on-complete="onCompleteHandler"
          @on-changed="onChangedHandler"
          @on-paste="onPasteHandler"
        >
          <template #errorMessage> There is an error </template>
        </otp-input>
      </div>
      <div class="mx-[30px] text-center mb-5">
        4-digit PIN diperlukan sebelum melakukan transaksi penarikan gaji.
      </div>
      <key-pad @clickNumber="clickNumber($event)" />
    </div>
  </div>
</template>
<script>
import { ProfileController } from "@/controllers/ProfileControllers.js";
import { setStagingPin } from "@/utils/localstorage";
import OtpInput from "@/components/OtpInput.vue";
import NavBar from "@/components/NavBar.vue";
// import PinInput from "@/components/PinInput.vue";
import KeyPad from "@/components/KeyPad.vue";
export default {
  name: "SetupPin",
  data() {
    return {
      Profile: new ProfileController(false, false, ""),
      isCodeValid: true,
      digits: 4,
      keyNumber: null,
    };
  },
  components: {
    NavBar,
    OtpInput,
    KeyPad,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.profile());
  },
  methods: {
    clickNumber(evt) {
      if (evt == this.keyNumber) {
        this.keyNumber = `$${evt}`;
      } else {
        this.keyNumber = `${evt}`;
      }
    },

    onCompleteHandler(code) {
      setStagingPin(code);
      this.$router.push("/setuppin/confirmation");
    },

    onChangedHandler(lastEnteredCode) {
      this.isCodeValid = true;
    },

    onPasteHandler(code) {},
    goBack() {
      this.$router.push("/login");
    },
    async getprofile() {
      return this.Profile.getProfile();
    },
    async profile() {
      await this.getprofile();
    },
  },
  created() {
    this.profile();
  },
};
</script>