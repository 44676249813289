<template>
  <div class="animate-pulse">
     <div class="bg-[#034AA7] h-5 w-40 text-xl rounded-full text-white mb-3 font-semibold">
            
          </div>
          <div class="bg-[#034AA7] h-5 rounded-full w-40 text-white">
            
          </div>
  </div>
</template>

<script>
export default {
name: "HomeLoadingBlue"
}
</script>

<style>

</style>