import { render, staticRenderFns } from "./SuccessBlue.vue?vue&type=template&id=48151320&scoped=true&"
import script from "./SuccessBlue.vue?vue&type=script&lang=js&"
export * from "./SuccessBlue.vue?vue&type=script&lang=js&"
import style0 from "./SuccessBlue.vue?vue&type=style&index=0&id=48151320&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48151320",
  null
  
)

export default component.exports