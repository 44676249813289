<template>
  <div class="h-screen">
    <div>
      <navbar-white
      textColor="text-black" srcPictureLeft="backIconBlack.svg" :onClickBack="goBack" />
      <div class="font-semibold text-xl mt-5 text-center">
        Konfirmasi Ulang PIN
      </div>
      <div class="mt-2 mb-3 text-center">Ketik Ulang PIN Baru Hakku</div>
      <div class="text-center mb-9">
        <otp-input
          class="mx-auto"
          type="password"
          placeholder="__"
          :isValid="isCodeValid"
          :digits="digits"
          :keyNumber="keyNumber"
          @on-complete="onCompleteHandler"
          @on-changed="onChangedHandler"
          @on-paste="onPasteHandler"
        >
          <template #errorMessage> Pin Tidak Sama </template>
        </otp-input>
      </div>
      <div
        class="
          mx-[30px]
          font-semibold
          cursor-pointer
          text-center
          mb-5
          text-green-button
        "
      >
        <span> </span>
      </div>
      <key-pad @clickNumber="clickNumber($event)" />
    </div>
  </div>
</template>
<script>
import { PinController } from "@/controllers/PinController.js";
import { getStagingPin } from "@/utils/localstorage";
import OtpInput from "@/components/OtpInput.vue";
import NavbarWhite from "@/components/NavbarWhite.vue";
// import PinInput from "@/components/PinInput.vue";
import KeyPad from "@/components/KeyPad.vue";
export default {
  name: "NewPinConfirm",
  data() {
    return {
      isCodeValid: true,
      digits: 4,
      keyNumber: null,
      pin: new PinController(false, false, ""),
    };
  },
  components: {
    NavbarWhite,
    OtpInput,
    KeyPad,
  },
  methods: {
    clickNumber(evt) {
      if (evt == this.keyNumber) {
        this.keyNumber = `$${evt}`;
      } else {
        this.keyNumber = `${evt}`;
      }
    },

    onCompleteHandler(code) {
      if (getStagingPin() == code) {
        this.ChangePin(code);
      } else {
        this.isCodeValid = false;
      }
    },

    onChangedHandler(lastEnteredCode) {
      this.isCodeValid = true;
    },

    onPasteHandler(code) {},
    goBack() {
      this.$router.push("/login");
    },

    async doChangePin(pin) {
      return this.pin.changePIN(pin);
    },
    async ChangePin(code) {
      await this.doChangePin(code);
      if (!this.isError) {
        this.$router.push("/changepin/success");
      }
    },
  },
  computed: {
    isError() {
      return this.pin.error;
    },

    errorCause() {
      return this.pin.errorCause;
    },

    isLoading() {
      return this.pin.loading;
    },
  },
};
</script>