<template>
  <div>
    <div v-if="isLoading" class="mt-11">
      <loading-blue />
    </div>
    <div v-else>
      <div v-if="HistoryDetail.status == 'paid'">
        <transaction-completed :item="HistoryDetail" />
      </div>
      <div v-if="HistoryDetail.status == 'approved'">
        <TransactionAprroval :item="HistoryDetail" />
      </div>
      <div v-if="HistoryDetail.status == 'waiting_for_approval'">
        <waiting-approval :item="HistoryDetail" />
      </div>
      <div v-if="HistoryDetail.status == 'cancelled'">
        <transaction-canceled :item="HistoryDetail" />
      </div>
      <div v-if="HistoryDetail.status == 'rejected'">
        <transaction-rejected :item="HistoryDetail" />
      </div>
    </div>
  </div>
</template>
<script>
import TransactionRejected from "@/components/TransactionRejected.vue";
import LoadingBlue from "@/components/LoadingBlue.vue";
import { HistoryController } from "@/controllers/HistoryController.js";
import WaitingApproval from "@/components/WaitingApproval.vue";
import TransactionCanceled from "@/components/TransactionCanceled.vue";
import TransactionCompleted from "@/components/TransactionCompleted.vue";
import TransactionAprroval from "@/components/TransactionAprroval.vue";
export default {
  name: "TransactionDetail",
  components: {
    TransactionRejected,
    WaitingApproval,
    TransactionCanceled,
    LoadingBlue,
    TransactionCompleted,
    TransactionAprroval,
  },
  data() {
    return {
      History: new HistoryController(false, false, ""),
    };
  },
  computed: {
    isError() {
      return this.History.error;
    },
    HistoryDetail() {
      return this.History.historyDetail;
    },
    errorCause() {
      return this.History.errorCause;
    },

    isLoading() {
      return this.History.loading;
    },
  },
  methods: {
    async getDetailHistory(id) {
      return this.History.getHistoryDetail(id);
    },
    async gethistoryDetail() {
      await this.getDetailHistory(this.$route.params.id);
    },
  },
  created() {
    this.gethistoryDetail();
  },
};
</script>
<style scoped>
</style>