<template>
  <button
       type="button"
    :disabled="true"
      class="
        w-full
        rounded-[32px]
        bg-slate-300		
        text-white
        h-12
        text-xl
        font-semibold
        mx-auto
        ripple
      "
    >
      <slot>Button</slot>
    </button>
</template>

<script>
export default {

}
</script>

<style>

</style>