import axios from 'axios'
import { BaseUrl } from '../endpoint/BaseUrl'
import { SendVerifyPin, VerifyPin, ChangePin,SetupPin, } from '../endpoint/index'
import { getToken } from '../../utils/localstorage'

const headers = {
    'Authorization': `Bearer ${getToken()}`
}

export const SetupPinRequest = (pin) => {
    const resp = axios({
        method: 'post',
        url: `${BaseUrl()}${SetupPin()}`,
        data: {
            pin: pin,
        },
        headers: headers
    }).then((response) => {
        return response
    })
    return resp
}


export const SendVerifyPinRequest = (email) => {
    let resp = null;
    resp = axios({
        method: 'post',
        url: `${BaseUrl()}${SendVerifyPin()}`,
        headers: headers,
        data: {
            email: email
        }
    }).then((response) => {
        return response
    })
    return resp
}

export const VerifyPinRequest = (verifyCode) => {
    let resp = null;
    resp = axios({
        method: 'post',
        url: `${BaseUrl()}${VerifyPin()}`,
        headers: headers,
        data: {
            verifyCode: verifyCode
        }
    }).then((response) => {
        return response
    })
    return resp
}

export const changePinRequest = (pin) => {
    let resp = null;
    resp = axios({
        method: 'put',
        url: `${BaseUrl()}${ChangePin()}`,
        headers: headers,
        data: {
            pin: pin
        }
    }).then((response) => {
        return response
    })
    return resp
}