<template>
  <header class="sticky h-14 top-0 z-50 absolute bg-nav-blue">
    <div class="flex flex-row">
      <div class="absolute" v-if="srcPictureLeft">
        <img
          alt="backIcon"
          @click="onClickBack"
          class="ml-7 py-4 cursor-pointer"
          :src="require(`../assets/icon/${srcPictureLeft}`)"
        />
      </div>
      <div v-else></div>
      <div class="py-4 relative mx-auto font-semibold text-white text-xl">
        {{ title }}
      </div>
      <div v-if="srcPictureRight">
        <faq-icon :onClickFaq="toFAQ" :PictureRight="srcPictureRight" />
      </div>
      <div v-else></div>
    </div>
  </header>
</template>
<script>
import FaqIcon from "@/components/FaqIcon.vue";
export default {
  components: {
    FaqIcon,
  },
  name: "NavBar",
  props: {
    srcPictureLeft: String,
    title: String,
    srcPictureRight: String,
    onClickBack: {
      type: Function,
    },
  },
  methods: {
    toFAQ() {
      this.$router.push("/faq");
    },
  },
};
</script>