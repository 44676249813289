var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-screen"},[_c('div',{staticClass:"modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto",attrs:{"id":"confirmModalPassword","data-bs-backdrop":"static","tabindex":"-1","aria-labelledby":"exampleModalCenterTitle","aria-modal":"true","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered relative w-auto pointer-events-none"},[_c('div',{staticClass:"modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"},[_c('div',{staticClass:"modal-body relative p-4"},[_c('div',{staticClass:"text-xl font-semibold text-center mb-2"},[_vm._v(" Email Terkirim ")]),_c('p',{staticClass:"p-4 mb-1 text-center"},[_vm._v(" Kami telah mengirimkan email ke "+_vm._s(_vm.setEmail)+" untuk melakukan pengaturan password Anda. ")]),_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"basis-1/3"}),_c('div',{staticClass:"basis-1/3"},[_c('button-primary',{attrs:{"data-bs-dismiss":"modal","onClick":_vm.test2}},[_vm._v(" OK ")])],1),_c('div',{staticClass:"basis-1/3"})])])])])]),_c('div',[_c('navbar-white',{attrs:{"textColor":"text-black","title":"Password","onClickBack":_vm.goBack,"srcPictureLeft":"backIconBlack.svg"}}),_c('div',{staticClass:"mt-3"},[_c('p',{staticClass:"mx-[30px]"},[_vm._v(" Password harus terdiri dari minimal 6 karakter dan kombinasi antara huruf dan angka. ")]),_c('div',{staticClass:"mt-4 mx-[30px]"},[_c('form',[_c('div',{staticClass:"relative w-full"},[(_vm.showPassword)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordOld),expression:"passwordOld"}],staticClass:"text-black block w-full p-2.5 focus:outline-0 focus:ring-0 focus:outline-none focus:border-b-[#7b7b7b] border-none",class:{
                'mb-4': !_vm.errorMsg.passwordOld,
                'inp-noerror': !_vm.errorMsg.passwordOld,
                'inp-error': _vm.errorMsg.passwordOld,
              },attrs:{"type":"text","placeholder":"Password Lama"},domProps:{"value":(_vm.passwordOld)},on:{"input":function($event){if($event.target.composing)return;_vm.passwordOld=$event.target.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordOld),expression:"passwordOld"}],staticClass:"text-black block w-full p-2.5 focus:outline-0 focus:ring-0 focus:outline-none focus:border-b-[#7b7b7b] border-none",class:{
                'mb-4': !_vm.errorMsg.passwordOld,
                'inp-noerror': !_vm.errorMsg.passwordOld,
                'inp-error': _vm.errorMsg.passwordOld,
              },attrs:{"type":"password","placeholder":"Password Lama"},domProps:{"value":(_vm.passwordOld)},on:{"input":function($event){if($event.target.composing)return;_vm.passwordOld=$event.target.value}}}),_c('div',{staticClass:"absolute inset-y-0 right-2 flex items-center text-base leading-5 cursor-pointer"},[_c('span',{staticClass:"bg-grey-darker"},[_c('i',{staticClass:"fa-lg fa-regular",class:{
                    'fa-eye-slash': !_vm.showPassword,
                    'fa-eye': _vm.showPassword,
                  },on:{"click":function($event){return _vm.toggleShow(_vm.showPassword)}}})])])]),(_vm.errorMsg.passwordOld)?_c('p',{staticClass:"text-red-star mb-4"},[_vm._v(" "+_vm._s(_vm.errorMsg.passwordOld)+" ")]):_vm._e(),_c('div',{staticClass:"relative w-full"},[(_vm.showPassword1)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordNew),expression:"passwordNew"}],staticClass:"text-black block w-full p-2.5 focus:outline-0 focus:ring-0 focus:outline-none focus:border-b-[#7b7b7b] border-none",class:{
                'mb-4': !_vm.errorMsg.passwordNew,
                'inp-noerror': !_vm.errorMsg.passwordNew,
                'inp-error': _vm.errorMsg.passwordNew,
              },attrs:{"type":"text","placeholder":"Password Baru"},domProps:{"value":(_vm.passwordNew)},on:{"input":function($event){if($event.target.composing)return;_vm.passwordNew=$event.target.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordNew),expression:"passwordNew"}],staticClass:"text-black block w-full p-2.5 focus:outline-0 focus:ring-0 focus:outline-none focus:border-b-[#7b7b7b] border-none",class:{
                'mb-4': !_vm.errorMsg.passwordNew,
                'inp-noerror': !_vm.errorMsg.passwordNew,
                'inp-error': _vm.errorMsg.passwordNew,
              },attrs:{"type":"password","placeholder":"Password Baru"},domProps:{"value":(_vm.passwordNew)},on:{"input":function($event){if($event.target.composing)return;_vm.passwordNew=$event.target.value}}}),_c('div',{staticClass:"absolute inset-y-0 right-2 flex items-center text-base leading-5 cursor-pointer"},[_c('span',{staticClass:"bg-grey-darker"},[_c('i',{staticClass:"fa-lg fa-regular",class:{
                    'fa-eye-slash': !_vm.showPassword1,
                    'fa-eye': _vm.showPassword1,
                  },on:{"click":function($event){return _vm.toggleShow1()}}})])])]),(_vm.errorMsg.passwordNew)?_c('p',{staticClass:"text-red-star mb-4"},[_vm._v(" "+_vm._s(_vm.errorMsg.passwordNew)+" ")]):_vm._e(),_c('div',{staticClass:"relative w-full"},[(_vm.showPassword2)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirm),expression:"passwordConfirm"}],staticClass:"text-black block w-full p-2.5 focus:outline-0 focus:ring-0 focus:outline-none focus:border-b-[#7b7b7b] border-none",class:{
                'mb-4': !_vm.errorMsg.passwordConfirm,
                'inp-noerror': !_vm.errorMsg.passwordConfirm,
                'inp-error': _vm.errorMsg.passwordConfirm,
              },attrs:{"type":"text","placeholder":"Konfirmasi Password Baru"},domProps:{"value":(_vm.passwordConfirm)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.changePass.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.passwordConfirm=$event.target.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirm),expression:"passwordConfirm"}],staticClass:"text-black block w-full p-2.5 focus:outline-0 focus:ring-0 focus:outline-none focus:border-b-[#7b7b7b] border-none",class:{
                'mb-4': !_vm.errorMsg.passwordConfirm,
                'inp-noerror': !_vm.errorMsg.passwordConfirm,
                'inp-error': _vm.errorMsg.passwordConfirm,
              },attrs:{"type":"password","placeholder":"Konfirmasi Password Baru"},domProps:{"value":(_vm.passwordConfirm)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.changePass.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.passwordConfirm=$event.target.value}}}),_c('div',{staticClass:"absolute inset-y-0 right-2 flex items-center text-base leading-5 cursor-pointer"},[_c('span',{staticClass:"bg-grey-darker"},[_c('i',{staticClass:"fa-lg fa-regular",class:{
                    'fa-eye-slash': !_vm.showPassword2,
                    'fa-eye': _vm.showPassword2,
                  },on:{"click":_vm.toggleShow2}})])])]),(_vm.errorMsg.passwordConfirm)?_c('p',{staticClass:"text-red-star mb-4"},[_vm._v(" "+_vm._s(_vm.errorMsg.passwordConfirm)+" ")]):_vm._e(),_c('div',{staticClass:"w-32 text-lg font-semibold cursor-pointer text-green-button hover:text-green-button-darker cursor-pointer",attrs:{"data-bs-toggle":"modal","data-bs-target":"#confirmModalPassword"},on:{"click":_vm.resetPass}},[_vm._v(" Lupa Password ")])])]),_c('div',{staticClass:"w-1/2 mt-5 mx-auto"},[_c('button-primary',{attrs:{"isLoading":_vm.isLoading,"onClick":_vm.changePass}},[_vm._v("Ganti Password")])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }