var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative h-screen"},[_c('NavBar',{attrs:{"onClickBack":_vm.toLogin,"title":"Register","srcPictureLeft":"backIcon.svg"}}),_c('div',{staticClass:"mt-4"},[_c('form',[_c('div',{staticClass:"mb-4 mx-[30px]"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"shadow appearance-none outline outline-1 rounded border-none w-full py-2 px-3 text-grey-darker h-11",class:{
            'outline-grey-input': !_vm.errorMsg.nama,
            'outline-red-star': _vm.errorMsg.nama,
          },attrs:{"id":"nama","type":"text","placeholder":"Contoh: John Doe"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}}),(_vm.errorMsg.nama)?_c('p',{staticClass:"text-red-star"},[_vm._v(" "+_vm._s(_vm.errorMsg.nama)+" ")]):_vm._e()]),_c('div',{staticClass:"mb-4 mx-[30px]"},[_vm._m(1),_c('div',{staticClass:"flex"},[_c('span',{staticClass:"border rounded-l h-11 pt-2 pl-2 flex w-20 bg-[#EDEDED]",class:{
              'border-grey-input': !_vm.errorMsg.noTelp,
              'border-red-star': _vm.errorMsg.noTelp,
            }},[_vm._v(" +62 "),_c('img',{staticClass:"w-4 mx-auto -mt-2",attrs:{"src":require("@/assets/icon/Group 95.svg"),"alt":"flag"}})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNo),expression:"phoneNo"}],staticClass:"shadow outline outline-1 rounded-r w-full border-none px-3 text-grey-darker mt-[1px] h-[42px]",class:{
              'outline-grey-input': !_vm.errorMsg.noTelp,
              'outline-red-star': _vm.errorMsg.noTelp,
            },attrs:{"pattern":"[0-9]*","inputmode":"numeric","id":"password","type":"number","placeholder":"Contoh: 8123456789"},domProps:{"value":(_vm.phoneNo)},on:{"input":[function($event){if($event.target.composing)return;_vm.phoneNo=$event.target.value},function($event){return _vm.FilterPhoneCharacter()}]}})]),(_vm.errorMsg.noTelp)?_c('p',{staticClass:"text-red-star"},[_vm._v(" "+_vm._s(_vm.errorMsg.noTelp)+" ")]):_vm._e()]),_c('div',{staticClass:"mb-4 mx-[30px]"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"shadow appearance-none outline outline-1 rounded border-none w-full py-2 px-3 text-grey-darker h-11",class:{
            'outline-grey-input': !_vm.errorMsg.email,
            'outline-red-star': _vm.errorMsg.email,
          },attrs:{"id":"email","type":"email","placeholder":"Contoh: johndoe@email.com"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),(_vm.errorMsg.email)?_c('p',{staticClass:"text-red-star"},[_vm._v(" "+_vm._s(_vm.errorMsg.email)+" ")]):_vm._e()]),_c('div',{staticClass:"mb-4 mx-[30px]"},[_vm._m(3),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.namaPerusahaan),expression:"namaPerusahaan"}],staticClass:"shadow appearance-none outline outline-1 rounded border-none w-full py-2 px-3 text-grey-darker h-11",class:{
            'outline-grey-input': !_vm.errorMsg.company,
            'outline-red-star': _vm.errorMsg.company,
          },attrs:{"id":"namaPerusahaan","type":"text","placeholder":"Contoh: PT. Indonesia Jaya"},domProps:{"value":(_vm.namaPerusahaan)},on:{"input":function($event){if($event.target.composing)return;_vm.namaPerusahaan=$event.target.value}}}),(_vm.errorMsg.company)?_c('p',{staticClass:"text-red-star"},[_vm._v(" "+_vm._s(_vm.errorMsg.company)+" ")]):_vm._e()]),_c('div',{staticClass:"mb-4 mx-[30px]"},[_vm._m(4),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"shadow outline border-none outline-1 rounded w-full py-2 px-3 h-11",class:{
            'outline-grey-input': !_vm.errorMsg.employee,
            'outline-red-star': _vm.errorMsg.employee,
          },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{staticClass:"text-grey-darker",attrs:{"disabled":"","value":""}},[_vm._v(" Pilih Jumlah ")]),_vm._l((_vm.employee),function(option,index){return _c('option',{key:index,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])})],2),(_vm.errorMsg.employee)?_c('p',{staticClass:"text-red-star"},[_vm._v(" "+_vm._s(_vm.errorMsg.employee)+" ")]):_vm._e()]),_c('div',{staticClass:"mb-4 mx-[30px]"},[_vm._m(5),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.alamatPerusahaan),expression:"alamatPerusahaan"}],staticClass:"shadow appearance-none outline outline-1 rounded w-full border-none py-2 px-3 text-grey-darker h-[75px]",class:{
            'outline-grey-input': !_vm.errorMsg.address,
            'outline-red-star': _vm.errorMsg.address,
          },staticStyle:{"resize":"none"},attrs:{"id":"namaPerusahaan","type":"text","placeholder":"Max. 150 Karakter"},domProps:{"value":(_vm.alamatPerusahaan)},on:{"input":[function($event){if($event.target.composing)return;_vm.alamatPerusahaan=$event.target.value},function($event){return _vm.FilterCharacter()}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.Regist.apply(null, arguments)}}}),_vm._v(" "),(_vm.errorMsg.address)?_c('p',{staticClass:"text-red-star"},[_vm._v(" "+_vm._s(_vm.errorMsg.address)+" ")]):_vm._e()]),_c('div',{staticClass:"mb-4 mx-[30px]"},[_c('div',{staticClass:"flex"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkBox),expression:"checkBox"}],staticClass:"w-6 h-6 border border-grey-input rounded",attrs:{"type":"checkbox","id":"checkbox"},domProps:{"checked":Array.isArray(_vm.checkBox)?_vm._i(_vm.checkBox,null)>-1:(_vm.checkBox)},on:{"change":function($event){var $$a=_vm.checkBox,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkBox=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkBox=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkBox=$$c}}}})]),_vm._m(6)]),(_vm.errorMsg.checkBox)?_c('p',{staticClass:"text-red-star text-center"},[_vm._v(" "+_vm._s(_vm.errorMsg.checkBox)+" ")]):_vm._e()]),_c('div',{staticClass:"mx-[30px]"},[_c('button-primary',{attrs:{"isLoading":_vm.isLoading,"onClick":_vm.Regist}},[_vm._v("Daftar")])],1),_c('div',{staticClass:"text-transparent"},[_vm._v("p")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"block text-black-form text-lg font-normal mb-2",attrs:{"for":"nama"}},[_vm._v(" Nama Lengkap"),_c('span',{staticClass:"text-red-star"},[_c('sup',[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"block text-black-form text-lg font-normal mb-2",attrs:{"for":"password"}},[_vm._v(" Nomor Telepon"),_c('span',{staticClass:"text-red-star"},[_c('sup',[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"block text-black-form text-lg font-normal mb-2",attrs:{"for":"email"}},[_vm._v(" Email"),_c('span',{staticClass:"text-red-star"},[_c('sup',[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"block text-black-form text-lg font-normal mb-2",attrs:{"for":"namaPerusahaan"}},[_vm._v(" Nama Perusahaan"),_c('span',{staticClass:"text-red-star"},[_c('sup',[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"block text-black-form text-lg font-normal mb-2",attrs:{"for":"namaPerusahaan"}},[_vm._v(" Jumlah Karyawan"),_c('span',{staticClass:"text-red-star"},[_c('sup',[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"block text-black-form text-lg font-normal mb-2",attrs:{"for":"namaPerusahaan"}},[_vm._v(" Alamat Perusahaan"),_c('span',{staticClass:"text-red-star"},[_c('sup',[_vm._v("*")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"-mt-1 ml-3"},[_c('label',{staticClass:"text-base",attrs:{"for":"checkbox"}},[_vm._v(" Saya bersedia dihubungi oleh pihak hakku untuk penawaran lebih lanjut")])])
}]

export { render, staticRenderFns }