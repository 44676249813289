<template>
<div class="animate-pulse">
    <div
          
            class="
            
              w-full
              h-[110px]
              nt:h-[125px]
              hover:bg-[#F2F5FA]
              bg-white
              p-4
              border-b-2 border-[#EDEDED]
            "
          >
            <div class="flex flex-col">
              <div class="w-full text-base h-5 rounded-full bg-slate-300 mb-1">
                
              </div>
               <div class="w-3/4 text-base h-5 rounded-full bg-slate-300 mb-1">
                
              </div>
              <div class="w-6/12 text-base h-5 rounded-full bg-slate-300 mb-1">
                
              </div>
              <div
                class="flex  flex-row justify-start text-sm text-loading-greytxt"
              >
                <div class="mr-1 w-5 h-5 rounded-full bg-slate-300 mr-1">
                  
                </div>
                <div class="mr-1 w-24 h-5 rounded-full bg-slate-300">
                   </div>
                <div class=" h-5 w-14 rounded-full bg-slate-300">
                 </div>
              </div>
            </div>
          </div>


            <div
          
            class="
            
              w-full
              h-[110px]
              nt:h-[125px]
              hover:bg-[#F2F5FA]
              bg-white
              p-4
              border-b-2 border-[#EDEDED]
            "
          >
            <div class="flex flex-col">
              <div class="w-full text-base h-5 rounded-full bg-slate-300 mb-1">
                
              </div>
              <div class="w-6/12 text-base h-5 rounded-full bg-slate-300 mb-1">
                
              </div>
               <div class="w-full text-base h-5 rounded-full bg-slate-300 mb-1">
                
              </div>
              <div
                class="flex flex-row justify-start text-sm text-loading-greytxt"
              >
                <div class="mr-1 w-5 h-5 rounded-full bg-slate-300 mr-1">
                  
                </div>
                <div class="mr-1 w-24 h-5 rounded-full bg-slate-300">
                   </div>
                <div class=" h-5 w-14 rounded-full bg-slate-300">
                 </div>
              </div>
            </div>
          </div>
</div>
         
 
</template>

<script>
export default {
name:"ActivityLoading"
}
</script>

<style>

</style>