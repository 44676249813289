<template>
  <div class="relative h-screen">
    <div class="h-[94%]">
      <navbar-white
      textColor="text-white"
        :onClickBack="goBack"
        title="Cara Menggunakan Hakku"
        srcPictureLeft="backIcon.svg"
        class="bg-nav-blue"
      />
      <div class="mt-7">
        <ul class="list-decimal ml-14">
          <li class="px-4 text-lg mb-2 font-bold">Daftar</li>
          <p class="px-4 mb-5">
            Pastikan perusahaan Anda telah terdaftar di sistem hakku. Formulir
            pendaftaran tersedia di halaman depan aplikasi hakku.
          </p>
          <li class="px-4 text-lg mb-2 font-bold">Terhubung</li>
          <p class="px-4 mb-5">
            Tim hakku akan menghubungi karyawan melalui email untuk mendapatkan
            akses masuk ke aplikasi hakku.
          </p>

          <li class="px-4 text-lg mb-2 font-bold">Masuk</li>
          <p class="px-4 mb-5">
            Karyawan memasukkan email dan password untuk masuk ke aplikasi
            hakku.
          </p>
          <li class="px-4 text-lg mb-2 font-bold">Tarik Gaji</li>
          <p class="px-4 mb-5">
            Masukkan nominal penarikan sesuai kebutuhan dan tarik gaji kapan pun
            dibutuhkan.
          </p>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import NavbarWhite from "@/components/NavbarWhite.vue";
export default {
  name: "FAQ",
  components: {
    NavbarWhite,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style>
</style>