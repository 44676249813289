<template>
  <div class="mx-[30px] text-2xl mt-11">
    <div class="flex flex-row justify-evenly">
      <div
        class="
          text-center
          py-5
          px-7
          hover:bg-[#F2F5FA] hover:rounded-lg
          font-semibold
          cursor-pointer
        "
        @click="clicked(1)"
      >
        1
      </div>
      <div
        class="
          text-center
          py-5
          px-7
          hover:bg-[#F2F5FA] hover:rounded-lg
          font-semibold
          cursor-pointer
        "
        @click="clicked(2)"
      >
        2
      </div>
      <div
        class="
          text-center
          py-5
          px-7
          hover:bg-[#F2F5FA] hover:rounded-lg
          font-semibold
          cursor-pointer
        "
        @click="clicked(3)"
      >
        3
      </div>
    </div>
    <div class="flex flex-row justify-evenly">
      <div
        class="
          text-center
          py-5
          px-7
          hover:bg-[#F2F5FA] hover:rounded-lg
          font-semibold
          cursor-pointer
        "
        @click="clicked(4)"
      >
        4
      </div>
      <div
        class="
          text-center
          py-5
          px-7
          hover:bg-[#F2F5FA] hover:rounded-lg
          font-semibold
          cursor-pointer
        "
        @click="clicked(5)"
      >
        5
      </div>
      <div
        class="
          text-center
          py-5
          px-7
          hover:bg-[#F2F5FA] hover:rounded-lg
          font-semibold
          cursor-pointer
        "
        @click="clicked(6)"
      >
        6
      </div>
    </div>
    <div class="flex flex-row justify-evenly">
      <div
        class="
          text-center
          font-semibold
          py-5
          px-7
          hover:bg-[#F2F5FA] hover:rounded-lg
          cursor-pointer
        "
        @click="clicked(7)"
      >
        7
      </div>
      <div
        class="
          text-center
          py-5
          px-7
          hover:bg-[#F2F5FA] hover:rounded-lg
          font-semibold
          cursor-pointer
        "
        @click="clicked(8)"
      >
        8
      </div>
      <div
        class="
          text-center
          py-5
          px-7
          hover:bg-[#F2F5FA] hover:rounded-lg
          font-semibold
          cursor-pointer
        "
        @click="clicked(9)"
      >
        9
      </div>
    </div>
    <div class="flex flex-row justify-evenly">
      <div class="text-center font-semibold"></div>
      <div
        class="
          ml-20
          font-semibold
          py-5
          px-7
          hover:bg-[#F2F5FA] hover:rounded-lg
          cursor-pointer
        "
        @click="clicked(0)"
      >
        0
      </div>
      <div
        class="
          text-center
          py-5
          px-7
          hover:bg-[#F2F5FA] hover:rounded-lg
          font-semibold
          cursor-pointer
        "
      >
        <img
          :src="require(`@/assets/icon/deleteIcon.svg`)"
          @click="clicked('x')"
          alt="backSpace"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "KeyPad",
  props: {},
  methods: {
    clicked(number) {
      this.$emit("clickNumber", number);
    },
  },
};
</script>