<template>
  <success-blue
    :onClick="toHome"
    msg="Berhasil"
    msgDetail="Setup PIN Berhasil"
  />
</template>
<script>
import CryptoJS from "crypto-js";
import {
  getStagingPin,
  setOldPin,
  removeStagingPin,
} from "@/utils/localstorage";
import SuccessBlue from "@/components/SuccessBlue.vue";
export default {
  name: "Success",
  components: {
    SuccessBlue,
  },
  methods: {
    toHome() {
      setOldPin(getStagingPin());
      removeStagingPin();
      this.$router.push("/home");
    },
  },
};
</script>
<style scoped>
</style>