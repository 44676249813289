<template>
  <success-blue
    msg="Reset Password Berhasil"
    msgDetail="Silahkan cek email untuk konfirmasi link pengubahan password"
    :onClick="toLogin"
  />
</template>
<script>
import SuccessBlue from "@/components/SuccessBlue.vue";
export default {
  name: "ResetPasswordSuccess",
  components: {
    SuccessBlue,
  },
  methods: {
    toLogin() {
      this.$router.push("/login");
    },
  },
};
</script>