<template>
  <div class="h-screen">
    <div>
      <navbar-white
      textColor="text-black" srcPictureLeft="backIconBlack.svg" :onClickBack="goBack" />
      <div class="font-semibold text-xl mt-5 text-center">PIN Baru HAKKU</div>
      <div class="mt-2 mb-3 text-center">Masukkan 4-digit PIN Baru HAKKU</div>
      <div class="text-center mb-9">
        <otp-input
          class="mx-auto"
          type="password"
          placeholder="__"
          :isValid="isCodeValid"
          :digits="digits"
          :keyNumber="keyNumber"
          @on-complete="onCompleteHandler"
          @on-changed="onChangedHandler"
          @on-paste="onPasteHandler"
        >
          <template #errorMessage> There is an error </template>
        </otp-input>
      </div>
      <div
        class="
          mx-[30px]
          font-semibold
          cursor-pointer
          text-center
          mb-5
          text-green-button
        "
      >
        <span> </span>
      </div>
      <key-pad @clickNumber="clickNumber($event)" />
    </div>
  </div>
</template>
<script>
import { setStagingPin } from "@/utils/localstorage";
import OtpInput from "@/components/OtpInput.vue";
import NavbarWhite from "@/components/NavbarWhite.vue";
// import PinInput from "@/components/PinInput.vue";
import KeyPad from "@/components/KeyPad.vue";
export default {
  name: "NewPin",
  data() {
    return {
      isCodeValid: true,
      digits: 4,
      keyNumber: null,
    };
  },

  components: {
    NavbarWhite,
    OtpInput,
    KeyPad,
  },

  methods: {
    clickNumber(evt) {
      if (evt == this.keyNumber) {
        this.keyNumber = `$${evt}`;
      } else {
        this.keyNumber = `${evt}`;
      }
    },

    onCompleteHandler(code) {
      setStagingPin(code);
      this.$router.push("/changepin/confirmation");
    },

    onChangedHandler(lastEnteredCode) {
      this.isCodeValid = true;
    },

    onPasteHandler(code) {},
    goBack() {
      this.$router.push("/changepin");
    },
  },
};
</script>