<template>
  <div class="h-screen relative">
    <div class="h-full">
      <NavBar
        :onClickBack="toLogin"
        title="Login"
        srcPictureLeft="backIcon.svg"
        srcPictureRight="faqIcon.svg"
      />
      <div>
        <img
          class="mx-auto my-10 w-28 h-28"
          :src="require(`@/assets/icon/hakkuLogoOnly.svg`)"
          alt="logo"
        />
      </div>

      <div>
        <form>
          <div class="mx-[30px]">
            <label
              class="block text-black-form text-lg font-semibold"
              for="email"
            >
              Reset Password
            </label>
            <p class="mb-2">
              Masukan email Anda untuk melakukan pengaturan password.
            </p>

            <input
              class="
                shadow
                appearance-none
                outline outline-1
                border-none
                rounded
                w-full
                py-2
                px-3
                text-grey-darker
                h-11
              "
              :class="{
                'mb-4': !errorMsg.email,
                'outline-grey-input': !errorMsg.email,
                'outline-red-star': errorMsg.email,
              }"
              id="email"
              type="email"
              placeholder="Email"
              v-model="email"
              @keyup.enter="resetPass"
            />
          </div>
          <p v-if="errorMsg.email" class="text-red-star mx-[30px] mb-4">
            {{ errorMsg.email }}
          </p>
          <div class="mx-[30px]">
            <button-primary :isLoading="isLoading" :onClick="resetPass"
              >Kirim</button-primary
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { AuthControllers } from "@/controllers/AuthControllers.js";

import NavBar from "@/components/NavBar.vue";
import ButtonPrimary from "@/components/ButtonPrimary.vue";

export default {
  name: "ResetPassword",
  components: {
    NavBar,
    ButtonPrimary,
  },
  data() {
    return {
      auth: new AuthControllers(false, false, ""),
      email: "",
      errorMsg: {
        email: "",
      },
    };
  },
  computed: {
    isError() {
      return this.auth.error;
    },

    errorCause() {
      return this.auth.errorCause;
    },

    isLoading() {
      return this.auth.loading;
    },
  },
  watch: {
    email(value) {
      this.email = value;
      this.validateEmail(value);
    },
  },
  methods: {
    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.errorMsg.email = "";
      } else {
        this.errorMsg.email = "Email tidak Valid";
      }
      if (email == "") {
        this.errorMsg.email = "Email tidak boleh Kosong";
      }
    },
    toLogin() {
      this.$router.push("/login");
    },
    async ResetPassoword(email) {
      return this.auth.resetPassword(email);
    },
    async resetPass() {
      await this.ResetPassoword(this.email);
      if (!this.isError) {
        this.$router.push("/resetpass-success");
      } else if (this.errorCause == "Not a valid email") {
        this.errorMsg.email = "Email tidak boleh Kosong";
      } else if (this.errorCause == "user not found") {
        this.errorMsg.email = "Email Salah";
      }
    },
  },
};
</script>

<style>
</style>