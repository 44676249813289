<template>
  <success-blue
    :onClick="toLogin"
    msg="Pendaftaran Berhasil"
    msgDetail="Silahkan menunggu email balasan dari kami selama maksimal 1x24 jam"
  />
</template>
<script>
import SuccessBlue from "@/components/SuccessBlue.vue";
export default {
  name: "RegisterSuccess",
  components: {
    SuccessBlue,
  },
  methods: {
    toLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
</style>