<template>
  <!-- <div class="relative h-screen">
    <div class="h-[94%]">
      <navbar-white
      textColor="text-white"
        :onClickBack="goBack"
        title="Bantuan"
        srcPictureLeft="backIcon.svg"
        class="bg-nav-blue"
      />
      <div class="mt-7">
        
<div id="accordion-flush" data-accordion="collapse" data-active-classes="bg-white dark:bg-gray-900" data-inactive-classes="bg-white dark:bg-gray-900 text-black">
  <h2 id="accordion-flush-heading-1">
    <button type="button" class="flex items-center justify-between w-full pb-3 px-5 text-left font-medium border-b border-gray-200 dark:border-gray-700 " data-accordion-target="#accordion-flush-body-1" aria-expanded="true" aria-controls="accordion-flush-body-1">
      <span>Mengapa saat pertama kali masuk ke aplikasi, saldo hakku=0 ?</span>
      <svg data-accordion-icon class="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
    </button>
  </h2>
  <div id="accordion-flush-body-1" class="hidden" aria-labelledby="accordion-flush-heading-1">
    <div class="py-3 px-5 text-slate-700 border-b border-gray-200 dark:border-gray-700">
      <p class="mb-2">Saldo Hakku baru mulai aktif 1 hari setelah karyawan menerima email aktivasi akun dari Tim Hakku.</p>
    </div>
  </div>
  <h2 id="accordion-flush-heading-2">
    <button type="button" class="flex items-center justify-between w-full py-3 px-5 font-medium text-left border-b border-gray-200 dark:border-gray-700 " data-accordion-target="#accordion-flush-body-2" aria-expanded="false" aria-controls="accordion-flush-body-2">
      <span>Karyawan yang perusahaannya sudah terdaftar di sistem hakku.</span>
      <svg data-accordion-icon class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
    </button>
  </h2>
  <div id="accordion-flush-body-2" class="hidden" aria-labelledby="accordion-flush-heading-2">
    <div class="py-3 px-5 text-slate-700 border-b border-gray-200 dark:border-gray-700">
      <p class="mb-2">Karyawan yang perusahaannya sudah terdaftar di sistem hakku.</p>
    </div>
  </div>
  <h2 id="accordion-flush-heading-6">
    <button type="button" class="flex items-center justify-between w-full py-3 px-5 font-medium text-left border-b border-gray-200 dark:border-gray-700 " data-accordion-target="#accordion-flush-body-6" aria-expanded="false" aria-controls="accordion-flush-body-2">
      <span>Berapakah nominal yang dapat di tarik melalui hakku?</span>
      <svg data-accordion-icon class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
    </button>
  </h2>
  <div id="accordion-flush-body-6" class="hidden" aria-labelledby="accordion-flush-heading-6">
    <div class="py-3 px-5 text-slate-700 border-b border-gray-200 dark:border-gray-700">
      <p class="mb-2">Karyawan dapat melakukan penarikan maksimal 50% dari gaji bulanannya sesuai dengan limit minimum yang telah ditetapkan.</p>
      <p>Limit minimum tersebut tergantung dari penerimaan gaji masing-masing karyawan, dengan perhitungan gaji minimal 5 hari kerja dan penarikan hanya bisa dilakukan di 50%-nya saja.</p>
    </div>
  </div>
  <h2 id="accordion-flush-heading-3">
    <button type="button" class="flex items-center justify-between w-full py-3 px-5 font-medium text-left border-b border-gray-200" data-accordion-target="#accordion-flush-body-3" aria-expanded="false" aria-controls="accordion-flush-body-3">
      <span>Berapa lama dana dapat dicairkan ?</span>
      <svg data-accordion-icon class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
    </button>
  </h2>
  <div id="accordion-flush-body-3" class="hidden" aria-labelledby="accordion-flush-heading-3">
    <div class="py-3 px-5 text-slate-700 border-b border-gray-200 dark:border-gray-700">
      <p class="mb-2">Dana akan dicairkan dalam waktu maksimal 1x24 jam setelah approval.</p>
    </div>
  </div>
  <h2 id="accordion-flush-heading-4">
    <button type="button" class="flex items-center justify-between w-full py-3 px-5 font-medium text-left border-b border-gray-200 dark:border-gray-700" data-accordion-target="#accordion-flush-body-4" aria-expanded="true" aria-controls="accordion-flush-body-1">
      <span>Kapan dana harus dikembalikan ?</span>
      <svg data-accordion-icon class="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
    </button>
  </h2>
  <div id="accordion-flush-body-4" class="hidden" aria-labelledby="accordion-flush-heading-4">
    <div class="py-3 px-5 text-slate-700 border-b border-gray-200 dark:border-gray-700">
      <p class="mb-2">Gaji yang di akses karyawan melalui hakku akan dihitung sebagai potongan pada siklus gajian bulan berikutnya.</p>
    </div>
  </div>
  <h2 id="accordion-flush-heading-5">
    <button type="button" class="flex items-center justify-between w-full py-3 px-5 font-medium text-left border-b border-gray-200 dark:border-gray-700" data-accordion-target="#accordion-flush-body-5" aria-expanded="true" aria-controls="accordion-flush-body-1">
      <span>Adakah biaya untuk setiap transaksi yang dilakukan melalui aplikasi hakku ?</span>
      <svg data-accordion-icon class="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
    </button>
  </h2>
  <div id="accordion-flush-body-5" class="hidden" aria-labelledby="accordion-flush-heading-5">
    <div class="py-3 px-5 text-slate-700 border-b border-gray-200 dark:border-gray-700">
      <p class="mb-2 ">Ya, karyawan akan dikenakan biaya admin untuk setiap penarikan gaji. Jika nominal penarikan kurang dari Rp1.000.000, biaya admin = Rp27.500. Jika nominal penarikan lebih dari Rp1.000.000, biaya admin = Rp32.500.</p>
    </div>
  </div>
</div>
      </div>
    </div>
  </div> -->
  <div class="relative h-screen">
    <div class="h-[94%]">
      <navbar-white
      textColor="text-white"
        :onClickBack="goBack"
        title="Bantuan"
        srcPictureLeft="backIcon.svg"
        class="bg-nav-blue"
      />
      <div class="mt-7">
  <div>
    <div>
      <div>
        <div md="6" v-for="(item, index) in accordionItems" :key="index">
          <div class="accordion-item">
            <div class="accordion-header" @click="toggleAccordion(index)">
                          {{ item.header }}
              <button variant="light" size="sm">
                <svg data-accordion-icon class="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                   <path v-if="item.active" fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                   <path v-else fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 11-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd"></path>
                </svg>
              </button>
            </div>
            <div class="accordion-body" :class="{ active: item.active }">{{ item.body }}</div>
          </div>
          <hr>
        </div>
      </div>
    </div>
  </div>
  </div>
    </div>
  </div>
</template>
<style>
.accordion-item {
  margin-bottom: 10px;
}

.accordion-header {
  display: flex;
  font-weight: 550;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
}

.accordion-header:hover {
  background-color: #ebe9e9;
}

.accordion-header-text {
  flex-grow: 1;
}

.accordion-header-button {
  flex-basis: 30px;
  text-align: center;
}

.accordion-body {
  display: none;
  padding: 10px;
  font-size: 15px;
}

.accordion-item .accordion-body.active {
  display: block;
}

.b-button {
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
}

.b-button svg {
  width: 1em;
  height: 1em;
}


</style>
<script>
import NavbarWhite from "@/components/NavbarWhite.vue";
export default {
  name: "FAQ",
  components: {
    NavbarWhite,
  },
  data(){
    return{
            accordionItems: [
        {
          header: 'Mengapa saat pertama kali masuk ke aplikasi, saldo hakku=0 ?',
          body: 'Saldo Hakku baru mulai aktif 1 hari setelah karyawan menerima email aktivasi akun dari Tim Hakku.',
          active: true
        },
        {
          header: 'Siapa sajakah yang dapat menggunakan aplikasi hakku ?',
          body: 'Karyawan yang perusahaannya sudah terdaftar di sistem hakku.',
          active: false
        },
        {
          header: 'Adakah biaya untuk setiap transaksi yang dilakukan melalui aplikasi hakku ?',
          body: 'Ya, karyawan akan dikenakan biaya admin untuk setiap penarikan gaji. Jika nominal penarikan <= Rp1.000.000, biaya admin = Rp27.500. Jika nominal penarikan > Rp1.000.000, biaya admin = Rp32.500.',
          active: false
        },
        {
          header: 'Berapakah nominal yang dapat di tarik melalui hakku ?',
          body: 'Karyawan dapat melakukan penarikan maksimal 50% dari gaji bulanannya sesuai dengan limit minimum yang telah ditetapkan. Limit minimum tersebut tergantung dari penerimaan gaji masing-masing karyawan, dengan perhitungan gaji minimal 5 hari kerja dan penarikan hanya bisa dilakukan di 50%-nya saja.',
          active: false
        },
        {
          header: 'Berapa lama dana dapat dicairkan ?',
          body: 'Dana akan dicairkan dalam waktu maksimal 1x24 jam setelah approval.',
          active: false
        },
        {
          header: 'Kapan dana harus dikembalikan ?',
          body: 'Gaji yang di akses karyawan melalui hakku akan dihitung sebagai potongan pada siklus gajian bulan berikutnya.',
          active: false
        }
      ]
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    toggleAccordion(index) {
      this.accordionItems[index].active = !this.accordionItems[index].active;
    },
  },
};
</script>