<template>
  <div class="h-screen">
    <div>
      <nav-bar
        title="Login"
        srcPictureRight="faqIcon.svg"
        srcPictureLeft="backIcon.svg"
        :onClickBack="goBack"
      />
      <div class="font-semibold text-xl mt-5 mb-3 text-center">
        Konfirmasi Ulang PIN
      </div>
      <div class="text-center mb-2">
        <otp-input
          class="mx-auto"
          type="password"
          placeholder="__"
          :isValid="isCodeValid"
          :digits="digits"
          :keyNumber="keyNumber"
          @on-complete="onCompleteHandler"
          @on-changed="onChangedHandler"
          @on-paste="onPasteHandler"
        >
          <template #errorMessage> Pin Tidak Sama </template>
        </otp-input>
      </div>
      <div class="mx-[30px] text-center mb-5">
        4-digit PIN diperlukan sebelum melakukan transaksi penarikan gaji.
      </div>
      <key-pad @clickNumber="clickNumber($event)" />
    </div>
  </div>
</template>
<script>
import { PinController } from "@/controllers/PinController";
import { getStagingPin } from "@/utils/localstorage";
import OtpInput from "@/components/OtpInput.vue";
import NavBar from "@/components/NavBar.vue";
// import PinInput from "@/components/PinInput.vue";
import KeyPad from "@/components/KeyPad.vue";
import CryptoJS from "crypto-js";
export default {
  name: "SetupPin",
  data() {
    return {
      isCodeValid: true,
      digits: 4,
      keyNumber: null,
      PIN: new PinController(false, false, ""),
    };
  },
  components: {
    NavBar,
    OtpInput,
    KeyPad,
  },
  methods: {
    clickNumber(evt) {
      if (evt == this.keyNumber) {
        this.keyNumber = `$${evt}`;
      } else {
        this.keyNumber = `${evt}`;
      }
    },

    onCompleteHandler(code) {
      if (getStagingPin() == code) {
        this.SetupPin(code);
      } else {
        this.isCodeValid = false;
      }
    },

    onChangedHandler(lastEnteredCode) {
      this.isCodeValid = true;
    },

    onPasteHandler(code) {},
    goBack() {
      this.$router.push("/login");
    },
    async doSetupPin(pin) {
      return this.PIN.setupPin(pin);
    },
    async SetupPin(code) {
      await this.doSetupPin(code);
      if (!this.isError) {
        this.$router.push("/setuppin/success");
      }
    },
  },
  computed: {
    isError() {
      return this.PIN.error;
    },

    errorCause() {
      return this.PIN.errorCause;
    },

    isLoading() {
      return this.PIN.loading;
    },
  },
};
</script>