export const SignIn = () => {
    return `/v1/auth/login`
}

export const Profile = () => {
    return `/v1/user`
}

export const Balance = () => {
    return `/v1/user/balance`
}

export const History = (page, size,) => {
    return `/v1/request-transaction?page=${page}&size=${size}`
}

export const historyDetail = (id) => {
    return `/v1/request-transaction/${id}`
}
export const cancelTransaction = (id) => {
    return `/v1/request-transaction/${id}/cancel`
}
export const Register = () => {
    return `/v1/auth/register`
}

export const ResetPassword = () => {
    return `/v1/auth/forget-password`
}
export const ChangePassword = () => {
    return `/v1/user/change-password`
}


export const NotificationActivity = (page, size) => {
    return `/v1/notification/activity?page=${page}&size=${size}`
}
export const NotificationActivityUpdate = (id) => {
    return `/v1/notification/activity/${id}`
}
export const NotificationInbox = (page, size) => {
    return `/v1/notification/inbox?page=${page}1&size=${size}`
}

export const TotalEmployees = () => {
    return `/v1/list/total-employees`
}

export const SetupPin = () => {
    return `/v1/auth/setup/pin`
}

export const RequestInquiry = () => {
    return `/v1/request-transaction/inquiry`
}

export const RequestTransaction = () => {
    return `/v1/request-transaction`
}

export const SendVerifyPin = () => {
    return `/v1/user/forget-pin`
}

export const VerifyPin = () => {
    return `/v1/user/reset-pin`
}
export const ChangePin = () => {
    return `/v1/user/change-pin`
}
export const Download = (id) => {
    return `/v1/request-transaction/${id}/download`
}