<template>
  <input
    v-model="currentValue"
    @input="handleInput"
    type="text"
    class="
      font-semibold
      text-black text-lg
      block
      w-full
      pl-10
      p-2.5
      focus:outline-0 focus:ring-0 focus:outline-none focus:border-b-[#7b7b7b]
      border-none
      inp
    "
    @keyup.enter="inquiry"
    placeholder="0"
  />
</template>
<script>
export default {
  name: "AmountInput",
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    inquiry: {
      type: Function,
    },
  },
  data() {
    return {
      currentValue: "",
    };
  },
  watch: {
    value: {
      handler(after) {
        this.currentValue = this.format(after);
      },
      immediate: true,
    },
  },
  methods: {
    format: (value) =>
      (value + "").replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),

    handleInput() {
      this.currentValue = this.format(this.currentValue);
      this.$emit("input", (this.currentValue + "").replace(/[^0-9]/g, ""));
    },
  },
};
</script>
<style scoped>
</style>