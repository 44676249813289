
import { GetHomeRequest } from '../api/request/HomeRequest'

import { sessionExpired } from "@/utils/helper";
export class HomeController {
    loading = false;
    error = false;
    errorCause = "";

    balanceData = []

    constructor(loading, error, errorCause,) {
        this.loading = loading;
        this.error = error;
        this.errorCause = errorCause;


    }

    getBalanceData() {
        let resp = null
        this.setLoading(true)
        resp = GetHomeRequest()
            .then((response) => {

                this.setBalanceData(response.data.data)
                this.setErrorCause("")
                this.setError(false)
                return true
            }).catch((err) => {
                if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                }
                this.setErrorCause(err)
                this.setError(true)
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }
    setBalanceData(data) {

        this.balanceData = data
    }



    setLoading(status) {
        this.loading = status
    }

    setError(err) {
        this.error = err
    }

    setErrorCause(cause) {
        this.errorCause = cause
    }
}