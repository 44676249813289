<template>
  <header class="sticky h-14 top-0 z-10 bg-white" :class="boxShadow">
    <div class="flex flex-row">
      <div class="absolute" v-if="srcPictureLeft">
        <img
          alt="backIcon"
          @click="onClickBack"
          class="ml-7 py-4 cursor-pointer "
          :src="require(`../assets/icon/${srcPictureLeft}`)"
        />
      </div>
      <div v-else></div>
      <div class="py-4 relative mx-auto  font-semibold text-xl" :class="textColor">
        {{ title }}
      </div>
    </div>
  </header>
</template>
<style>
</style>
<script>
export default {
  name: "NavbarWhite",
  props: {
    onClickBack: {
      type: Function,
    },
    title: String,
    srcPictureLeft: String,
    boxShadow: String,
    textColor:String,
  },
};
</script>