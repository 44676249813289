import axios from 'axios'
import { Profile } from '../endpoint/index'
import { BaseUrl } from '../endpoint/BaseUrl'
import { getToken } from '../../utils/localstorage'
const headers = {
    'Authorization': `Bearer ${getToken()}`
}

export const GetProfileRequest = () => {
    let resp = null;
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${Profile()}`,
        headers: headers
    }).then((response) => {
        return response
    })
    return resp
}