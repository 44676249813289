<template>
  <div
    class="
      fixed
      inset-x-0
      bottom-0
      z-10
      mw:w-[480px]
      w-auto
      bg-white
      mx-auto
      h-16
      shadow-[0_-4px_4px_-2px_rgba(0,0,0,0.25)]
    "
  >
    <div class="mx-[30px] py-2">
      <button-primary :onClick="Click" :isLoading="isLoading">
        <slot>Button</slot>
      </button-primary>
    </div>
  </div>
</template>
<script>
import ButtonPrimary from "@/components/ButtonPrimary.vue";
export default {
  name: "ButtonBottom",
  components: {
    ButtonPrimary,
  },
  props: {
    Click: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style>
</style>