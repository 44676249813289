
import { CancelTransaction, GetHistoryListRequest, GetHistoryDetailRequest } from '../api/request/HistoryRequest'
import { sessionExpired } from "@/utils/helper";

export class HistoryController {
    loading = false;
    error = false;
    errorCause = "";

    historyLists = []
    historyDetail = []
    totalDataHistory

    constructor(loading, error, errorCause,) {
        this.loading = loading;
        this.error = error;
        this.errorCause = errorCause;


    }

    async getHistory(page, size,) {
        // this.setLoading(true)
        let resp = null

        resp = await GetHistoryListRequest(page, size,)
            .then((response) => {

                this.setLists(response.data.data)
                this.setErrorCause("")
                this.setError(false)

                this.setTotalData(response.data.meta.totalData)

                return true
            }).catch((err) => {

                if (err.response.data.code === 404) {

                    this.setErrorCause('Data Tidak Ditemukan')
                    this.setError(false)
                    this.setLists(err.response.data)

                } else if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err)
                    this.setError(true)
                }

                return false
            }).finally(() => {
                // this.setLoading(false)
            })
        return resp
    }

    getCancelTransaction(id) {
        this.setLoading(true)
        let resp = null

        resp = CancelTransaction(id)
            .then((response) => {

                this.setErrorCause("")
                this.setError(false)
                return true
            }).catch((err) => {

                if (err.response.data.code === 404) {
                    this.setErrorCause('Data Tidak Ditemukan')
                    this.setError(false)


                } else if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err.response.data.message)
                    this.setError(true)
                }

                return false
            }).finally(() => {
                this.setLoading(false)
            })
    }


    getHistoryDetail(id) {
        this.setLoading(true)
        let resp = null

        resp = GetHistoryDetailRequest(id)
            .then((response) => {

                this.setDetail(response.data.data)
                this.setErrorCause("")
                this.setError(false)
                return true
            }).catch((err) => {

                if (err.response.data.code === 404) {
                    this.setErrorCause('Data Tidak Ditemukan')
                    this.setError(false)
                    this.setDetail(err.response.data)

                } else if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err.response.data.message)
                    this.setError(true)
                }



                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }


    setTotalData(data) {
        this.totalDataHistory = data
    }

    setLists(data) {

        this.historyLists = data
    }
    setDetail(data) {
        this.historyDetail = data
    }


    setLoading(status) {
        this.loading = status
    }

    setError(err) {
        this.error = err
    }

    setErrorCause(cause) {
        this.errorCause = cause
    }
}