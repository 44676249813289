<template>
  <div class="relative h-full">
    <div class="">
      <navbar-white
      textColor="text-black" title="Notifikasi" />
      <div class="relative">
        <div
          class="fixed z-50 mw:w-[480px] bg-white inset-x-0 mx-auto mb-4 w-auto"
        >
          <div class="flex flex-row">
            <router-link
              v-if="errorCause =='data not found'"
              class="basis-1/2 text-center border-b-2 border-[#EDEDED]"
              to="/notification/activity"
              >Activity (0)</router-link
            >
            <router-link
            v-else
              class="basis-1/2 text-center border-b-2 border-[#EDEDED]"
              to="/notification/activity"
              >Activity ({{ ActivityList.length}})</router-link
            >

            <router-link
              class="basis-1/2 text-center border-b-2"
              to="/notification/inbox"
              >Inbox</router-link
            >
          </div>
        </div>
      </div>

      <router-view />

      <div class="h-14"></div>
    </div>
    <navbar-bottom />
  </div>
</template>

<script>
import { NotificationController } from "@/controllers/NotificationController.js";
import NavbarBottom from "@/components/NavbarBottom.vue";
import NavbarWhite from "@/components/NavbarWhite.vue";
export default {
  name: "Notification",
  components: {
    NavbarWhite,
    NavbarBottom,
  },
  data() {
    return {
      meta: {
        page: 1,
        size: "",
      },

      notif: new NotificationController(false, false, ""),
    };
  },
  computed: {
    isError() {
      return this.notif.error;
    },
    ActivityList() {
      return this.notif.activityLists;
    },
    errorCause() {
      return this.notif.errorCause;
    },

    isLoading() {
      return this.notif.loading;
    },
  },
  mounted() {
    this.getActivity();
  },
  methods: {
    async getActivitylist(page, size) {
      return this.notif.getActivityList(page, size);
    },
    async getActivity() {
      await this.getActivitylist(this.meta.page, this.meta.size);
    },
  },
  created() {},
};
</script>
<style scoped>
a:hover,
a.router-link-active {
  cursor: pointer;

  filter: brightness(0) saturate(100%) invert(7%) sepia(95%) saturate(5952%)
    hue-rotate(219deg) brightness(100%) contrast(100%);
  border-bottom: 2px solid #003399;
}
</style>