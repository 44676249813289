import { render, staticRenderFns } from "./AmountInput.vue?vue&type=template&id=2fc62a92&scoped=true&"
import script from "./AmountInput.vue?vue&type=script&lang=js&"
export * from "./AmountInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fc62a92",
  null
  
)

export default component.exports