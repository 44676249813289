<template>
  <div class="background-blue h-screen w-full relative">
    <img
      alt="bgTop"
      class="absolute top-0 left-0 w-[50wh]"
      :src="require(`@/assets/background/bg-success-blue-top.svg`)"
    />

    <img
      alt="bgBottom"
      class="absolute right-0 bottom-0 h-[50vh]"
      :src="require(`@/assets/background/bg-success-blue-bottom.svg`)"
    />

    <div class="flex flex-col h-full">
      <div class="my-auto z-10">
        <swiper :options="swiperOption">
          <swiper-slide>
            <img
              class="mx-auto"
              :src="require(`@/assets/picture/pana1.svg`)"
              alt="pana"
            />
            <div class="w-48 mx-auto mt-16">
              <p class="text-center font-semibold text-white text-xl">
                Akses Penghasilan
              </p>
            </div>
            <div class="mx-auto w-[70%] mt-3">
              <p class="text-center text-white">
                Akses penghasilan prorata Anda kapan pun Anda butuhkan
              </p>
            </div>
          </swiper-slide>
          <swiper-slide
            ><img
              class="mx-auto"
              :src="require(`@/assets/picture/bro.svg`)"
              alt="pana"
            />
            <div class="w-72 mx-auto mt-16">
              <p class="text-center font-semibold text-white text-xl">
                Transaksi Aman dan Cepat
              </p>
            </div>
            <div class="mx-auto w-[70%] mt-3">
              <p class="text-center text-white">
                Tarik gaji tanpa ribet dan langsung cair dalam waktu 1x24 jam
              </p>
            </div></swiper-slide
          >
          <swiper-slide
            ><img
              class="mx-auto"
              :src="require(`@/assets/picture/amico.svg`)"
              alt="pana"
            />
            <div class="w-48 mx-auto mt-16">
              <p class="text-center font-semibold text-white text-xl">
                Poin Hadiah
              </p>
            </div>
            <div class="mx-auto w-[70%] mt-3">
              <p class="text-center text-white">
                Dapatkan poin dari setiap transaksi dan tunggu kejutan
                berikutnya
              </p>
            </div></swiper-slide
          >
          <div
            class="swiper-pagination text-amber-600 mb-24"
            slot="pagination"
          ></div>
        </swiper>

        <div class="mx-auto text-center z-50">
          <button
            class="
              w-[80%]
              py-3
              mt-11
              font-semibold
              bg-white
              text-nav-blue
              rounded-[32px]
              cursor-pointer
            "
            @click="MulaiRegist"
          >
            Daftar
          </button>
        </div>

        <div class="mx-auto w-[70%] mt-5">
          <p class="text-center text-white z-50">
            Sudah punya akun?
            <span class="font-semibold cursor-pointer z-50" @click="MulaiLogin"
              >Masuk</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { setOnboarding, getOnboarding } from "@/utils/cookies";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "OnBoarding",
  components: {
    Swiper,
    SwiperSlide,
  },

  data: () => ({
    onBoard: "yes",
    isOnboard: "",
    swiperOption: {
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      autoplay:
      { delay: 3500,
       disableOnInteraction: false }
    },
  }),
  methods: {
    MulaiRegist() {
      setOnboarding(this.onBoard);
      this.$router.push("/register");
    },
    MulaiLogin() {
      setOnboarding(this.onBoard);

      this.$router.push("/login");
    },
    checkOnboard() {
      const onBoard = getOnboarding();
      return onBoard;
    },
  },
  mounted() {
    const isOnboard = this.checkOnboard();
    if (isOnboard) {
      this.$router.push("/login");
    }
  },
};
</script>
<style>
.background-blue {
  background: linear-gradient(162.46deg, #003399 22.93%, #0da9e4 82.55%);
}
</style>