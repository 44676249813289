<template>
  <div class="h-screen relative">
    <div v-if="isLoading" class="mt-11">
      <loading-blue />
    </div>
    <div v-else class="h-full">
      <navbar-white
      textColor="text-black"
        boxShadow="shadow-[0_4px_4px_-2px_rgba(0,0,0,0.25)]"
        title="Detail Transaksi"
      />
      <div class="mt-4 mx-[30px]">
        <div class="flex flex-col">
          <img
            alt="checkList"
            class="mx-auto w-11"
            :src="require(`@/assets/icon/checklistGreenIcon.svg`)"
          />
          <p class="mx-auto mt-3 mb-6 font-semibold">
            Pengajuan Pembatalan Berhasil
          </p>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Nomor Referensi</div>
          <div class="text-right">{{ item.requestTransactionId }}</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Tanggal Transaksi</div>
          <div>
            {{ moment(item.createdAt).locale("id").format("DD-MM-YYYY") }}
          </div>
        </div>

        <div class="flex flex-row justify-between mb-4">
          <div class="font-semibold">Waktu Transaksi</div>
          <div>{{ moment(item.createdAt).locale("id").format("LTS") }} WIB</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">No. Rekening</div>
          <div>{{ item.bankAccountNumber }}</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Nama Penerima</div>
          <div>{{ item.bankAccountName }}</div>
        </div>

        <div class="flex flex-row justify-between mb-4">
          <div class="font-semibold">Bank Tujuan</div>
          <div>{{ item.bankAccount }}</div>
        </div>

        <hr class="mb-4" />

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Nominal</div>
          <div>{{ convertRupiah(item.amount) }}</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Biaya Admin</div>
          <div>- {{ convertRupiah(item.fee) }}</div>
        </div>

        <div class="flex flex-row justify-between">
          <div class="font-semibold">Total</div>
          <div class="font-semibold">
            {{ convertRupiah(item.amountTransfer) }}
          </div>
        </div>
      </div>
    </div>
    <button-bottom :Click="goBack">Kembali ke Menu</button-bottom>
  </div>
</template>
<script>
import ButtonBottom from "@/components/ButtonBottom.vue";

import LoadingBlue from "@/components/LoadingBlue.vue";
import { HistoryController } from "@/controllers/HistoryController.js";
import { formatPrice } from "@/utils/helper.js";
import moment from "moment";
import NavbarWhite from "@/components/NavbarWhite.vue";
export default {
  name: "TransactionCompleted",
  components: {
    NavbarWhite,
    LoadingBlue,
    ButtonBottom,
  },
  data() {
    return {
      History: new HistoryController(false, false, ""),

      moment: moment,
    };
  },
  computed: {
    isError() {
      return this.History.error;
    },
    item() {
      return this.History.historyDetail;
    },
    errorCause() {
      return this.History.errorCause;
    },

    isLoading() {
      return this.History.loading;
    },
  },
  methods: {
    goBack() {
      this.$router.push("/transaction-history");
    },
    convertRupiah(money) {
      return formatPrice(money);
    },
    async getDetailHistory(id) {
      return this.History.getHistoryDetail(id);
    },
    async gethistoryDetail() {
      await this.getDetailHistory(this.$route.params.id);
    },
  },
  created() {
    this.gethistoryDetail();
  },
};
</script>