<template>
  <div class="h-screen">
    <!-- modal confirmation -->
    <div
      class="
        modal
        fade
        fixed
        top-0
        left-0
        hidden
        w-full
        h-full
        outline-none
        overflow-x-hidden overflow-y-auto
      "
      id="confirmModalPassword"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div
        class="
          modal-dialog modal-dialog-centered
          relative
          w-auto
          pointer-events-none
        "
      >
        <div
          class="
            modal-content
            border-none
            shadow-lg
            relative
            flex flex-col
            w-full
            pointer-events-auto
            bg-white bg-clip-padding
            rounded-md
            outline-none
            text-current
          "
        >
          <div class="modal-body relative p-4">
            <div class="text-xl font-semibold text-center mb-2">
              Email Terkirim
            </div>

            <p class="p-4 mb-1 text-center">
              Kami telah mengirimkan email ke {{ setEmail }} untuk melakukan
              pengaturan password Anda.
            </p>
            <div class="flex flex-row">
              <div class="basis-1/3"></div>
              <div class="basis-1/3">
                <button-primary data-bs-dismiss="modal" :onClick="test2">
                  OK
                </button-primary>
              </div>
              <div class="basis-1/3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <navbar-white
      textColor="text-black"
        title="Password"
        :onClickBack="goBack"
        srcPictureLeft="backIconBlack.svg"
      />

      <div class="mt-3">
        <p class="mx-[30px]">
          Password harus terdiri dari minimal 6 karakter dan kombinasi antara
          huruf dan angka.
        </p>

        <div class="mt-4 mx-[30px]">
          <form>
            <div class="relative w-full">
              <input
                v-model="passwordOld"
                v-if="showPassword"
                type="text"
                class="
                  text-black
                  block
                  w-full
                  p-2.5
                  focus:outline-0
                  focus:ring-0
                  focus:outline-none
                  focus:border-b-[#7b7b7b]
                  border-none
                "
                :class="{
                  'mb-4': !errorMsg.passwordOld,
                  'inp-noerror': !errorMsg.passwordOld,
                  'inp-error': errorMsg.passwordOld,
                }"
                placeholder="Password Lama"
              />

              <input
                v-model="passwordOld"
                v-else
                type="password"
                class="
                  text-black
                  block
                  w-full
                  p-2.5
                  focus:outline-0
                  focus:ring-0
                  focus:outline-none
                  focus:border-b-[#7b7b7b]
                  border-none
                "
                :class="{
                  'mb-4': !errorMsg.passwordOld,
                  'inp-noerror': !errorMsg.passwordOld,
                  'inp-error': errorMsg.passwordOld,
                }"
                placeholder="Password Lama"
              />
              <div
                class="
                  absolute
                  inset-y-0
                  right-2
                  flex
                  items-center
                  text-base
                  leading-5
                  cursor-pointer
                "
              >
                <span class="bg-grey-darker"
                  ><i
                    class="fa-lg fa-regular"
                    :class="{
                      'fa-eye-slash': !showPassword,
                      'fa-eye': showPassword,
                    }"
                    @click="toggleShow(showPassword)"
                  ></i
                ></span>
              </div>
            </div>
            <p v-if="errorMsg.passwordOld" class="text-red-star mb-4">
              {{ errorMsg.passwordOld }}
            </p>
            <div class="relative w-full">
              <input
                v-model="passwordNew"
                v-if="showPassword1"
                type="text"
                class="
                  text-black
                  block
                  w-full
                  p-2.5
                  focus:outline-0
                  focus:ring-0
                  focus:outline-none
                  focus:border-b-[#7b7b7b]
                  border-none
                "
                :class="{
                  'mb-4': !errorMsg.passwordNew,
                  'inp-noerror': !errorMsg.passwordNew,
                  'inp-error': errorMsg.passwordNew,
                }"
                placeholder="Password Baru"
              />

              <input
                v-model="passwordNew"
                v-else
                type="password"
                class="
                  text-black
                  block
                  w-full
                  p-2.5
                  focus:outline-0
                  focus:ring-0
                  focus:outline-none
                  focus:border-b-[#7b7b7b]
                  border-none
                "
                :class="{
                  'mb-4': !errorMsg.passwordNew,
                  'inp-noerror': !errorMsg.passwordNew,
                  'inp-error': errorMsg.passwordNew,
                }"
                placeholder="Password Baru"
              />
              <div
                class="
                  absolute
                  inset-y-0
                  right-2
                  flex
                  items-center
                  text-base
                  leading-5
                  cursor-pointer
                "
              >
                <span class="bg-grey-darker"
                  ><i
                    class="fa-lg fa-regular"
                    :class="{
                      'fa-eye-slash': !showPassword1,
                      'fa-eye': showPassword1,
                    }"
                    @click="toggleShow1()"
                  ></i
                ></span>
              </div>
            </div>
            <p v-if="errorMsg.passwordNew" class="text-red-star mb-4">
              {{ errorMsg.passwordNew }}
            </p>
            <div class="relative w-full">
              <input
                v-model="passwordConfirm"
                v-if="showPassword2"
                type="text"
                class="
                  text-black
                  block
                  w-full
                  p-2.5
                  focus:outline-0
                  focus:ring-0
                  focus:outline-none
                  focus:border-b-[#7b7b7b]
                  border-none
                "
                @keyup.enter="changePass"
                :class="{
                  'mb-4': !errorMsg.passwordConfirm,
                  'inp-noerror': !errorMsg.passwordConfirm,
                  'inp-error': errorMsg.passwordConfirm,
                }"
                placeholder="Konfirmasi Password Baru"
              />

              <input
                v-model="passwordConfirm"
                v-else
                type="password"
                @keyup.enter="changePass"
                class="
                  text-black
                  block
                  w-full
                  p-2.5
                  focus:outline-0
                  focus:ring-0
                  focus:outline-none
                  focus:border-b-[#7b7b7b]
                  border-none
                "
                :class="{
                  'mb-4': !errorMsg.passwordConfirm,
                  'inp-noerror': !errorMsg.passwordConfirm,
                  'inp-error': errorMsg.passwordConfirm,
                }"
                placeholder="Konfirmasi Password Baru"
              />
              <div
                class="
                  absolute
                  inset-y-0
                  right-2
                  flex
                  items-center
                  text-base
                  leading-5
                  cursor-pointer
                "
              >
                <span class="bg-grey-darker"
                  ><i
                    class="fa-lg fa-regular"
                    :class="{
                      'fa-eye-slash': !showPassword2,
                      'fa-eye': showPassword2,
                    }"
                    @click="toggleShow2"
                  ></i
                ></span>
              </div>
            </div>
            <p v-if="errorMsg.passwordConfirm" class="text-red-star mb-4">
              {{ errorMsg.passwordConfirm }}
            </p>
            <div
              class="
                w-32
                text-lg
                font-semibold
                cursor-pointer
                text-green-button
                hover:text-green-button-darker
                cursor-pointer
              "
              data-bs-toggle="modal"
              data-bs-target="#confirmModalPassword"
              @click="resetPass"
            >
              Lupa Password
            </div>
          </form>
        </div>
        <div class="w-1/2 mt-5 mx-auto">
          <button-primary :isLoading="isLoading" :onClick="changePass"
            >Ganti Password</button-primary
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AuthControllers } from "@/controllers/AuthControllers.js";
import { validate } from "@/utils/helper";
import { getEmail } from "@/utils/localstorage";
import NavbarWhite from "@/components/NavbarWhite.vue";
import ButtonPrimary from "@/components/ButtonPrimary.vue";
export default {
  name: "ChangePassword",
  components: {
    NavbarWhite,
    ButtonPrimary,
  },
  data() {
    return {
      auth: new AuthControllers(false, false, ""),
      showPassword: false,
      showPassword1: false,
      showPassword2: false,
      passwordConfirm: "",
      passwordOld: "",
      passwordNew: "",
      errorMsg: {
        passwordOld: "",
        passwordNew: "",
        passwordConfirm: "",
      },
    };
  },
  computed: {
    setEmail() {
      const email = getEmail();
      return email;
    },
    isError() {
      return this.auth.error;
    },

    errorCause() {
      return this.auth.errorCause;
    },

    isLoading() {
      return this.auth.loading;
    },
  },
  watch: {
    passwordOld(value) {
      this.passwordOld = value;
      this.validatePasswordOld(value);
    },
    passwordNew(value) {
      this.passwordNew = value;
      this.validatePasswordNew(value);
    },
    passwordConfirm(value) {
      this.passwordConfirm = value;
      this.validatePasswordConfirm(value);
    },
  },
  methods: {
    validatePasswordOld(pwOld) {
      if (/^(?=.*?[0-9])[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{8,}$/g.test(pwOld)) {
        this.errorMsg.passwordOld = "";
      } else {
        this.errorMsg.passwordOld =
          "Password minimal 8 karakter kombinasi huruf dan angka";
      }
      if (pwOld == "") {
        this.errorMsg.passwordOld = "Password tidak boleh Kosong";
      }
    },
    validatePasswordNew(pwNew) {
      if (/^(?=.*?[0-9])[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{8,}$/g.test(pwNew)) {
        this.errorMsg.passwordNew = "";
      } else {
        this.errorMsg.passwordNew =
          "Password minimal 8 karakter kombinasi huruf dan angka";
      }
      if (pwNew == "") {
        this.errorMsg.passwordNew = "Password tidak boleh Kosong";
      }
    },
    validatePasswordConfirm(pwConfirm) {
      if (
        /^(?=.*?[0-9])[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{8,}$/g.test(pwConfirm)
      ) {
        this.errorMsg.passwordConfirm = "";
      } else {
        this.errorMsg.passwordConfirm =
          "Password minimal 8 karakter kombinasi huruf dan angka";
      }
      if (pwConfirm == "") {
        this.errorMsg.passwordConfirm = "Password tidak boleh Kosong";
      }
      if (pwConfirm != this.passwordNew) {
        this.errorMsg.passwordConfirm = "Password tidak sama";
      }
    },
    async ResetPassoword(email) {
      return this.auth.resetPassword(email);
    },
    async resetPass() {
      await this.ResetPassoword(this.setEmail);
    },
    async changePass() {
      await this.ChangePass(
        this.passwordOld,
        this.passwordNew,
        this.passwordConfirm
      );
      if (!this.isError) {
        this.$router.push("/changepass-success");
      } else if (this.errorCause == "password is incorrect") {
        this.errorMsg.passwordOld = "Password Salah";
      } else if (this.errorCause == "Not a valid currentPassword") {
        this.errorMsg.passwordOld = "Password tidak boleh Kosong";
        // validate(this.passwordNew, this.errorMsg.passwordNew);
        // validate(this.passwordConfirm, this.errorMsg.passwordConfirm);
        if (this.passwordNew == "") {
          this.errorMsg.passwordNew = "Password tidak boleh Kosong";
        }
        if (this.passwordConfirm == "") {
          this.errorMsg.passwordConfirm = "Password tidak boleh Kosong";
        }
      } else if (this.errorCause == "Not a valid newPassword") {
        this.errorMsg.passwordNew = "Password tidak boleh Kosong";
        if (this.passwordOld == "") {
          this.errorMsg.passwordOld = "Password tidak boleh Kosong";
        }
        if (this.passwordConfirm == "") {
          this.errorMsg.passwordConfirm = "Password tidak boleh Kosong";
        }
      } else if (this.errorCause == "Not a valid confirmNewPassword") {
        this.errorMsg.passwordConfirm = "Password tidak boleh Kosong";
        if (this.passwordOld == "") {
          this.errorMsg.passwordOld = "Password tidak boleh Kosong";
        }
        if (this.passwordNew == "") {
          this.errorMsg.passwordNew = "Password tidak boleh Kosong";
        }
      }
    },
    goBack() {
      this.$router.push("/profile");
    },
    toggleShow() {
      if (this.showPassword === false) {
        this.showPassword = true;
      } else {
        this.showPassword = false;
      }
    },
    async ChangePass(oldPassword, newPassword, confirmationPassword) {
      return this.auth.changePassword(
        oldPassword,
        newPassword,
        confirmationPassword
      );
    },

    toggleShow1() {
      if (this.showPassword1 === false) {
        this.showPassword1 = true;
      } else {
        this.showPassword1 = false;
      }
    },

    toggleShow2() {
      if (this.showPassword2 === false) {
        this.showPassword2 = true;
      } else {
        this.showPassword2 = false;
      }
    },
    test2() {},
  },
};
</script>
<style scoped>
.inp-noerror {
  border-bottom: 1.08px solid #7b7b7b;
}
.inp-error {
  border-bottom: 1.08px solid #dc2626;
}
</style>