var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative h-screen"},[_c('div',{staticClass:"h-full"},[_c('nav-bar',{attrs:{"title":"Login"}}),_c('img',{staticClass:"mx-auto my-10 w-28 h-28",attrs:{"src":require(`@/assets/icon/hakkuLogoOnly.svg`),"alt":"logo"}}),_c('form',[_c('div',{staticClass:"mx-[30px]"},[_c('label',{staticClass:"block text-black-form text-lg font-semibold mb-2",attrs:{"for":"email"}},[_vm._v(" Masukkan Email dan Password ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"shadow appearance-none border-none outline outline-1 rounded w-full py-2 px-3 text-grey-darker h-11",class:{
            'mb-4': !_vm.errorMsg.email,
            'outline-grey-input': !_vm.errorMsg.email,
            'outline-red-star': _vm.errorMsg.email,
          },attrs:{"id":"email","type":"email","placeholder":"Email"},domProps:{"value":(_vm.email)},on:{"focus":_vm.focus,"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),(_vm.errorMsg.email)?_c('p',{staticClass:"text-red-star mb-2"},[_vm._v(" "+_vm._s(_vm.errorMsg.email)+" ")]):_vm._e(),_c('div',{staticClass:"relative"},[(_vm.showPassword)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"shadow appearance-none border-none outline outline-1 rounded w-full py-2 px-3 text-grey-darker h-11",class:{
              'outline-grey-input': !_vm.errorMsg.password,
              'outline-red-star': _vm.errorMsg.password,
            },attrs:{"id":"email","type":"text","placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"focus":_vm.focus,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.loginAction.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"shadow appearance-none border-none outline outline-1 rounded w-full py-2 px-3 text-grey-darker h-11",class:{
              'outline-grey-input': !_vm.errorMsg.password,
              'outline-red-star': _vm.errorMsg.password,
            },attrs:{"id":"password","type":"password","placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"focus":_vm.focus,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.loginAction.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('div',{staticClass:"absolute inset-y-0 right-2 flex items-center text-base leading-5 cursor-pointer"},[_c('span',{staticClass:"bg-grey-darker"},[_c('i',{staticClass:"fa-lg fa-regular",class:{
                  'fa-eye-slash': !_vm.showPassword,
                  'fa-eye': _vm.showPassword,
                },on:{"click":_vm.toggleShow}})])])])]),(_vm.errorMsg.password)?_c('p',{staticClass:"text-red-star mx-[30px] mb-4"},[_vm._v(" "+_vm._s(_vm.errorMsg.password)+" ")]):_vm._e(),_c('div',{staticClass:"flex justify-end mr-6 mt-9 mb-11"},[_c('a',{staticClass:"inline-block align-baseline font-bold text-base text-nav-blue cursor-pointer",on:{"click":_vm.toResetPass}},[_vm._v(" Lupa Password? ")])]),_c('div',{staticClass:"mx-[30px]"},[_c('button-primary',{attrs:{"isLoading":_vm.isLoading,"onClick":_vm.loginAction}},[_vm._v("Masuk")])],1),_c('p',{staticClass:"text-center mt-7"},[_vm._v(" Perusahaan Anda belum terdaftar? "),_c('span',{staticClass:"text-nav-blue font-semibold cursor-pointer"},[_c('a',{on:{"click":_vm.toRegister}},[_vm._v("Daftar disini.")])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }