<template>
  <div class="animate-pulse">
      <div class="mt-4">

      
          <div class="mx-[30px]">
            <div class="rounded-full bg-slate-300 h-8 w-full w-1/2 mb-2 ">

            </div>

            <div class="relative rounded-full h-11 bg-slate-300 w-full">
              
            </div>
            
          </div>
          <div class="w-1/2 mt-7 rounded-full mx-auto 680:mt-11 h-10 bg-slate-300">
          </div>
        </div>

        <div
          class="
            flex flex-row
            justify-between
            mt-4
            650:mt-5
            680:mt-8
            hmm:mx-[10px]
            hmmm:mx-[10px]
            mx-[30px]
          "
        >
          <div
            class="
              flex flex-col
              680:w-[190px]
              570:w-[190px]
              400:w-[190px]
              hm:w-44
              hmm:w-[180px]
              hmmm:w-[165px]
            "
          >
            <div
              class="
                h-20
                570:h-[75px]
                bg-white
                mb-2
                flex flex-col
                shadow-[0_2px_8px_1px_rgba(0,0,0,0.1)]
                rounded
              "
            >
              <div class="my-auto pl-4">
                <div class="rounded-full  font-semibold h-5 mr-2 bg-slate-300 text-base hm:text-sm hmmm:text-sm mb-2">
                 
                </div>
                <div class="rounded-full text-base h-5 bg-slate-300 mr-2 hm:text-sm hmmm:text-sm">
                 
                </div>
              </div>
            </div>
            <div
              class="
                h-20
                570:h-[75px]
                bg-white
                mb-2
                flex flex-col
                shadow-[0_2px_8px_1px_rgba(0,0,0,0.1)]
                rounded
              "
            >
              <div class="my-auto pl-4">
                <div class="rounded-full font-semibold mb-3 mr-2 bg-slate-300 h-5 text-base hm:text-sm hmmm:text-sm">
                 
                </div>
                <div class="rounded-full text-base hm:text-sm mr-2 bg-slate-300 h-5 hmmm:text-sm">
                 
                </div>
              </div>
            </div>
          </div>

          <div
            class="
              hm:w-44
              hmm:w-[180px]
              hmmm:w-[165px]
              h-[167px]
              570:w-[185px] 570:h-[159px]
              400:w-[190px]
              570:h-[159px]
              680:w-[185px] 680:h-[155px]
              bg-white
              mb-2
              flex flex-col
              shadow-[0_2px_8px_1px_rgba(0,0,0,0.1)]
              rounded
            "
          >
            <div class="my-auto">
              
                <div class="mx-auto px-3 rounded-full h-12 bg-slate-300 w-1/2"></div>
             
            </div>
          </div>
        </div>
  </div>
</template>

<script>
export default {
name:"HomeLoading",
}
</script>

<style>

</style>