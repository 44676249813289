<template>
  <div class="h-screen">
    <div>
      <navbar-white
      textColor="text-black" srcPictureLeft="backIconBlack.svg" :onClickBack="goBack" />
      <div class="font-semibold text-xl mt-5 text-center">
        Masukkan Kode Verifikasi
      </div>
      <div class="mt-2 mb-3 text-center">
        4-digit Kode verifikasi telah dikirim ke email {{ getEmail }}
      </div>
      <div class="text-center mb-9">
        <otp-input
          class="mx-auto"
          type="password"
          placeholder="__"
          :isValid="isCodeValid"
          :digits="digits"
          :keyNumber="keyNumber"
          @on-complete="onCompleteHandler"
          @on-changed="onChangedHandler"
          @on-paste="onPasteHandler"
        >
          <template #errorMessage> Invalid code! </template>
        </otp-input>
      </div>
      <div class="mx-[30px] font-semibold text-center mb-5">
        Tidak menerima kode?
        <span
          class="text-green-button cursor-pointer"
          data-bs-toggle="modal"
          data-bs-target="#exampleModalSm"
          @click="sendEmail"
          >Kirim Ulang.</span
        >
      </div>
      <key-pad @clickNumber="clickNumber($event)" />
    </div>

    <!-- modal here -->
    <div
      class="
        modal
        fade
        fixed
        top-0
        left-0
        hidden
        w-full
        h-full
        outline-none
        overflow-x-hidden overflow-y-auto
      "
      id="exampleModalSm"
      tabindex="-1"
      aria-labelledby="exampleModalSmLabel"
      aria-modal="true"
      role="dialog"
    >
      <div
        class="
          modal-dialog modal-dialog-centered modal-sm
          relative
          w-auto
          pointer-events-none
        "
      >
        <div
          class="
            modal-content
            border-none
            shadow-lg
            relative
            flex flex-col
            w-full
            pointer-events-auto
            bg-[#262626] bg-clip-padding
            rounded-md
            outline-none
            text-current
          "
        >
          <div class="modal-body relative p-4 text-white">
            Kode Telah Dikirim ke Email
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { setVerified } from "@/utils/localstorage";
import { PinController } from "@/controllers/PinController";
import { getEmail } from "@/utils/localstorage";
import { errorLogout } from "@/utils/helper";
import OtpInput from "@/components/OtpInput.vue";
import NavbarWhite from "@/components/NavbarWhite.vue";
// import PinInput from "@/components/PinInput.vue";
import KeyPad from "@/components/KeyPad.vue";
export default {
  name: "SetupPin",
  data() {
    return {
      isCodeValid: true,
      digits: 4,
      keyNumber: null,
      statusModal: true,
      PIN: new PinController(false, false, ""),
    };
  },
  components: {
    NavbarWhite,
    OtpInput,
    KeyPad,
  },
  computed: {
    getEmail() {
      return getEmail();
    },
    isError() {
      return this.PIN.error;
    },

    errorCause() {
      return this.PIN.errorCause;
    },

    isLoading() {
      return this.PIN.loading;
    },
  },
  methods: {
    closeModal() {
      setTimeout(this.toggleBtn, 2000);
    },
    toggleBtn() {
      const toggleBtn = document.getElementById("btnModal");
      toggleBtn.click();
    },
    clickNumber(evt) {
      if (evt == this.keyNumber) {
        this.keyNumber = `$${evt}`;
      } else {
        this.keyNumber = `${evt}`;
      }
    },

    onCompleteHandler(code) {
      this.VerifyPin(code);
    },

    onChangedHandler(lastEnteredCode) {
      this.isCodeValid = true;
    },

    onPasteHandler(code) {},
    goBack() {
      this.$router.push("/changepin");
    },
    async sendVerifPin(email) {
      return this.PIN.emailVerifyPin(email);
    },
    async sendEmail() {
      await this.sendVerifPin(getEmail());
    },

    async verifPin(verifyCode) {
      return this.PIN.verifyPIN(verifyCode);
    },
    async VerifyPin(code) {
      await this.verifPin(code);
      if (!this.isError) {
        localStorage.removeItem("hk_repeat");
        setVerified();
        this.$router.push({
          name: "NewPin",
        });
      } else {
        this.isCodeValid = false;
        errorLogout();
      }
    },
  },
};
</script>