<template>
  <div class="hp:mx-[30px] mx-[10px]">
    <div
      class="
        mt-1
        h-20
        shadow-md
        rounded
        flex flex-row
        justify-between
        px-[8px]
        cursor-pointer
        hover:bg-slate-50
      "
      @click="toTransactionDetail(Index)"
    >
      <div class="flex flex-col my-auto">
        <div class="text-xl font-bold text-center">
          {{ moment(Item.createdAt).format("DD") }}
        </div>
        <div v-if="Item.status == 'waiting_for_approval'">
          <img
            alt="watingAprov"
            class="w-7 h-7 mx-auto"
            :src="require(`@/assets/icon/waiting_approve.svg`)"
          />
        </div>
        <div v-if="Item.status == 'cancelled'">
          <img
            alt="canceled"
            class="w-7 h-7 mx-auto"
            :src="require(`@/assets/icon/canceledIcon.svg`)"
          />
        </div>
        <div v-if="Item.status == 'paid'">
          <img
            alt="checklistGreen"
            class="w-7 h-7 mx-auto"
            :src="require(`@/assets/icon/checklistGreenIcon.svg`)"
          />
        </div>
        <div v-if="Item.status == 'approved'">
          <img
            alt="checklistGreen"
            class="w-7 h-7 mx-auto"
            :src="require(`@/assets/icon/waiting_approve.svg`)"
          />
        </div>
        <div v-if="Item.status == 'rejected'">
          <img
            alt="canceled"
            class="w-7 h-7 mx-auto"
            :src="require(`@/assets/icon/canceledIcon.svg`)"
          />
        </div>
      </div>
      <div class="flex flex-col my-auto w-64 px-10">
        <div class="font-bold text-sm">
          {{ moment(Item.createdAt).locale("id").format("dddd") }}
        </div>
        <div class="text-sm text-grey-input">
          {{ moment(Item.createdAt).locale("id").format("MMMM YYYY") }}
        </div>
        <div v-if="Item.status == 'cancelled'" class="text-xs">
          Status: Dibatalkan
        </div>
        <div v-if="Item.status == 'rejected'" class="text-xs">
          Status: Ditolak
        </div>
        <div v-if="Item.status == 'waiting_for_approval'" class="text-xs">
          Status: Menunggu Persetujuan
        </div>
        <div v-if="Item.status == 'paid'" class="text-xs">Status: Dibayar</div>
        <div v-if="Item.status == 'approved'" class="text-xs">
          Status: Disetujui
        </div>
      </div>
      <div class="font-bold text-sm my-auto">- {{ convertRupiah(Item.amount) }}</div>
      <div class="my-auto">
        <img alt="arrow" :src="require(`@/assets/icon/arrowRightIcon.svg`)" />
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { formatPrice } from "@/utils/helper.js";

export default {
  name: "TransactionList",

  data() {
    return {
      moment: moment,
    };
  },
  props: {
    Item: {
      type: Object,
    },
    Index: {
      type: String,
    },
  },
  methods: {
    toTransactionDetail(Index) {
      this.$router.push({
        name: "TransactionDetail",
        params: { id: Index },
      });
    },
    convertRupiah(money) {
      return formatPrice(money);
    },
  },
};
</script>