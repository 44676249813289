import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js';

export const setOnboarding = (onBoard) => {
    Cookies.set('hk_onboard', onBoard)
}

export const setModal = (modal) => {
    Cookies.set('hk_modal_login', modal)
}

export const setNotif = (notif) => {
    Cookies.set('hk_notif', notif)
}
export const setModalBiayaAdmin = (modal) => {
    Cookies.set('hk_modal_biayaAdmin', modal)
}

export const RemoveModalAdmin = () => Cookies.remove('hk_modal_biayaAdmin')
export const setMarker = (marker) => {
    Cookies.set('hk_marker', marker)
}

export const getModalBiayaAdmin = () => Cookies.get('hk_modal_biayaAdmin') || ''

export const getMarker = () => Cookies.get('hk_marker') || ''

export const getNotif = () => Cookies.get('hk_notif') || ''


export const getOnboarding = () => Cookies.get('hk_onboard') || ''

export const getModal = () => Cookies.get('hk_modal_login') || ''

export const removeNotif = () => Cookies.remove("hk_notif")

export const removeModal = () => {
    Cookies.remove('hk_modal_login')
}


export const removeOnboarding = () => Cookies.remove("hk_onboard")