
import { GetProfileRequest } from '../api/request/ProfileRequest'
import { setOldPin } from '../utils/localstorage'
import { sessionExpired } from "@/utils/helper";

export class ProfileController {
    loading = false;
    error = false;
    errorCause = "";

    lists = []

    constructor(loading, error, errorCause,) {
        this.loading = loading;
        this.error = error;
        this.errorCause = errorCause;


    }

    getProfile() {
        let resp = null
        this.setLoading(true)
        resp = GetProfileRequest()
            .then((response) => {

                this.setPinDetail(response.data.data.pin)
                this.setLists(response.data.data)
                this.setErrorCause("")
                this.setError(false)
                return true
            }).catch((err) => {

                if (err.response.data.code === 404) {
                    this.setErrorCause(err.response.data.message)
                    this.setError(false)
                    this.setInboxLists(err.response.data)

                } else if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err.response.data.message)
                    this.setError(true)
                }
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }
    setLists(data) {

        this.lists = data
    }

    setPinDetail(pin) {
        setOldPin(pin)
    }

    setLoading(status) {
        this.loading = status
    }

    setError(err) {
        this.error = err
    }

    setErrorCause(cause) {
        this.errorCause = cause
    }
}