import axios from 'axios'
import { TotalEmployees } from '../endpoint/index'
import { BaseUrl } from '../endpoint/BaseUrl'


const basicAuth = {
    'Authorization': `Basic MTIzOjEyMw==`
}

export const GetTotalEmployeesRequest = () => {
    let resp = null;
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${TotalEmployees()}`,
        headers: basicAuth
    }).then((response) => {
        return response
    })
    return resp
}
