<template>
  <img
    @click="onClickFaq"
    class="absolute right-0 mr-7 py-4 cursor-pointer"
    :src="require(`../assets/icon/${PictureRight}`)"
    alt="backIcon"
  />
</template>
<script>
export default {
  name: "FaqIcon",
  props: {
    PictureRight: String,
    onClickFaq: {
      type: Function,
      require: true,
    },
  },
};
</script>