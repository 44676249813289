import Vue from 'vue'
import App from './App.vue'

import router from './router'
import './index.css'
import 'flowbite';
import 'tw-elements';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css'
// import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

Vue.config.productionTip = false

new Vue({
  router,

  render: h => h(App)
}).$mount('#app')
