import { Transaction, Inquiry, downloadRequest } from '../api/request/TransactionRequest'
import { setInquiry, setTransId } from '../utils/localstorage'
import { sessionExpired } from "@/utils/helper";


export class TransactionController {
    loading = false
    error = false
    errorCause = ''
    inquiryList = []
    requestList = []
    emailMsg = []

    constructor(loading, error, errorCause) {
        this.loading = loading
        this.error = error
        this.errorCause = errorCause
    }

    inquiry(amount) {
        let resp = null
        this.setLoading(true)
        resp = Inquiry(amount)
            .then((response) => {

                this.setInquiryDetail(
                    response.data.data.bankAccountNumber,
                    response.data.data.bankAccountName,
                    response.data.data.bankAccount,
                    String(response.data.data.amount),
                    String(response.data.data.fee),
                    String(response.data.data.amountTransfer),

                )

                this.setErrorCause("")
                this.setError(false)


                return true
            }).catch((err) => {

                if (err.response.data.code === 404) {
                    this.setErrorCause(err.response.data.message)
                    this.setError(false)
                    this.setInboxLists(err.response.data)

                } else if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err.response.data.message)
                    this.setError(true)
                }
                console.clear()
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }

    transaction(amount) {
        let resp = null
        this.setLoading(true)
        resp = Transaction(amount)
            .then((response) => {

                this.setTransactionId(
                    response.data.data.id
                )

                this.setErrorCause("")
                this.setError(false)


                return true
            }).catch((err) => {

                if (err.response.data.code === 404) {
                    this.setErrorCause(err.response.data.message)
                    this.setError(false)
                    this.setInboxLists(err.response.data)

                } else if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err.response.data.message)
                    this.setError(true)
                }
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }

    download(id) {
        let resp = null
        this.setLoading(true)
        resp = downloadRequest(id)
            .then((response) => {

                this.setEmailMsg(response.data.message)
                this.setErrorCause("")
                this.setError(false)


                return true
            }).catch((err) => {

                if (err.response.data.code === 404) {
                    this.setErrorCause(err.response.data.message)
                    this.setError(false)
                    this.setInboxLists(err.response.data)

                } else if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err.response.data.message)
                    this.setError(true)
                }
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }

    setTransactionId(id) {
        setTransId(id)
    }

    setEmailMsg(data) {
        this.emailMsg = data
    }
    setInquiryDetail(noRek, nama, bank, amount, fee, total) {
        setInquiry(noRek, nama, bank, amount, fee, total)
    }

    setLoading(status) {
        this.loading = status
    }

    setError(err) {
        this.error = err
    }

    setErrorCause(cause) {
        this.errorCause = cause
    }
}
