<template>
  <div class="h-auto mbl:h-screen relative">
    <div class="h-full">
      <navbar-white
        textColor="text-black"
        boxShadow="shadow-[0_4px_4px_-2px_rgba(0,0,0,0.25)]"
        title="Profil"
      />
      <div v-if="isLoading" class="mt-11">
        <profile-loading />
      </div>

      <div v-else>
        <div class="flex flex-row justify-start mt-4 mx-[30px]">
          <div class="mr-11 w-[100px] h-24">
            <img
              alt="profilePicture"
              v-if="
                profileList.profileImage == '' ||
                profileList.profileImage == '-'
              "
              class="h-full w-full rounded-full"
              :src="require(`@/assets/icon/iconProfilImage.svg`)"
            />
            <img
              alt="profilePicture"
              v-else
              class="h-full w-full rounded-full"
              :src="profileList.profileImage"
            />
          </div>
          <div class="flex flex-col">
            <div class="font-bold">{{ profileList.name }}</div>
            <div v-if="profileList.mobileNumber.charAt(0) == '0'">
              {{ profileList.mobileNumber.replace("0", "+62 ") }}
            </div>
            <!-- <div v-if="profileList.mobileNumber.charAt(0) == '8'">
              {{ profileList.mobileNumber.replace("8", "+62 8") }}
            </div> -->
            <div v-else >
              {{profileList.mobileNumber}}
              </div>
            <div
              class="
                border-[0.5px] border-grey-nav
                bg-white
                text-grey-input
                w-fit
                pl-2
                rounded-2xl
                flex flex-row
                font-semibold
              "
            >
              10000 POIN
              <img
                alt="arrow"
                class="filter"
                :src="require(`@/assets/icon/arrowRightIcon.svg`)"
              />
            </div>
          </div>
        </div>

        <div class="mx-[30px]">
          <div class="mt-8 font-bold text-lg mb-3">Data Karyawan</div>

          <div class="flex flex-row mb-2 justify-start">
            <div class="font-semibold basis-1/2">Nama Perusahaan</div>
            <div class="basis-1/2">{{ profileList.companyName }}</div>
          </div>

          <div class="flex flex-row mb-2 justify-start">
            <div class="font-semibold basis-1/2">NIK</div>
            <div class="basis-1/2">{{ profileList.nik }}</div>
          </div>

          <div class="flex flex-row mb-2 justify-start">
            <div class="font-semibold basis-1/2">Jabatan</div>
            <div class="basis-1/2">{{ profileList.jobTitle }}</div>
          </div>

          <div class="flex flex-row mb-2 justify-start">
            <div class="font-semibold basis-1/2">Status Karyawan</div>
            <div class="basis-1/2">{{ profileList.employeementStatus }}</div>
          </div>
          <hr class="my-5" />

          <div class="font-bold text-lg mb-3">Info Rekening</div>

          <div class="flex flex-row mb-2 justify-start">
            <div class="font-semibold basis-1/2">Nama Pemilik Rekening</div>
            <div class="basis-1/2">{{ profileList.bankAccountNumber }}</div>
          </div>

          <div class="flex flex-row mb-2 justify-start">
            <div class="font-semibold basis-1/2">No. Rekening</div>
            <div class="basis-1/2">{{ profileList.bankAccountName }}</div>
          </div>

          <div class="flex flex-row mb-4 justify-start">
            <div class="font-semibold basis-1/2">Nama Bank</div>
            <div class="basis-1/2">{{ profileList.bankAccount }}</div>
          </div>

          <div class="font-bold mt-3 text-lg mb-3">Keamanan</div>
          <div class="flex flex-row mb-5 cursor-pointer" @click="changePin">
            <div class="basis-1/8 w-11">
              <img
                alt="lock"
                class="w-6"
                :src="require(`@/assets/icon/lockIcon.svg`)"
              />
            </div>
            <div class="basis-3/4 font-semibold text-left">Pengaturan Pin</div>
            <div class="basis-1/4 text-right relative">
              <img
                alt="arrow"
                class="filter absolute right-0"
                :src="require(`@/assets/icon/arrowRightIcon.svg`)"
              />
            </div>
          </div>

          <div class="flex flex-row mb-7 cursor-pointer" @click="changePass">
            <div class="basis-1/8 w-11">
              <img
                alt="key"
                class="w-6"
                :src="require(`@/assets/icon/keyIcon.svg`)"
              />
            </div>
            <div class="basis-3/4 font-semibold text-left">Ganti Password</div>
            <div class="basis-1/4 text-right relative">
              <img
                alt="arrow"
                class="filter absolute right-0"
                :src="require(`@/assets/icon/arrowRightIcon.svg`)"
              />
            </div>
          </div>

          <button-primary
            class="mb-16"
            :isLoading="loadingStatus"
            :onClick="logout"
            >Keluar</button-primary
          >
        </div>
      </div>
    </div>
    <navbar-bottom />
  </div>
</template>
<script>
import { ProfileController } from "@/controllers/ProfileControllers.js";
import { removeAuth } from "@/utils/localstorage";
import { removeNotif } from "@/utils/cookies";
import ButtonPrimary from "@/components/ButtonPrimary.vue";
import NavbarBottom from "@/components/NavbarBottom.vue";
import NavbarWhite from "@/components/NavbarWhite.vue";
import ProfileLoading from "@/components/ProfileLoading.vue";
import LoadingBlue from "@/components/LoadingBlue.vue";
export default {
  name: "Profile",
  components: {
    NavbarWhite,
    NavbarBottom,
    ButtonPrimary,
    LoadingBlue,
    ProfileLoading,
  },
  data() {
    return {
      loadingStatus: false,
      Profile: new ProfileController(false, false, ""),
    };
  },
  created() {
    this.profile();
  },
  computed: {
    isError() {
      return this.Profile.error;
    },
    profileList() {
      return this.Profile.lists;
    },
    errorCause() {
      return this.Profile.errorCause;
    },

    isLoading() {
      return this.Profile.loading;
    },
  },

  methods: {
    logout() {
      removeNotif();
      this.loadingStatus = true;
      setTimeout(removeAuth(), 3500);
      localStorage.clear();
      window.location.reload();
    },
    changePass() {
      this.$router.push("/changepass");
    },
    changePin() {
      this.$router.push("/changepin");
    },
    async getprofile() {
      return this.Profile.getProfile();
    },
    async profile() {
      await this.getprofile();
    },
  },
};
</script>
<style scoped>
.filter {
  filter: brightness(0) saturate(100%) invert(33%) sepia(0%) saturate(323%)
    hue-rotate(245deg) brightness(80%) contrast(84%);
}
</style>