
import { GetNotificationActivityRequest, GetNotificationInboxRequest,GetNotificationActivityUpdateRequest } from '../api/request/NotificationRequest'
import { sessionExpired } from "@/utils/helper";

export class NotificationController {
    loading = false;
    error = false;
    errorCause = "";

    activityLists = []
    inboxLists = []
    activityListsUpdate = []
    constructor(loading, error, errorCause,) {
        this.loading = loading;
        this.error = error;
        this.errorCause = errorCause;


    }

    getActivityList(page, size,) {
        this.setLoading(true)
        let resp = null

        resp = GetNotificationActivityRequest(page, size,)
            .then((response) => {

                this.setActivityLists(response.data.data)
                this.setErrorCause("")
                this.setError(false)
                return true
            }).catch((err) => {

                if (err.response.data.code === 404) {
                    this.setErrorCause(err.response.data.message)
                    this.setError(false)
                    this.setActivityLists(err.response.data)



                } else if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err.response.data.message)
                    this.setError(true)
                }


                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }
    getActivityListUpdate(id) {
        let resp = null

        resp = GetNotificationActivityUpdateRequest(id)
            .then((response) => {

                this.setActivityListsUpdate(response.data.data)
                this.setErrorCause("")
                this.setError(false)
                return true
            }).catch((err) => {

                if (err.response.data.code === 404) {
                    this.setErrorCause(err.response.data.message)
                    this.setError(false)
                    this.ActivityListsUpdate(err.response.data)



                } else if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err.response.data.message)
                    this.setError(true)
                }


                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }

    getInboxList(page, size) {
        this.setLoading(true)
        let resp = null
        resp = GetNotificationInboxRequest(page, size,)
            .then((response) => {

                this.setInboxLists(response.data.data)
                this.setErrorCause("")
                this.setError(false)
                return true
            }).catch((err) => {

                if (err.response.data.code === 404) {
                    this.setErrorCause(err.response.data.message)
                    this.setError(false)
                    this.setInboxLists(err.response.data)

                } else if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err.response.data.message)
                    this.setError(true)
                }
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }



    setActivityLists(data) {
        this.activityLists = data
    }
    setActivityListsUpdate(data) {
        this.activityListsUpdate = data
    }
    setInboxLists(data) {
        this.inboxLists = data
    }


    setLoading(status) {
        this.loading = status
    }

    setError(err) {
        this.error = err
    }

    setErrorCause(cause) {
        this.errorCause = cause
    }
}