import axios from 'axios'
import { NotificationActivity, NotificationInbox,NotificationActivityUpdate } from '../endpoint/index'
import { BaseUrl } from '../endpoint/BaseUrl'
import { getToken } from '../../utils/localstorage'

const headers = {
    'Authorization': `Bearer ${getToken()}`
}

export const GetNotificationActivityRequest = (page, size,) => {
    let resp = null;
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${NotificationActivity(page, size,)}`,
        headers: headers
    }).then((response) => {
        return response
    })
    return resp
}

export const GetNotificationInboxRequest = (page, size) => {
    let resp = null;
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${NotificationInbox(page, size)}`,
        headers: headers
    }).then((response) => {
        return response
    })
    return resp
}
export const GetNotificationActivityUpdateRequest = (id) => {
    let resp = null;
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${NotificationActivityUpdate(id)}`,
        headers: headers
    }).then((response) => {
        return response
    })
    return resp
}
