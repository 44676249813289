<template>
  <div class="relative">
    <div class="">
      <div class="h-[94%]">
        <navbar-white
        textColor="text-black"
          boxShadow="shadow-[0_4px_4px_-2px_rgba(0,0,0,0.25)]"
          title="Riwayat Transaksi"
        />
        <div v-if="isLoading" class="mt-11">
          <loading-blue />
        </div>
        <div v-else>
          <div v-if="HistoryList.success == false">
            <transaction-empty msg="Belum ada transaksi"/>
          </div>
          <div v-else>
            <transaction-list
              v-for="item in HistoryList"
              :key="item.id"
              :Item="item"
              :Index="item.id"
            />
            <div class="mb-16"></div>
          </div>
        </div>
      </div>
    </div>
    <navbar-bottom />
  </div>
</template>
<script>
import { HistoryController } from "@/controllers/HistoryController.js";
import NavbarBottom from "@/components/NavbarBottom.vue";
import NavbarWhite from "@/components/NavbarWhite.vue";
import TransactionEmpty from "@/components/TransactionEmpty.vue";
import { setSize, getSize } from "@/utils/localstorage";
import LoadingBlue from "@/components/LoadingBlue.vue";
import TransactionList from "@/components/TransactionList.vue";
export default {
  name: "TransactionSummary",
  components: {
    NavbarBottom,
    NavbarWhite,
    TransactionEmpty,
    TransactionList,
    LoadingBlue,
  },
  data() {
    return {
      meta: {
        page: "",
        size: 8,
      },

      history: false,
      History: new HistoryController(false, false, ""),
    };
  },
  computed: {
    totalData() {
      return this.History.totalDataHistory;
    },
    isError() {
      return this.History.error;
    },
    HistoryList() {
      return this.History.historyLists;
    },
    errorCause() {
      return this.History.errorCause;
    },

    isLoading() {
      return this.History.loading;
    },
  },

  mounted() {
    this.gethistory();
    this.getNextData();
   
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getHistoryList(page, size) {
      return this.History.getHistory(page, size);
    },
    gethistory() {
      if (screen.height >= 790) {
        setSize(10);
        this.getHistoryList(this.meta.page, getSize());
      }
      if (screen.height >= 960) {
        setSize(14);
        this.getHistoryList(this.meta.page, getSize());
      }
      if (screen.height >= 1212) {
        setSize(16);
        this.getHistoryList(this.meta.page, getSize());
      }
      if (screen.height < 790) {
        setSize(this.meta.size);
        this.getHistoryList(this.meta.page, getSize());
      }
    },
    getNextData() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.getHistoryList(this.meta.page, (this.meta.size += 5));
        }
      };
    },
  },
  created() {},
};
</script>