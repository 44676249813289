<template>
  <div class="h-screen relative">
    <div class="h-full">
      <navbar-white
        boxShadow="shadow-[0_4px_4px_-2px_rgba(0,0,0,0.25)]"
        title="Detail Transaksi"
        srcPictureLeft="backIconBlack.svg"
        :onClickBack="goBack"
        textColor="text-black"
      />
      <div class="mt-4 mx-[30px]">
        <div class="flex flex-col">
          <img
            alt="canceled"
            class="mx-auto w-11"
            :src="require(`@/assets/icon/canceledIcon.svg`)"
          />
          <p class="mx-auto mt-3 mb-6 font-semibold">Dibatalkan</p>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Nomor Referensi</div>
          <div class="text-right">{{ item.requestTransactionId }}</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Tanggal Transaksi</div>
          <div>
            {{ moment(item.createdAt).locale("id").format("DD-MM-YYYY") }}
          </div>
        </div>

        <div class="flex flex-row justify-between mb-4">
          <div class="font-semibold">Waktu Transaksi</div>
          <div>{{ moment(item.createdAt).locale("id").format("LTS") }} WIB</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">No. Rekening</div>
          <div>{{ item.bankAccountNumber }}</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Nama Penerima</div>
          <div>{{ item.bankAccountName }}</div>
        </div>

        <div class="flex flex-row justify-between mb-4">
          <div class="font-semibold">Bank Tujuan</div>
          <div>{{ item.bankAccount }}</div>
        </div>

        <hr class="mb-4" />

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Nominal</div>
          <div>{{ convertRupiah(item.amount) }}</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Biaya Admin</div>
          <div>- {{ convertRupiah(item.fee) }}</div>
        </div>

        <div class="flex flex-row justify-between">
          <div class="font-semibold">Total</div>
          <div class="font-semibold">
            {{ convertRupiah(item.amountTransfer) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatPrice } from "@/utils/helper.js";
import moment from "moment";
import NavbarWhite from "@/components/NavbarWhite.vue";
export default {
  name: "TransactionCanceled",
  components: {
    NavbarWhite,
  },
  data() {
    return {
      moment: moment,
    };
  },
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    convertRupiah(money) {
      return formatPrice(money);
    },
  },
};
</script>