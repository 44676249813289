<template>
  <div class="animate-pulse">
    <div class="flex flex-row justify-start mt-4 mx-[30px]">
          <div class="mr-11 w-[100px] rounded-full bg-slate-300 h-24">
            
          </div>
          <div class="flex flex-col">
            <div class="font-bold h-5 rounded-full bg-slate-300 w-28 mb-3"></div>
           
            <div class="h-5 bg-slate-300 rounded-full W-36 mb-2">
              
            </div>
            <div
              class="
                border-[0.5px] border-grey-nav
                bg-white
                text-grey-input
                w-fit
                pl-2
                h-5
                w-24
                rounded-2xl
                flex flex-row
                font-semibold
                bg-slate-300
              "
            >
             
            </div>
          </div>
        </div>

        <div class="mx-[30px] ">
          <div class="mt-8 rounded-full bg-slate-300 h-5 w-36 font-bold text-lg mb-3"></div>

          <div class="flex flex-row mb-2 justify-start">
            <div class="font-semibold rounded-full bg-slate-300 h-5 w-1/2 basis-1/2 mr-2"></div>
            <div class="basis-1/2 bg-slate-300 h-5 rounded-full w-1/2"></div>
          </div>

          <div class="flex flex-row mb-2 justify-start">
            <div class="font-semibold basis-1/2 bg-slate-300 rounded-full h-5 w-full mr-2"></div>
            <div class="basis-1/2 bg-slate-300 h-5 w-full rounded-full"></div>
          </div>

          <div class="flex flex-row mb-2 justify-start">
            <div class="font-semibold basis-1/2 bg-slate-300 rounded-full h-5 w-full mr-2"></div>
            <div class="basis-1/2 bg-slate-300 h-5 w-full rounded-full"></div>
          </div>

          <div class="flex flex-row mb-2 justify-start">
            <div class="font-semibold basis-1/2 bg-slate-300 h-5 w-full rounded-full mr-2"></div>
            <div class="basis-1/2 bg-slate-300 h-5 w-full rounded-full"></div>
          </div>
          <hr class="my-5" />

          <div class="font-bold text-lg mb-3 bg-slate-300 h-5 w-36 rounded-full"></div>

          <div class="flex flex-row mb-2 justify-start">
            <div class="font-semibold basis-1/2 bg-slate-300 h-5 w-full mr-2 rounded-full"></div>
            <div class="basis-1/2 bg-slate-300 h-5 w-full rounded-full"></div>
          </div>

          <div class="flex flex-row mb-2 justify-start">
            <div class="font-semibold basis-1/2 bg-slate-300 rounded-full h-5 w-full mr-2"></div>
            <div class="basis-1/2 bg-slate-300 h-5 w-full rounded-full"></div>
          </div>

          <div class="flex flex-row mb-4 justify-start">
            <div class="font-semibold basis-1/2 bg-slate-300 h-5 w-full rounded-full mr-2"></div>
            <div class="basis-1/2 bg-slate-300 h-5 w-full rounded-full"></div>
          </div>

          <div class="font-bold mt-3 text-lg mb-3 bg-slate-300 h-5 w-full rounded-full"></div>
          <div class="flex bg-slate-300 h-5 w-full flex-row mb-5 cursor-pointer rounded-full" >
           
          </div>

          <div class="flex bg-slate-300 h-5 w-full flex-row mb-7 cursor-pointer rounded-full">
            
          </div>

          
        </div>
  </div>
</template>

<script>
export default {
name:"ProfileLoading"
}
</script>

<style>

</style>