<template>
  <success-blue
    :onClick="toProfile"
    msg="Berhasil"
    msgDetail="Password berhasil diubah"
  />
</template>
<script>
import { removeAuth } from "@/utils/localstorage";
import SuccessBlue from "@/components/SuccessBlue.vue";
export default {
  name: "ChangePasswordSuccess",
  components: {
    SuccessBlue,
  },
  methods: {
    toProfile() {
      this.$router.push("/profile");
    },
  },
};
</script>