<template>
  <div id="app" class="max-w-[480px] mx-auto bg-white">
    <router-view />
  </div>
</template>

<style>
@font-face {
  font-family: "SF-Pro";
  src: url("~@/assets/font/SFProText.ttf");
}
#app {
  font-family: "SF-Pro";
}
</style>
<script>
import swal from "sweetalert";
import { removeAuth } from "@/utils/localstorage";
import jwt_decode from "jwt-decode";
import { getToken } from "@/utils/localstorage";
import { PinController } from "@/controllers/PinController";
export default {
  name: "App",
  data() {
    return {
      PIN: new PinController(false, false, ""),
    };
  },
  computed: {
    isError() {
      return this.PIN.error;
    },

    errorCause() {
      return this.PIN.errorCause;
    },

    isLoading() {
      return this.PIN.loading;
    },
  },
  methods: {
    async setPin() {
      return this.PIN.setOldPin();
    },
    async SetOldPin() {
      await this.setPin();
    },
  },
  watch: {
    $route(to, from) {
      if (
        !(
          to.name === "NewPin" ||
          to.name === "NewPinConfirmation" ||
          to.name === "Login" ||
          to.name === "Register" ||
          to.name === "FAQ" ||
          to.name === "ResetPassword" ||
          to.name === "NotFound"
        )
      ) {
        this.SetOldPin();
        localStorage.removeItem("hk_verifCode");
      }

      if (
        !(
          to.name === "Login" ||
          to.name === "Register" ||
          to.name === "OnBoarding" ||
          to.name === "FAQ" ||
          to.name === "ResetPassword" ||
          to.name === "NotFound"
        )
      ) {
        const decode = jwt_decode(getToken());
        const ms = decode.exp * 1000;
        const date = new Date(ms);

        const now = new Date().getTime();
        const dateNow = new Date(now);
       
        if (dateNow > date) {
          swal({
            title: "Perhatian!",
            text: "Sesi Telah habis, silahkan Login Kembali",
            icon: "info",
          }).then(function () {
            removeAuth();
            window.location.reload();
          });
        }
      }
    },
  },
  // created() {
  //   // this.$store.dispatch("pin/getPin");
  // },
  // mounted() {
  //   this.$store.dispatch("pin/getPin");
  // },
};
</script>