import axios from 'axios'
import { Balance } from '../endpoint/index'
import { BaseUrl } from '../endpoint/BaseUrl'
import { getToken } from '../../utils/localstorage'
const headers = {
    'Authorization': `Bearer ${getToken()}`
}

export const GetHomeRequest = () => {
    let resp = null;
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${Balance()}`,
        headers: headers
    }).then((response) => {
        return response
    })
    return resp
}