<template>
  <div class="h-screen relative">
    <div class="h-[94%]">
      <navbar-white
      textColor="text-black"
       boxShadow="shadow-[0_4px_4px_-2px_rgba(0,0,0,0.25)]"
        srcPictureLeft="backIconBlack.svg"
        :onClickBack="goBack"
        title="Ringkasan Transaksi"
      />
      <div class="mt-10 mx-[30px]">
        <div class="flex flex-row justify-between mb-7">
          <div class="font-semibold">No. Rekening</div>
          <div>{{getNoRekening}}</div>
        </div>

        <div class="flex flex-row justify-between mb-7">
          <div class="font-semibold">Nama Penerima</div>
          <div>{{getNama}}</div>
        </div>

        <div class="flex flex-row justify-between mb-4">
          <div class="font-semibold">Bank Tujuan</div>
          <div>{{getBank}}</div>
        </div>

        <hr class="mb-4" />

        <div class="flex flex-row justify-between mb-7">
          <div class="font-semibold">Nominal</div>
          <div>{{convertRupiah(getAmount)}}</div>
        </div>

        <div class="flex flex-row justify-between mb-4">
         
            <div class="font-semibold flex flex-row">Biaya Admin  
              <img
              class="ml-2 cursor-pointer"         
              :src="require(`@/assets/icon/alert-circle.svg`)"
              alt="home"
               data-bs-target="#biayaAdminModal2"
    data-bs-toggle="modal"
            />
            </div>
          
          
          <div>-{{convertRupiah(getFee)}}</div>
        </div>

        <div class="flex flex-row justify-between">
          <div class="font-semibold">Total</div>
          <div class="font-semibold">{{convertRupiah(getTotal)}}</div>
        </div>
      </div>
    </div>
    <button  
    class="hidden"
    id="toggleBtn"
    type="button"
    data-bs-target="#biayaAdminModal"
    data-bs-toggle="modal"
    >srgdrhhe
    </button>
    <button-bottom data-bs-toggle="modal" data-bs-target="#pinInputModal" :Click="test4"
      >Selanjutnya</button-bottom
    >

    <!-- modal popup biaya admin -->
    <div
      class="
        modal
        fade
        fixed
        top-0
        left-0
        hidden
        w-full
        h-full
        outline-none
        overflow-x-hidden overflow-y-auto
      "
      id="biayaAdminModal"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div
        class="
          modal-dialog modal-dialog-centered
          relative
          w-auto
          pointer-events-none
        "
      >
        <div
          class="
            modal-content
            border-none
            shadow-lg
            relative
            flex flex-col
            w-full
            pointer-events-auto
            bg-white bg-clip-padding
            rounded-md
            outline-none
            text-current
          "
        >
          <div class="modal-body relative p-4">
            <div class="flex flex-row justify-between mb-3 text-[#4D4D4D]">
              <div class="text-xl font-semibold">Biaya Admin</div>
             
            </div>
            <p class="p-3 text-[#4D4D4D] mb-1">
              <ul class="list-disc">
              <li>
                Nominal penarikan kurang dari sama dengan Rp1.000.000,
                <span class="font-semibold">biaya admin = Rp27.500</span>
              </li>
              <li>
                Nominal penarikan lebih dari Rp1.000.000,
                <span class="font-semibold">biaya admin = Rp32.500</span>
              </li>
            </ul>
            </p>
            <div class="flex flex-row">
              <div class="basis-1/3">

              </div>
              <div class="basis-1/3">

              </div>
              <button-primary 
               data-bs-dismiss="modal" 
              class="basis-1/3" :onClick="setMarkerCookie">
              OK
              </button-primary>
            </div>
          </div>
          
        </div>
      </div>
    </div>


<!-- modal popup button ? -->
<div
      class="
        modal
        fade
        fixed
        top-0
        left-0
        hidden
        w-full
        h-full
        outline-none
        overflow-x-hidden overflow-y-auto
      "
      id="biayaAdminModal2"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div
        class="
          modal-dialog modal-dialog-centered
          relative
          w-auto
          pointer-events-none
        "
      >
        <div
          class="
            modal-content
            border-none
            shadow-lg
            relative
            flex flex-col
            w-full
            pointer-events-auto
            bg-white bg-clip-padding
            rounded-md
            outline-none
            text-current
          "
        >
          <div class="modal-body relative p-4">
            <div class="flex flex-row justify-between mb-3 text-[#4D4D4D]">
              <div class="text-xl font-semibold">Biaya Admin</div>
             
            </div>
            <p class="p-3 text-[#4D4D4D] mb-1">
              <ul class="list-disc">
              <li>
                Nominal penarikan kurang dari sama dengan Rp1.000.000,
                <span class="font-semibold">biaya admin = Rp27.500</span>
              </li>
              <li>
                Nominal penarikan lebih dari Rp1.000.000,
                <span class="font-semibold">biaya admin = Rp32.500</span>
              </li>
            </ul>
            </p>
            <div class="flex flex-row">
              <div class="basis-1/3">

              </div>
              <div class="basis-1/3">

              </div>
              <button-primary 
               data-bs-dismiss="modal" 
              class="basis-1/3" :onClick="tes">
              OK
              </button-primary>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <!-- modal pop up input pin -->
    <div
      class="
        modal
        fade
        fixed
        top-0
        left-0
        hidden
        w-full
        h-full
        outline-none
        overflow-x-hidden overflow-y-auto
      "
      id="pinInputModal"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div
        class="
          modal-dialog modal-dialog-centered
          relative
          w-auto
          pointer-events-none
        "
      >
        <div
          class="
            modal-content
            border-none
            shadow-lg
            relative
            flex flex-col
            w-full
            pointer-events-auto
            bg-white bg-clip-padding
            rounded-md
            outline-none
            text-current
          "
        >
          <div class="modal-body relative p-4">
           
              <div class="text-xl font-semibold text-center">Masukkan PIN:</div>
              
               
            
            <div class="text-center mt-5 mb-7">
              <otp-input
                class="mx-auto"
                type="password"
                placeholder="__"
                :isValid="isCodeValid"
                :digits="digits"
                
                @on-complete="onCompleteHandler"
                @on-changed="onChangedHandler"
                @on-paste="onPasteHandler"
              >
              <template #errorMessage> Pin Tidak sama </template>
              
               </otp-input>
               <div v-if="valid" class="text-red-star">{{ErrorCause}}</div>
            </div>

            <div class="flex flex-row">
              <div class="basis-1/2 pr-1">
              <button-primary 
               data-bs-dismiss="modal" 
              class="basis-1/3 " :onClick="test1">
              Cancel
              </button-primary>
              </div>
              <div class="basis-1/2 pl-1">
              <button-primary 
              
               data-bs-dismiss="modal" 
              class="basis-1/3" :onClick="Transaction">
              OK
              </button-primary>
              </div>
              
            </div>
          </div>
          
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {
  setMarker,
  getModalBiayaAdmin,
  RemoveModalAdmin,
} from "@/utils/cookies";

import {
  getNoRek,
  getNameTransaction,
  getBank,
  getAmount,
  getFee,
  getTotal,
  getOldPin,
  getTransId,
} from "@/utils/localstorage.js";
import { formatPrice } from "@/utils/helper.js";
import { TransactionController } from "@/controllers/TransactionController.js";

import ButtonBottom from "@/components/ButtonBottom.vue";
import ButtonPrimary from "@/components/ButtonPrimary.vue";
import NavbarWhite from "@/components/NavbarWhite.vue";
import OtpInput from "@/components/OtpInput.vue";
import CryptoJS from "crypto-js";
export default {
  name: "TransactionSummary",
  components: {
    ButtonBottom,
    NavbarWhite,
    ButtonPrimary,
    OtpInput,
  },
  data() {
    return {
      trans: new TransactionController(false, false, ""),
      valid: false,
      marker: "yes",
      digits: 4,
      isCodeValid: true,
      validation: false,
    };
  },
  computed: {
    ErrorCause() {
      return this.trans.errorCause;
    },

    IsLoading() {
      return this.trans.loading;
    },

    IsError() {
      return this.trans.error;
    },

    getNoRekening() {
      return getNoRek();
    },
    getNama() {
      return getNameTransaction();
    },
    getBank() {
      return getBank();
    },
    getAmount() {
      return getAmount();
    },
    getFee() {
      return getFee();
    },
    getTotal() {
      return getTotal();
    },
    checkModalBiayaAdmin() {
      const modalAdmin = getModalBiayaAdmin();
      return modalAdmin;
    },
  },
  methods: {
    tes(){},
    async setTransaction(amount) {
      return this.trans.transaction(amount);
    },
    async Transaction() {
      if (this.validation == true) {
        await this.setTransaction(getAmount());
        if (!this.IsError) {
          this.$router.push({
            name: "TransactionSuccess",
            params: { id: getTransId() },
          });
        } 
        else if (this.ErrorCause =="cannot proceed request transaction before date 8 each month")
      {
        swal(
          {
            title: "Waktu Penarikan",
            text: "Penarikan baru dapat dilakukan pada minggu ke-2 atau hari ke-8.",
            closeOnClickOutside: false,
            buttons: {
            confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "bg-green-button  rounded-[32px] px-14 hover:bg-green-button-darker text-md",
                closeModal: true
            }
        },
          });
      } 
      else if (this.ErrorCause =="cannot proceed request transaction after date 24 each month")
      {
         swal(
          {
            title: "Waktu Penarikan",
            text: "Penarikan tidak bisa dilakukan setelah tanggal 24",
            closeOnClickOutside: false,
            buttons: {
            confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "bg-green-button  rounded-[32px] px-14 hover:bg-green-button-darker text-md",
                closeModal: true
            }
        },
          });
      } 
        else {
          this.valid = true;
          setTimeout(this.erroMsg, 3000);
        }
      } else {
        this.isCodeValid = false;
      }
    },
    setMarkerCookie() {
      setMarker(this.marker);
      RemoveModalAdmin();
    },

    goBack() {
      this.$router.go(-1);
    },
    erroMsg() {
      this.valid = false;
    },
    convertRupiah(money) {
      return formatPrice(money);
    },
    toggleBtn() {
      const toggleBtn = document.getElementById("toggleBtn");
      toggleBtn.click();
    },
    test1() {},
    test2() {},
    test3() {},
    test4() {},
    onCompleteHandler(code) {
      if (code == getOldPin()) {
        this.validation = true;
      } else {
        this.isCodeValid = false;
      }
    },

    onChangedHandler(lastEnteredCode) {
      this.isCodeValid = true;
    },

    onPasteHandler(code) {},
  },
  mounted() {
    if (this.checkModalBiayaAdmin) {
      this.toggleBtn();
    }
  },
};
</script>