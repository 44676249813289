<template>
  <div class="animate-pulse">
  <div
           
            class="
              flex flex-col
              border-b-2
              mb-2
              border-[#EDEDED]
              hover:bg-[#F2F5FA]
            "
          >
            
            <div class="h-[350px] mx-auto w-full  rounded-lg bg-slate-300" >
             
            </div>

            <div class="mt-1 font-semibold h-5 w-1/2 rounded-full bg-slate-300 mb-1"></div>
            <div class="h-5 rounded-full bg-slate-300  mb-1"></div>
            <div class="text-sm text-loading-greytxt h-5  mb-1 rounded-full bg-slate-300">
             
            </div>
          </div>
  </div>
</template>

<script>
export default {
name:"InboxLoading"
}
</script>

<style>

</style>