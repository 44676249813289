<template>
  <div class="background-blue h-screen w-full relative">
    <img
      alt="waveTop"
      class="absolute top-0 left-0 w-[50wh]"
      :src="require(`../assets/background/bg-success-blue-top.svg`)"
    />

    <img
      alt="waveBottom"
      class="absolute right-0 bottom-0 h-[50vh]"
      :src="require(`../assets/background/bg-success-blue-bottom.svg`)"
    />
    <div class="flex flex-col">
      <img
        alt="checkList"
        class="mx-auto mt-40 z-50"
        :src="require(`../assets/icon/checklistIcon.svg`)"
      />
      <p class="text-white mx-auto mt-4 font-semibold text-lg">
        {{ msg }}
      </p>
      <div class="mx-[30px] z-50">
        <p class="text-center text-white mx-auto mt-2 font-medium text-lg">
          {{ msgDetail }}
        </p>
      </div>
      <button
        class="
          w-28
          py-3
          mt-6
          font-semibold
          bg-white
          mx-auto
          text-nav-blue
          rounded-[32px]
          cursor-pointer
          z-50
        "
        @click="onClick"
      >
        OK
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "SuccessBlue",
  props: {
    msg: String,
    msgDetail: String,
    onClick: {
      type: Function,
    },
  },
};
</script>

<style scoped>
.background-blue {
  background: linear-gradient(162.46deg, #003399 22.93%, #0da9e4 82.55%);
}
.testing {
  right: 0px;
  bottom: 0px;
  position: absolute;
  height: 50vh;
}
</style>