<template>
  <div
    class="
      mt-1
      fixed
      inset-x-0
      bottom-0
      z-10
      mw:w-[480px]
      w-auto
      bg-white
      mx-auto
      shadow-[0_-4px_4px_-2px_rgba(0,0,0,0.25)]
    "
  >
    <div class="flex flex-row justify-between mx-[30px]">
      <router-link to="/home" class="pt-2 font-normal text-grey-nav text-xs"
        ><img
          class="mx-auto w-7 a"
          :src="require(`@/assets/icon/iconHome.svg`)"
          alt="home"
        />Home</router-link
      >
      <router-link
        to="/transaction-history"
        class="pt-2 font-normal a text-grey-nav text-xs"
        ><img
          class="mx-auto w-7 a"
          :src="require(`@/assets/icon/iconRiwayat.svg`)"
          alt="riwayat"
        />Riwayat</router-link
      >
      <div class="pt-2 font-normal text-grey-nav text-xs">
        <router-link to="/notification">
          <img
            class="mx-auto w-6 a"
            :src="require(`@/assets/icon/iconNotif.svg`)"
            alt="notif"
          />
          Notifikasi</router-link
        >
        <div v-if="ActivityList">
          <img
           
            class="absolute animate-ping top-2 ml-7 w-3"
            :src="require(`@/assets/icon/blink.svg`)"
            alt="notif"
          />
          <img
            class="absolute top-2 ml-7 w-3"
            :src="require(`@/assets/icon/blink.svg`)"
            alt="notif"
          />
          </div>
          <div v-else>

          </div>
      </div>

      <router-link
        to="/profile"
        class="pt-2 font-normal a text-grey-nav text-xs"
        ><img
          class="mx-auto w-7 a"
          :src="require(`@/assets/icon/iconProfil.svg`)"
          alt="profil"
        />Profil</router-link
      >
    </div>
  </div>
</template>

<script>
import { NotificationController } from "@/controllers/NotificationController.js";

import { setNotif, getNotif } from "@/utils/cookies";

export default {
  name: "NavbarBottom",
  data() {
    return {
      meta: {
        page: 1,
        size: "",
      },
      notif: new NotificationController(false, false, ""),
    };
  },
  methods: {
   
     async getInboxlist(page, size) {
      return this.notif.getInboxList(page, size);
    },
    async getInbox() {
      await this.getInboxlist(this.meta.page, this.meta.size);
    },
      async getActivitylist(page, size) {
      return this.notif.getActivityList(page, size);
    },
    async getActivity() {
      await this.getActivitylist(this.meta.page, this.meta.size);},
    setNotification() {
      setNotif(this.notif);
     },
  },
  computed: {
    getNotification() {
      return getNotif();
    },
     isError() {
      return this.notif.error;
    },
    ActivityList() {
    let list = this.notif.activityLists.filter((notif) => !notif.isRead)
    if (list.length > 0 ){
      return true
    } else if (list.length == 0 ){
      return false
    }
    },
    InboxList() {
      return this.notif.inboxLists;
    },
    errorCause() {
      return this.notif.errorCause;
    },

    isLoading() {
      return this.notif.loading;
    },
  },
 created(){
   this.getActivity();
     this.getInbox();
 }
};
</script>
<style scoped>
a:hover,
a.router-link-active {
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(7%) sepia(95%) saturate(5952%)
    hue-rotate(219deg) brightness(100%) contrast(100%);
}

.hoveran {
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(7%) sepia(95%) saturate(5952%)
    hue-rotate(219deg) brightness(100%) contrast(100%);
}
</style>