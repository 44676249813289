import axios from 'axios'
import { History, historyDetail, cancelTransaction } from '../endpoint/index'
import { BaseUrl } from '../endpoint/BaseUrl'
import { getToken } from '../../utils/localstorage'

const headers = {
    'Authorization': `Bearer ${getToken()}`
}

export const GetHistoryListRequest = (page, size,) => {
    let resp = null;
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${History(page, size,)}`,
        headers: headers
    }).then((response) => {
        return response
    })
    return resp
}

export const GetHistoryDetailRequest = (id) => {
    let resp = null;
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${historyDetail(id)}`,
        headers: headers
    }).then((response) => {
        return response
    })
    return resp
}


export const CancelTransaction = (id) => {
    let resp = null;
    resp = axios({
        method: 'put',
        url: `${BaseUrl()}${cancelTransaction(id)}`,
        headers: headers
    }).then((response) => {
        return response
    })
    return resp
}