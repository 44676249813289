import { SetupPinRequest,SendVerifyPinRequest, VerifyPinRequest, changePinRequest } from '../api/request/PinRequest'
import { setOldPin } from '../utils/localstorage'
import { GetProfileRequest } from '../api/request/ProfileRequest'
import { sessionExpired } from "@/utils/helper";

export class PinController {
    loading = false
    error = false
    errorCause = ''
    detail = []
    verifyPin = []
    newPin = []
    setupPinDetail = []
    constructor(loading, error, errorCause) {
        this.loading = loading
        this.error = error
        this.errorCause = errorCause
    }

    setupPin(pin) {
        let resp = null
        this.setLoading(true)
        resp = SetupPinRequest(pin)
            .then((response) => {
                this.setSetupPinDetail(response.data)
                this.setErrorCause("")
                this.setError(false)
                return true
            }).catch((err) => {
                if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err.response.data.message)
                    this.setError(true)
                }

                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }

    emailVerifyPin(email) {
        let resp = null
        this.setLoading(true)
        resp = SendVerifyPinRequest(email)
            .then((response) => {

                this.setDetail(response.data)
                this.setErrorCause("")
                this.setError(false)


                return true
            }).catch((err) => {

                if (err.response.data.code === 404) {
                    this.setErrorCause(err.response.data.message)
                    this.setError(false)
                    this.setInboxLists(err.response.data)

                } else if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err.response.data.message)
                    this.setError(true)
                }
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }

    verifyPIN(verifyCode) {
        let resp = null
        this.setLoading(true)
        resp = VerifyPinRequest(verifyCode)
            .then((response) => {

                this.setVerifyDetail(response.data)
                this.setErrorCause("")
                this.setError(false)


                return true
            }).catch((err) => {

                if (err.response.data.code === 404) {
                    this.setErrorCause(err.response.data.message)
                    this.setError(false)
                    this.setInboxLists(err.response.data)

                } else if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err.response.data.message)
                    this.setError(true)
                }
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }

    changePIN(pin) {
        let resp = null
        this.setLoading(true)
        resp = changePinRequest(pin)
            .then((response) => {

                this.setNewPin(response.data)
                this.setErrorCause("")
                this.setError(false)

                return true
            }).catch((err) => {

                if (err.response.data.code === 404) {
                    this.setErrorCause(err.response.data.message)
                    this.setError(false)
                    this.setInboxLists(err.response.data)

                } else if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err.response.data.message)
                    this.setError(true)
                }
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }

    setOldPin() {
        let resp = null
        this.setLoading(true)
        resp = GetProfileRequest()
            .then((response) => {

                this.setPinDetail(response.data.data.pin)

                this.setErrorCause("")
                this.setError(false)

                return true
            }).catch((err) => {

                if (err.response.data.code === 404) {
                    this.setErrorCause(err.response.data.message)
                    this.setError(false)
                    this.setInboxLists(err.response.data)

                } else if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err.response.data.message)
                    this.setError(true)
                }
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }

    setNewPin(data) {
        this.newPin = data
    }
    setDetail(data) {
        this.detail = data
    }
    setPinDetail(pin) {
        setOldPin(pin)
    }
    setSetupPinDetail(data) {
        this.setupPinDetail = data
    }
    setVerifyDetail(data) {
        this.verifyPin = data
    }

    setLoading(status) {
        this.loading = status
    }

    setError(err) {
        this.error = err
    }

    setErrorCause(cause) {
        this.errorCause = cause
    }
}
