<template>
  <div class="h-full bg-[#003399] relative">
    <div>
      <img :src="require(`@/assets/background/bg-wavy-home.svg`)" />
      <div>
        <img
          alt="logo"
          class="top-4 absolute w-28 left-3.5"
          :src="require(`@/assets/icon/logoIcon.svg`)"
        />
        <div
            @click="toHelp"
            class="
              top-5
              right-5
              pl-2 
              absolute 
              cursor-pointer 
              flex 
              flex-row
              rounded-2xl
              border-[0.5px] border-grey-nav
              bg-white
              text-black
            "
          >
            Bantuan
            <img
              alt="faq"
              @click="toHelp"
              class="ml-1 mr-2 w-4 cursor-pointer"
              :src="require(`@/assets/icon/newfaqlogo.svg`)"
            />
          </div>
        <!-- <img
          alt="faq"
          @click="toFaq"
          class="top-4 right-3.5 right-0 absolute w-11 cursor-pointer"
          :src="require(`@/assets/icon/faqIcon.svg`)"
        /> -->
        <div class="absolute top-16 844:top-24 465:top-14 570:top-16 left-3.5">
          <div class="text-white font-semibold">Hi {{ setName }}</div>
          <div class="text-white font-semibold">
            {{ setCompanyName }}
          </div>
        </div>
        <div
          class="
            absolute
            465:top-[125px]
            570:top-[125px]
            844:top-[166px]
            left-3.5
            top-[138px]
          "
        >
          <div
            class="
              border-[0.5px] border-grey-nav
              bg-nav-blue
              text-white
              w-fit
              pl-2
              rounded-2xl
              flex flex-row
              font-semibold
            "
          >
            10000 POIN
            <img
              alt="arrow"
              class="filter"
              :src="require(`@/assets/icon/arrowRightIcon.svg`)"
            />
          </div>
        </div>

        <div
          class="
            absolute
            844:top-36
            465:top-24
            top-28
            570:top-24
            right-3.5
            text-right
          "
        >
          <div class="text-white font-semibold">Saldo hakku</div>
          <div v-if="isLoading">
            <home-loading-blue/>
          </div>
          <div v-else>
            <div v-if="balanceDataList.balance === 0">
              <p v-if="isLoggedIn" v-on:mouseover="showTooltip = true" v-on:mouseout="showTooltip = false">
                Halo, {{ setName }}!
              <span v-show="showTooltip" class="tooltip">Selamat datang!</span>
              </p>
            </div>
            <div class="text-xl text-white font-semibold">
            {{ convertRupiah(balanceDataList.balance) }}
            <p v-if="isLoggedIn" v-on:mouseover="showTooltip = true" v-on:mouseout="showTooltip = false">
                Halo, {{ setName }}!
              <span v-show="showTooltip" class="tooltip">Selamat datang!</span>
              </p>
          </div>
          <div class="text-white">
            Dari total: {{ convertRupiah(balanceDataList.limit) }}
          </div>
          </div>
          
        </div>
      </div>

      <!-- //Loading -->
      <div
        v-if="isLoading"
        class="
          w-full
          absolute
          top-[185px]
          844:top-[220px]
          rounded-t-[32px]
          bg-white
          h-full
        "
      >
       <home-loading/>
      </div>
      <!-- ini div item -->
      <div
        v-else
        class="
          w-full
          absolute
          top-[185px]
          844:top-[220px]
          rounded-t-[32px]
          bg-white
        "
      >
        <form class="mt-4">
          <div class="mx-[30px]">
            <label
              class="block text-nav-blue text-lg font-semibold"
              
            >
              <div class="flex flex-row">
                <img
                  alt="wallet"
                  class="filters"
                  :src="require(`@/assets/icon/walletIcon.svg`)"
                />
                <div class="ml-2">Input Jumlah Penarikan</div>
              </div>
            </label>

            <div class="relative w-full">
              <div
                class="
                  flex
                  absolute
                  inset-y-0
                  left-0
                  items-center
                  pointer-events-none
                  font-semibold
                  text-lg
                "
              >
                Rp
              </div>
              <amount-input
                v-model="amount"
                :inquiry="sendInquiry"
              ></amount-input>
              <input type="text" class="hidden" />
              <div
                class="
                  flex
                  absolute
                  inset-y-0
                  right-0
                  items-center
                  text-lg
                  pointer-events-none
                "
              >
                <img
                  alt="pencil"
                  :src="require(`@/assets/icon/pencilIcon.svg`)"
                  class="w-9"
                />
              </div>
            </div>
            <div class="mb-2">
              <div v-if="amount === ''">
                <p class="text-[#DC2626]"><span> </span></p>
              </div>
              <div v-else-if="amount < 300000">
                <p class="text-[#DC2626]">Minimal penarikan Rp300.000</p>
              </div>
              <div v-else-if="amount > balanceDataList.balance">
                <p class="text-[#DC2626]">Saldo tidak mencukupi</p>
              </div>
              <div
                class="text-red-star"
                v-if="
                  (inquiryErrorCause == 'Not a valid amount' && amount == '') ||
                  (inquiryErrorCause == 'your previous request transaction still in process')
                "
              >
                <div v-if="valid">
                  {{ inquiryErrorCause }}
                </div>
              </div>
            </div>
          </div>
          <div class="w-1/2 mt-7 mx-auto 680:mt-11" >
          <div v-if="balanceDataList.balance === 0"> <button-disabled>Tarik Gaji</button-disabled></div>
           <div v-else>
             <button-primary :isLoading="inquiryIsLoading" :onClick="sendInquiry" >Tarik Gaji</button-primary>
            </div>
            <button
              class="hidden"
              id="toggleBtn"
              type="button"
              data-bs-target="#waktuPenarikan"
              data-bs-toggle="modal"
            >
              tes
            </button>
          </div>
        </form>

        <div
          class="
            flex flex-row
            justify-between
            mt-4
            650:mt-5
            680:mt-8
            hmm:mx-[10px]
            hmmm:mx-[10px]
            mx-[30px]
          "
        >
          <div
            class="
              flex flex-col
              680:w-[190px]
              570:w-[190px]
              400:w-[190px]
              hm:w-44
              hmm:w-[180px]
              hmmm:w-[165px]
            "
          >
            <div
              class="
                h-20
                570:h-[75px]
                bg-white
                mb-2
                flex flex-col
                shadow-[0_2px_8px_1px_rgba(0,0,0,0.1)]
                rounded
              "
            >
              <div class="my-auto pl-4">
                <div class="font-semibold text-base hm:text-sm hmmm:text-sm">
                  Terakhir diperbarui
                </div>
                <div class="text-base hm:text-sm hmmm:text-sm">
                  {{ getDate }}
                </div>
              </div>
            </div>
            <div
              class="
                h-20
                570:h-[75px]
                bg-white
                mb-2
                flex flex-col
                shadow-[0_2px_8px_1px_rgba(0,0,0,0.1)]
                rounded
              "
            >
              <div class="my-auto pl-4">
                <div class="font-semibold text-base hm:text-sm hmmm:text-sm">
                  Total Hari Kerja
                </div>
                <div class="text-base hm:text-sm hmmm:text-sm">
                  {{ balanceDataList.totalMandays }} Hari
                </div>
              </div>
            </div>
          </div>

          <div
            class="
              hm:w-44
              hmm:w-[180px]
              hmmm:w-[165px]
              h-[167px]
              570:w-[185px] 570:h-[159px]
              400:w-[190px]
              570:h-[159px]
              680:w-[185px] 680:h-[160px]
              bg-white
              mb-2
              flex flex-col
              shadow-[0_2px_8px_1px_rgba(0,0,0,0.1)]
              rounded
            "
          >
            <div class="my-auto">
              <progress-circle
                class="mx-auto"
                circle-color="#ededed"
                start-color="#16A34A"
                stop-color="#16A34A"
                :completed-steps="steps"
                :total-steps="balanceDataList.totalMandays"
                :animation-duration="duration"
                :diameter="diameter"
                :circle-width="circleWidth"
                ><div class="text-center font-semibold text-2xl">
                  {{ balanceDataList.mandays }}
                </div>
                <div class="text-center px-3">Hari kerja Berjalan</div>
              </progress-circle>
            </div>
          </div>
        </div>
        <div class="h-16 bg-white"></div>
      </div>
    </div>
    <!-- navbar bawah -->
    <div>
      <navbar-bottom> </navbar-bottom>
    </div>

    <!-- modal waktu penarikan -->
    <div
      v-if="checkModal"
      class="
        modal
        fade
        fixed
        top-0
        left-0
        hidden
        w-full
        h-full
        outline-none
        overflow-x-hidden overflow-y-auto
      "
      id="waktuPenarikan"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div
        class="
          modal-dialog modal-dialog-centered
          relative
          w-auto
          pointer-events-none
        "
      >
        <div
          class="
            modal-content
            border-none
            shadow-lg
            relative
            flex flex-col
            w-full
            pointer-events-auto
            bg-white bg-clip-padding
            rounded-md
            outline-none
            text-current
          "
        >
          <div class="modal-body relative p-4">
            <div class="flex flex-row justify-between mb-3 text-[#4D4D4D]">
              <div class="text-xl font-semibold">Waktu Penarikan</div>
              <div></div>
            </div>
            <p class="p-3 text-[#4D4D4D] mb-1">
              Penarikan baru dapat dilakukan pada minggu ke-2 atau hari ke-8.
            </p>
            <div class="flex flex-row">
              <div class="basis-1/3"></div>
              <div class="basis-1/3"></div>
              <button-primary
                data-bs-dismiss="modal"
                class="basis-1/3"
                :onClick="modalRemove"
              >
                OK
              </button-primary>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.my-title {
  text-align: left;
  position: relative;
  top: -20px;
  font-size: 20px;
  font-weight: semi-bold;
}
.my-text {
  text-align: left;
  font-size: 16px;
  padding-left: 30px;
  font-weight: semi-bold;
  position: relative;
  top: -15px;
}
.tooltip {
  display: inline-block;
  position: absolute;
  padding: 5px;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  z-index: 1;
}

</style>
<script>
import ButtonDisabled from "@/components/ButtonDisabled.vue"
import moment from "moment";
import HomeLoadingBlue from "@/components/HomeLoadingBlue.vue"
import HomeLoading from "@/components/HomeLoading.vue"
import { TransactionController } from "@/controllers/TransactionController.js";
import AmountInput from "@/components/AmountInput.vue";
import { HomeController } from "@/controllers/HomeController.js";
import ButtonPrimary from "@/components/ButtonPrimary.vue";
import { ProgressCircle } from "vue-progress-circle";
import NavbarBottom from "@/components/NavbarBottom.vue";
import LoadingBlue from "@/components/LoadingBlue.vue";
import { formatPrice, formatDate } from "@/utils/helper.js";
import {
  getMarker,
  getModal,
  removeModal,
  setModalBiayaAdmin,
  setNotif,
  getNotif,
} from "@/utils/cookies";
import { getName, getOldPin, getCompanyName } from "@/utils/localstorage";
export default {
  name: "Home",
  components: {
    NavbarBottom,
    ButtonPrimary,
    ProgressCircle,
    LoadingBlue,
    AmountInput,
    HomeLoading,
    ButtonDisabled,
  },
  data() {
    return {
      valid: false,
      hk_modal_biayaAdmin: "yes",
      trans: new TransactionController(false, false, ""),
      moment: moment,
      home: new HomeController(false, false, ""),
      amount: "",
      duration: 3000,
      steps: 0,
      balance: 2000000,
      diameter: 160,
      circleWidth: 16,
      notif: "yes",
      isLoggedIn: false,
      showTooltip: false

    };
  },
  computed: {
    isError() {
      return this.home.error;
    },
    balanceDataList() {
      return this.home.balanceData;
    },
    errorCause() {
      return this.home.errorCause;
    },

    inquiryErrorCause() {
      return this.trans.errorCause;
    },

    inquiryIsLoading() {
      return this.trans.loading;
    },

    inquiryIsError() {
      return this.trans.error;
    },

    getDate() {
      return moment(this.home.balanceData.renewalAt)
        .locale("id")
        .format("DD MMMM YYYY");
    },
    isLoading() {
      return this.home.loading;
    },

    checkModal() {
      const modal = getModal();
      return modal;
    },
    checkMarker() {
      const marker = getMarker();
      return marker;
    },
    setName() {
      const name = getName();
      return name;
    },
    setCompanyName() {
      const compName = getCompanyName();
      return compName;
    },
     isAmountEmpty() {
      if(this.amount===''){
        return true
      }
      else{
        return false
      }
    }
  },
  methods: {
    erroMsg() {
      this.valid = false;
    },
    toFaq() {
      this.$router.push("/faq");
    },
    toHelp() {
      this.$router.push("/help");
    },
    toSummary() {
      if (!this.checkMarker) {
        setModalBiayaAdmin(this.hk_modal_biayaAdmin);
        this.$router.push("/transaction-summary");
      } else {
        this.$router.push("/transaction-summary");
      }
    },
    changeSteps() {
      setTimeout(this.StepsChange, 2000);
    },
    StepsChange() {
      if (this.balanceDataList.mandays >= this.balanceDataList.totalMandays) {
        this.steps = this.balanceDataList.totalMandays;
      } else {
        this.steps = this.balanceDataList.mandays;
      }
    },
    toggleBtn() {
      const toggleBtn = document.getElementById("toggleBtn");
      toggleBtn.click();
    },
    modalRemove() {
      removeModal();
    },
    convertRupiah(money) {
      return formatPrice(money);
    },
    convertDate(date) {
      return formatDate(date);
    },

    test() {},
    async getbalance() {
      return this.home.getBalanceData();
    },
    async getBalance() {
      return this.getbalance();
    },

    async setInquiryTransaction(amount) {
      return this.trans.inquiry(amount);
    },
    login() {
      // kode untuk login
      this.isLoggedIn = true
      this.showTooltip = true
    },

    async sendInquiry() {
      await this.setInquiryTransaction(this.amount);
      if (!this.inquiryIsError) {
        this.toSummary();
      } 
      else if (this.inquiryErrorCause =="cannot proceed request transaction before date 8 each month")
      {
        swal(
          {
            title: "Waktu Penarikan",
            text: "Penarikan baru dapat dilakukan pada minggu ke-2 atau hari ke-8.",
            closeOnClickOutside: false,
            buttons: {
            confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "bg-green-button  rounded-[32px] px-14 hover:bg-green-button-darker text-md",
                closeModal: true
            }
            
        },
          });
      }
      else if (this.inquiryErrorCause =="cannot proceed request transaction after date 24 each month")
      {
        swal(
          {
            title: "Waktu Penarikan",
            text: "Penarikan tidak bisa dilakukan setelah tanggal 24",
            closeOnClickOutside: false,
            buttons: {
            confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "bg-green-button  rounded-[32px] px-14 hover:bg-green-button-darker text-md",
                closeModal: true
            }
        },
          });
      }
  
      else {
        this.valid = true;
        setTimeout(this.erroMsg, 3000);
      }
    },
  },
  mounted() {
    this.changeSteps();
    if (this.checkModal) {
      this.toggleBtn();
    }
    this.getBalance();
  },
};
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.filter {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%)
    hue-rotate(143deg) brightness(113%) contrast(101%);
}
.inp {
  border-bottom: 1.08px solid #7b7b7b;
}
.filters {
  filter: brightness(0) saturate(100%) invert(7%) sepia(95%) saturate(5952%)
    hue-rotate(219deg) brightness(100%) contrast(100%);
}

</style>