<template>
  <div>
    <div
      class="
        flex
        justify-center
        items-center
        long:mt-[196px]
        mt-[190px]
        mx-[30px]
      "
    >
      <img :src="require(`@/assets/icon/nodataIcon.svg`)" alt="NoData" />
    </div>
    <p class="text-center text-grey-input">{{msg}}</p>
  </div>
</template>
<script>
export default {
  name: "TransactionEmpty",
  props:{
    msg:String
  }
};
</script>