<template>
  <div>
    <div class="mt-8">
        <div v-if="ActivityList.success == false">
          <transaction-empty msg="Belum ada aktifitas"/>
        </div>
        <div v-else>
          <div
            v-for="item in ActivityList"
            :key="item.id"
            class="
              w-full
              h-[110px]
              nt:h-[125px]
              hover:bg-[#B4BFCD]

              p-4
              border-b-2 border-[#EDEDED]
            "
            
            :class="{'bg-[#F1F5F9]': !item.isRead, 'bg-white': item.isRead}"
          >
            <div class="flex flex-col " >
              <div class="w-full text-base cursor-pointer" @click="getUpdateStatus(item.id)">
                {{ item.description }}
              </div>
              
              <div
                class="flex flex-row justify-start text-sm text-loading-greytxt"
              >
                <div>
                  <img
                    alt="clock"
                    class="mr-1 w-5"
                    :src="require(`@/assets/icon/miniClockIcon.svg`)"
                  />
                </div>
                <div class="mr-1">
                  {{ moment(item.createdAt).locale("id").format("DD-MM-YYYY") }}
                </div>
                <div>
                  {{ moment(item.createdAt).locale("id").format("LT") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import ActivityLoading from "@/components/ActivityLoading.vue"
import { setNotif, getNotif } from "@/utils/cookies";
import TransactionEmpty from "@/components/TransactionEmpty.vue";
import { NotificationController } from "@/controllers/NotificationController.js";
import LoadingBlue from "@/components/LoadingBlue.vue";
import moment from "moment";
export default {
  name: "Activity",
  components: {
    TransactionEmpty,
    LoadingBlue,
    ActivityLoading
  },
  data() {
    return {
      moment: moment,
      meta: {
        page: 1,
        size: "",
      },
      notip: "yes",
      notif: new NotificationController(false, false, ""),
    };
  },
  computed: {
    isError() {
      return this.notif.error;
    },
    ActivityList() {
      return this.notif.activityLists;
    },
    errorCause() {
      return this.notif.errorCause;
    },

    isLoading() {
      return this.notif.loading;
    },
  },

  mounted() {
    this.getActivity();
  },
  methods: {
    async getActivitylist(page, size) {
      return this.notif.getActivityList(page, size);
    },
    async getActivity() {
      await this.getActivitylist(this.meta.page, this.meta.size);
    },
    async getUpdateActivity(id) {
      return this.notif.getActivityListUpdate(id);
    },
    async getUpdateStatus(id) {
      await this.getUpdateActivity(id);
      this.getActivity();
    },
    setNotification() {
      setNotif(this.notip);
      
    },
  },
  created() {
    this.setNotification();
  },
};
</script>