import axios from 'axios'
import { RequestInquiry, RequestTransaction, Download } from '../endpoint/index'
import { BaseUrl } from '../endpoint/BaseUrl'
import { getToken } from '../../utils/localstorage'

const headers = {
    'Authorization': `Bearer ${getToken()}`
}

export const Inquiry = (amount) => {
    let resp = null;
    resp = axios({
        method: 'post',
        url: `${BaseUrl()}${RequestInquiry()}`,
        headers: headers,
        data: {
            amount: amount,
        },
    }).then((response) => {
        return response
    })
    return resp
}

export const Transaction = (amount) => {
    let resp = null;
    resp = axios({
        method: 'post',
        url: `${BaseUrl()}${RequestTransaction()}`,
        headers: headers,
        data: {
            amount: amount,
        },
    }).then((response) => {
        return response
    })
    return resp
}

export const downloadRequest = (id) => {
    let resp = null;
    resp = axios({
        method: 'get',
        url: `${BaseUrl()}${Download(id)}`,
        headers: headers,
    }).then((response) => {
        return response
    })
    return resp
}