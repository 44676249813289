import {  AuthRequest, RegistRequest, ResetPasswordRequest, ChangePasswordRequest } from '../api/request/AuthRequest'
import { sessionExpired } from "@/utils/helper";

import { setAuthentication, setOldPin } from '../utils/localstorage'
export class AuthControllers {
    loading = false
    error = false
    errorCause = ''
    regisDetail = []
    resetPassDetail = []
    changePassDetail = []
   
    response = []

    constructor(loading, error, errorCause) {
        this.loading = loading
        this.error = error
        this.errorCause = errorCause
    }

    signIn(email, password) {
        let resp = null
        this.setLoading(true)
        resp = AuthRequest(email, password)
            .then((response) => {
                this.setPinDetail(response.data.data.user.pin)
                this.setLoginDetail(response.data.data.token, response.data.data.user.name, response.data.data.user.client.companyName, response.data.data.user.email)
                this.setErrorCause("")
                this.setError(false)
                this.setResponse(response.data)

                return true
            }).catch((err) => {

                this.setErrorCause(err.response.data.message)
                this.setError(true)
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }

    register(fullName, email, mobileNumber, companyName, companyTotalEmployee, companyAddress) {
        let resp = null
        this.setLoading(true)
        resp = RegistRequest(fullName, email, mobileNumber, companyName, companyTotalEmployee, companyAddress)
            .then((response) => {
                this.setRegistDetail(response.data)
                this.setErrorCause("")
                this.setError(false)


                return true
            }).catch((err) => {

                this.setErrorCause(err.response.data.message)



                this.setError(true)

                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }

    

    resetPassword(email) {
        let resp = null
        this.setLoading(true)
        resp = ResetPasswordRequest(email)
            .then((response) => {
                this.setResetPassDetail(response.data)
                this.setErrorCause("")
                this.setError(false)
                return true
            }).catch((err) => {
                if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err.response.data.message)
                    this.setError(true)
                }
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }

    changePassword(oldPassword, newPassword, confirmationPassword) {
        let resp = null
        this.setLoading(true)
        resp = ChangePasswordRequest(oldPassword, newPassword, confirmationPassword)
            .then((response) => {
                this.setChangePassDetail(response.data)
                this.setErrorCause("")
                this.setError(false)
                return true
            }).catch((err) => {
                if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err.response.data.message)
                    this.setError(true)
                }
                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }
    setResponse(data) {
        this.response = data
    }
   

    setResetPassDetail(data) {
        this.resetPassDetail = data
    }
    setChangePassDetail(data) {
        this.changePassDetail = data
    }

    setPinDetail(pin) {
        setOldPin(pin)
    }

    setLoginDetail(token, name, company, email) {
        setAuthentication(token, name, company, email)
    }
    setRegistDetail(data) {
        this.regisDetail = data
    }


    setLoading(status) {
        this.loading = status
    }

    setError(err) {
        this.error = err
    }

    setErrorCause(cause) {
        this.errorCause = cause
    }
}
