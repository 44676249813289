<template>
  <div class="h-screen">
    <div>
      <navbar-white 
      textColor="text-black"
      srcPictureLeft="backIconBlack.svg" :onClickBack="goBack" />
      <div class="font-semibold text-xl mt-5 text-center">PIN Lama HAKKU</div>
      <div class="mt-2 mb-3 text-center">Masukkan 4-digit PIN HAKKU</div>
      <div class="text-center mb-9">
        <otp-input
          class="mx-auto"
          type="password"
          placeholder="__"
          :isValid="isCodeValid"
          :digits="digits"
          :keyNumber="keyNumber"
          @on-complete="onCompleteHandler"
          @on-changed="onChangedHandler"
          @on-paste="onPasteHandler"
        >
          <template #errorMessage> PIN Salah! </template>
        </otp-input>
      </div>
      <p
        class="
          font-semibold
          cursor-pointer
          mx-auto
          mb-5
          text-green-button
          w-fit
        "
        @click="sendEmail"
      >
        Lupa PIN HAKKU
      </p>
      <loading
        color="#003399"
        background-color="#000000"
        :active.sync="isLoading"
        :is-full-page="fullPage"
      ></loading>

      <key-pad @clickNumber="clickNumber($event)" />
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { getEmail, getOldPin } from "@/utils/localstorage";
import { setVerified } from "@/utils/localstorage";
import { PinController } from "@/controllers/PinController";
import OtpInput from "@/components/OtpInput.vue";
import NavbarWhite from "@/components/NavbarWhite.vue";
import LoadingBlue from "@/components/LoadingBlue.vue";
// import PinInput from "@/components/PinInput.vue";
import KeyPad from "@/components/KeyPad.vue";
export default {
  name: "SetupPin",
  data() {
    return {
      isCodeValid: true,
      digits: 4,
      keyNumber: null,
      PIN: new PinController(false, false, ""),
      fullPage: true,
    };
  },
  components: {
    NavbarWhite,
    OtpInput,
    KeyPad,
    LoadingBlue,
    Loading,
  },
  computed: {
    getEmail() {
      return getEmail();
    },
    isError() {
      return this.PIN.error;
    },

    errorCause() {
      return this.PIN.errorCause;
    },

    isLoading() {
      return this.PIN.loading;
    },
  },
  methods: {
    async sendVerifPin(email) {
      return this.PIN.emailVerifyPin(email);
    },
    async sendEmail() {
      await this.sendVerifPin(getEmail());
      if (!this.isError) {
        this.toForgotPin();
      }
    },
    clickNumber(evt) {
      if (evt == this.keyNumber) {
        this.keyNumber = `$${evt}`;
      } else {
        this.keyNumber = `${evt}`;
      }
    },

    onCompleteHandler(code) {
      if (code == getOldPin()) {
        setVerified();
        this.$router.push({
          name: "NewPin",
        });
      } else {
        this.isCodeValid = false;
      }
    },

    onChangedHandler(lastEnteredCode) {
      this.isCodeValid = true;
    },

    onPasteHandler(code) {},
    goBack() {
      this.$router.push("/profile");
    },
    toForgotPin() {
      this.$router.push("/forgotpin");
    },
  },
};
</script>