<template>
  <div class="h-screen relative">
    <div class="h-full">
      <navbar-white
        boxShadow="shadow-[0_4px_4px_-2px_rgba(0,0,0,0.25)]"
        title="Detail Transaksi"
        srcPictureLeft="backIconBlack.svg"
        :onClickBack="goBack"
      />
      <div class="mt-4 mx-[30px]">
        <div class="flex flex-col">
          <img
            alt="waitingaprov"
            class="mx-auto w-11"
            :src="require(`@/assets/icon/waiting_approve.svg`)"
          />
          <p class="mx-auto mt-3 mb-1 font-semibold">
            Menunggu Persetujuan
          </p>
          <div class="mx-auto mt-3 mb-6 text-sm ">
            <p>Transaksi anda sedang menunggu persetujuan</p>
            <p class="text-center"> dari perusahaan</p>
          </div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Nomor Referensi</div>
          <div class="text-right">{{ item.requestTransactionId }}</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Tanggal Transaksi</div>
          <div>
            {{ moment(item.createdAt).locale("id").format("DD-MM-YYYY") }}
          </div>
        </div>

        <div class="flex flex-row justify-between mb-4">
          <div class="font-semibold">Waktu Transaksi</div>
          <div>{{ moment(item.createdAt).locale("id").format("LTS") }} WIB</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">No. Rekening</div>
          <div>{{ item.bankAccountNumber }}</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Nama Penerima</div>
          <div>{{ item.bankAccountName }}</div>
        </div>

        <div class="flex flex-row justify-between mb-4">
          <div class="font-semibold">Bank Tujuan</div>
          <div>{{ item.bankAccount }}</div>
        </div>

        <hr class="mb-4" />

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Nominal</div>
          <div>{{ convertRupiah(item.amount) }}</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Biaya Admin</div>
          <div>- {{ convertRupiah(item.fee) }}</div>
        </div>

        <div class="flex flex-row justify-between">
          <div class="font-semibold">Total</div>
          <div class="font-semibold">
            {{ convertRupiah(item.amountTransfer) }}
          </div>
        </div>
      </div>
    </div>
    <button-bottom
      :Click="test"
      data-bs-target="#confirmModal"
      data-bs-toggle="modal"
      >Ajukan Pembatalan</button-bottom
    >

    <!-- confirmation modal -->
    <div
      class="
        modal
        fade
        fixed
        top-0
        left-0
        hidden
        w-full
        h-full
        outline-none
        overflow-x-hidden overflow-y-auto
      "
      id="confirmModal"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div
        class="
          modal-dialog modal-dialog-centered
          relative
          w-auto
          pointer-events-none
        "
      >
        <div
          class="
            modal-content
            border-none
            shadow-lg
            relative
            flex flex-col
            w-full
            pointer-events-auto
            bg-white bg-clip-padding
            rounded-md
            outline-none
            text-current
          "
        >
          <div class="modal-body relative p-4">
            <div class="text-xl font-semibold text-center mb-2">
              Konfirmasi Transaksi
            </div>

            <p class="p-4 mb-1 text-center">
              Apakah Anda yakin ingin mengajukan pembatalan?
            </p>
            <div class="flex flex-row">
              <div class="basis-1/2 pr-1">
                <button-primary data-bs-dismiss="modal" :onClick="test1">
                  Tidak
                </button-primary>
              </div>
              <div class="basis-1/2 pl-1">
                <button
                  data-bs-dismiss="modal"
                  v-if="!isLoading"
                  @click="toCancelDetail(item.id)"
                  class="
                    w-full
                    rounded-[32px]
                    bg-green-button
                    text-white
                    hover:bg-green-button-darker
                    h-12
                    text-xl
                    font-semibold
                    mx-auto
                  "
                >
                  Iya
                </button>
                <button
                  data-bs-dismiss="modal"
                  v-else
                  @click="toCancelDetail(item.id)"
                  class="
                    w-full
                    h-12
                    text-xl
                    font-semibold
                    mx-auto
                    rounded-[32px]
                    bg-loading-greybg
                    text-loading-greytxt
                    hover:bg-grey-hover hover:rounded-[32px]
                  "
                >
                  Iya
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HistoryController } from "@/controllers/HistoryController.js";

import { formatPrice } from "@/utils/helper.js";
import moment from "moment";
import ButtonBottom from "@/components/ButtonBottom.vue";
import ButtonPrimary from "@/components/ButtonPrimary.vue";
import NavbarWhite from "@/components/NavbarWhite.vue";
export default {
  name: "TransactionCanceled",
  components: {
    ButtonBottom,
    NavbarWhite,
    ButtonPrimary,
  },
  data() {
    return {
      History: new HistoryController(false, false, ""),
      moment: moment,
    };
  },
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    isError() {
      return this.History.error;
    },

    errorCause() {
      return this.History.errorCause;
    },

    isLoading() {
      return this.History.loading;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    convertRupiah(money) {
      return formatPrice(money);
    },
    test() {},
    test1() {},
    async doCancel(id) {
      return this.History.getCancelTransaction(id);
    },
    async toCancelDetail(params) {
      await this.doCancel(params);
      if (!this.isError) {
        this.$router.push({
          name: "TransactionCancelledSuccess",
          params: { id: params },
        });
      }
    },
  },
};
</script>