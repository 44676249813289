<template>
  <div class="relative">
    <div class="h-full">
      <div class="mt-4 mx-[30px]">
        <div class="flex flex-col">
          <img
            alt="checkList"
            class="mx-auto w-11"
            :src="require(`@/assets/icon/checklistGreenIcon.svg`)"
          />
          <p class="mx-auto mt-3 font-semibold">Transaksi Berhasil</p>
          <p class="text-center mb-6">
            Transaksi Anda berhasil diproses dan menunggu persetujuan dari perusahaan
          </p>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Nomor Referensi</div>
          <div>{{ HistoryDetail.requestTransactionId }}</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Tanggal Transaksi</div>
          <div>
            {{
              moment(HistoryDetail.createdAt).locale("id").format("DD-MM-YYYY")
            }}
          </div>
        </div>

        <div class="flex flex-row justify-between mb-4">
          <div class="font-semibold">Waktu Transaksi</div>
          <div>
            {{ moment(HistoryDetail.createdAt).locale("id").format("LTS") }}
            WIB
          </div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">No. Rekening</div>
          <div>{{ HistoryDetail.bankAccountNumber }}</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Nama Penerima</div>
          <div>{{ HistoryDetail.bankAccountName }}</div>
        </div>

        <div class="flex flex-row justify-between mb-4">
          <div class="font-semibold">Bank Tujuan</div>
          <div>{{ HistoryDetail.bankAccount }}</div>
        </div>

        <hr class="mb-4" />

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Nominal</div>
          <div>{{ convertRupiah(HistoryDetail.amount) }}</div>
        </div>

        <div class="flex flex-row justify-between mb-3.5">
          <div class="font-semibold">Biaya Admin</div>
          <div>- {{ convertRupiah(HistoryDetail.fee) }}</div>
        </div>

        <div class="flex flex-row justify-between">
          <div class="font-semibold">Total</div>
          <div class="font-semibold">
            {{ convertRupiah(HistoryDetail.amountTransfer) }}
          </div>
        </div>
      </div>
      <div class="w-44 mx-auto mt-6 mb-20">
        <button-primary :onClick="sendEmail">Download</button-primary>
        <!-- <div v-if="isErrorTrans">
          <p class="text-center text-red-star">{{ errorCauseTrans }}</p>
        </div>
        <div v-else>
          <div v-if="isSuccess">
            <p class="text-center text-[#16a34a]">{{ emailMsg }}</p>
          </div>
        </div> -->
      </div>
    </div>
    <button-bottom :Click="toHome">Kembali ke Menu</button-bottom>
  </div>
</template>
<script>
import { TransactionController } from "@/controllers/TransactionController.js";
import { formatPrice } from "@/utils/helper.js";
import moment from "moment";
import { HistoryController } from "@/controllers/HistoryController.js";
import ButtonBottom from "@/components/ButtonBottom.vue";
import ButtonPrimary from "@/components/ButtonPrimary.vue";
import NavbarWhite from "@/components/NavbarWhite.vue";
export default {
  name: "TransactionSuccess",
  components: {
    ButtonBottom,
    ButtonPrimary,
    NavbarWhite,
  },
  data() {
    return {
      isSuccess: false,
      moment: moment,
      History: new HistoryController(false, false, ""),
      email: new TransactionController(false, false, ""),
    };
  },
  computed: {
    isError() {
      return this.History.error;
    },
    HistoryDetail() {
      return this.History.historyDetail;
    },
    errorCause() {
      return this.History.errorCause;
    },

    isLoading() {
      return this.History.loading;
    },
    isErrorTrans() {
      return this.email.error;
    },
    emailMsg() {
      return this.email.emailMsg;
    },
    errorCauseTrans() {
      return this.email.errorCause;
    },

    isLoadingTrans() {
      return this.email.loading;
    },
  },
  methods: {
    convertRupiah(money) {
      return formatPrice(money);
    },
    async getDetailHistory(id) {
      return this.History.getHistoryDetail(id);
    },
    async gethistoryDetail() {
      await this.getDetailHistory(this.$route.params.id);
    },
    goBack() {
      this.$router.go(-1);
    },
    successMsg() {
      this.isSuccess = false;
    },
    async doSendEmail(id) {
      return this.email.download(id);
    },
    async sendEmail() {
      await this.doSendEmail(this.$route.params.id);
      if (!this.isErrorTrans) {
        // this.isSuccess = true;
        // setTimeout(this.successMsg, 2500);
        this.$toast.open({
          message: "File telah dikirim ke Email anda",
          type: "success",
          duration: 2000,
          dismissible: true,
          position: "bottom",
        });
      } else {
        this.$toast.open({
          message: "Gagal Download Email",
          type: "error",
          duration: 2000,
          dismissible: true,
          position: "bottom",
        });
      }
    },
    toHome() {
      this.$router.push("/home");
    },
  },
  created() {
    this.gethistoryDetail();
    console.log(this.$route.params.id);
  },
};
</script>