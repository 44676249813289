
import { GetTotalEmployeesRequest } from '../api/request/HelperRequest'
import { sessionExpired } from "@/utils/helper";

export class HelperController {
    loading = false;
    error = false;
    errorCause = "";

    totalEmployees = []


    constructor(loading, error, errorCause,) {
        this.loading = loading;
        this.error = error;
        this.errorCause = errorCause;


    }

    getEmployees() {
        this.setLoading(true)
        let resp = null

        resp = GetTotalEmployeesRequest()
            .then((response) => {
                this.setEmployee(response.data.data)
                this.setErrorCause("")
                this.setError(false)
                return true
            }).catch((err) => {

                if (err.response.data.code === 404) {
                    this.setErrorCause('Data Tidak Ditemukan')
                    this.setError(false)


                } else if (err.response.data.message == "Access token expired!") {
                    this.setErrorCause(err)
                    this.setError(true)
                    sessionExpired()
                } else {
                    this.setErrorCause(err.response.data.message)
                    this.setError(true)
                }

                return false
            }).finally(() => {
                this.setLoading(false)
            })
        return resp
    }





    setEmployee(data) {
        this.totalEmployees = data
    }

    setLoading(status) {
        this.loading = status
    }

    setError(err) {
        this.error = err
    }

    setErrorCause(cause) {
        this.errorCause = cause
    }
}