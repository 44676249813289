import Vue from 'vue'
import VueRouter from 'vue-router'
import jwt_decode from "jwt-decode";
import swal from 'sweetalert'
import { getOnboarding, } from "@/utils/cookies";
import { getVerified, getOldPin, removeAuth } from "@/utils/localstorage";
import CryptoJS from "crypto-js";
import { getToken } from "@/utils/localstorage"
import Register from "../views/Register";
import Login from "../views/Login";
import RegisterSuccess from "../views/RegisterSuccess";
import ResetPasswordSuccess from "../views/ResetPasswordSuccess";
import ResetPassword from "../views/ResetPassword";
import ChangePassword from "../views/ChangePassword";
import ChangePasswordSuccess from "../views/ChangePasswordSuccess";
import FAQView from "../views/FAQ";
import HelpView from "../views/Help";
import OnBoarding from "../views/OnBoarding";
import TransactionSummary from "../views/TransactionSummary";
import TransactionHistory from "../views/TransactionHistory";
import TransactionSuccess from "../views/TransactionSuccess";
import TransactionDetail from "../views/TransactionDetail";
import TransactionCancelledSuccess from "../views/TransactionCancelledSuccess";
import Profile from "../views/Profile";
import Home from "../views/Home";
import Notification from "../views/Notification";
import Activity from "../views/Notification/Activity.vue";
import Inbox from "../views/Notification/Inbox.vue";
import SetupPin from "../views/SetupPin";
import HomeView from "../views/HomeView.vue";
import Setuppin from "../views/SetupPin/SetupPin.vue";
import SetupPinSuccess from "../views/SetupPin/Success.vue";
import SetupPinConfirm from "../views/SetupPin/Confirmation.vue";
import ChangePin from "../views/ChangePin";
import Changepin from "../views/ChangePin/ChangePin.vue";
import ChangePinSuccess from "../views/ChangePin/Success.vue";
import NewPin from "../views/ChangePin/NewPin.vue";
import NewPinConfirmation from "../views/ChangePin/NewPinConfirm.vue";
import ForgotPin from "../views/ForgotPin";
import NotFound from "../views/NotFound";
import { Date } from 'core-js';

Vue.use(VueRouter)



const routes = [
  { path: '/404', component: NotFound, name: 'NotFound' },
  { path: '*', redirect: '/404' },
  {
    path: '/register',
    name: 'Register',
    component: Register,

  },
  {
    path: '/forgotpin',
    name: 'ForgotPin',
    component: ForgotPin,
    meta: {
      auth: true,
      pin: true,
    }
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,

  },
  {
    path: '/register-success',
    name: 'RegisterSuccess',
    component: RegisterSuccess,

  },
  {
    path: '/resetpass-success',
    name: 'ResetPasswordSuccess',
    component: ResetPasswordSuccess,

  },
  {
    path: '/resetpass',
    name: 'ResetPassword',
    component: ResetPassword,

  },

  {
    path: '/changepass-success',
    name: 'ChangePasswordSuccess',
    component: ChangePasswordSuccess,
    meta: {
      auth: true,
      pin: true,
    }
  },
  {
    path: '/changepass',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      auth: true,
      pin: true,
    }
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      auth: true,
      pin: true,
    }
  },
  {
    path: '/onboard',
    name: 'OnBoarding',
    component: OnBoarding,


  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQView,

  },
  {
    path: '/help',
    name: 'Help',
    component: HelpView,

  },
  {
    path: '/transaction-summary',
    name: 'TransactionSummary',
    component: TransactionSummary,
    meta: {
      auth: true,
      pin: true,
    }
  },
  {
    path: '/transaction-success/:id',
    name: 'TransactionSuccess',
    component: TransactionSuccess,
    meta: {
      auth: true,
      pin: true,
    }
  },
  {
    path: '/transaction-history',
    name: 'TransactionHistory',
    component: TransactionHistory,
    meta: {
      auth: true,
      pin: true,
    }
  },
  {
    path: '/transaction-cancelled/:id',
    name: 'TransactionCancelledSuccess',
    component: TransactionCancelledSuccess,
    meta: {
      auth: true,
      pin: true,
    }
  },
  {
    path: '/changepin',
    component: ChangePin,

    children: [
      {
        path: "",
        component: Changepin,
        name: "ChangePin",
      },
      {
        path: 'success',
        component: ChangePinSuccess,
        name: "ChangePinSuccess",
      },
      {
        path: 'newpin',
        component: NewPin,
        name: "NewPin",
      },
      {
        path: 'confirmation',
        component: NewPinConfirmation,
        name: "NewPinConfirmation",
      },

    ],
    meta: {
      auth: true,
      pin: true,
    }
  },


  {
    path: '/setuppin',
    component: SetupPin,


    children: [
      {
        path: "",
        name: "SetupPin",
        component: Setuppin,
      },
      {
        path: 'success',
        component: SetupPinSuccess,
        name: "SetupPinSuccess",
      },
      {
        path: 'confirmation',
        component: SetupPinConfirm,
        name: "SetupPinConfirm",
      },

    ],
    meta: {
      auth: true,
      Pin: true
    }
  },


  {
    path: '/transaction-detail/:id',
    name: 'TransactionDetail',
    component: TransactionDetail,
    meta: {
      auth: true,
      pin: true,
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      auth: true,
      pin: true,
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,

    meta: {
      auth: true,
      pin: true,
    }
  },
  {
    path: '/notification',
    component: Notification,
    name: "Notification",
    children: [
      {
        path: 'activity',
        component: Activity,

      },
      {
        path: 'inbox',
        component: Inbox,


      },
    ],
    meta: {
      auth: true,
      pin: true,
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

})

router.beforeEach((to, from, next) => {
  function checkOnboard() {
    const onBoard = getOnboarding();
    return onBoard
  }
  function checkAuth() {
    const token = getToken();
    return token
  }
  function checkOldPin() {
    let oldPin = getOldPin()
    return oldPin
  }

  function checkVerified() {
    let verified = getVerified()
    return verified
  }
  const ms = 1667978640000
  const date = new Date(ms)
  const now = new Date().getTime()
  const dateNow = new Date(now)

  const isVerified = checkVerified();
  const isAuth = checkAuth();

  const isOnboard = checkOnboard();
  let isOldPin = checkOldPin()
  window.scrollTo(0, 0)

  if (to.name === "Login" && !isOnboard || to.name === "Register" && !isOnboard)
    next({ name: "OnBoarding" })
  else next()



  if (to.matched.some(record => record.meta.auth)) {
    if (!isAuth) {
      removeAuth()
      next({ name: "Login" })
    } else {
      next()
    }
  } else {
    next()
  }

  // if (to.matched.some(record => record.meta.auth)) {
  //   if ( dateNow > date) {
  //      swal({
  // //       title: "Attention",
  // //       text: "Sesi habis, Silahkan login kembali",
  // //       type: "success"
  // //     }, function () {
  // //       removeAuth()
  // //       next({ name: "Login" })
  // //     });} else {
  //     next()
  //   }
  // } else {
  //   next()
  // }

  // if (to.matched.some(record => record.meta.auth)) {
  //   if (!isAuth) {
  //     removeAuth()
  //     next({ name: "Login" })
  //   } else {
  //     next()
  //   }
  //   if (dateNow > date) {
  //     swal({
  //       title: "Attention",
  //       text: "Sesi habis, Silahkan login kembali",
  //       type: "success"
  //     }, function () {
  //       removeAuth()
  //       next({ name: "Login" })
  //     });
  //   } else {
  //     next()
  //   }
  // } else {
  //   next()
  // }

  if (to.matched.some(record => record.meta.pin)) {
    if (!isOldPin) {
      next("/setuppin")
    } else {
      next()
    }
  } else {
    next()
  }

  if (to.name === "NewPin" && !isVerified || to.name === "NewPinConfirmation" && !isVerified) {
    next("/changepin")
  }

  if (to.matched.some(record => record.meta.Pin)) {
    if (isOldPin != "") {
      next("/home")
    } else {
      next()
    }
  } else {
    next()
  }



  if (to.name === "Login" && isAuth || to.name === "Register" && isAuth || to.name === "OnBoarding" && isAuth)
    next({ name: "Home" })
  else next()

  if (to.path == "/notification")
    next("/notification/activity")
  else next()


  if (to.path === "/")
    next("/login")
  else (next)


  if (to.name === "OnBoarding" && isOnboard) {
    next()
    return
  }

  next()
})

export default router
