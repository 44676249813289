<template>
  <div class="mt-8">
    <div class="mx-[15px]">
      <div v-if="isLoading" class="mt-11">
        <inbox-loading/>
      </div>

      <div v-else>
        <div v-if="InboxList.success == false">
          <transaction-empty msg="Belum ada inbox"/>
        </div>
        <div v-else>
          <div
            v-for="item in InboxList"
            :key="item.id"
            class="
              flex flex-col
              border-b-2
              mb-2
              border-[#EDEDED]
              hover:bg-[#F2F5FA]
            "
          >
            <div class="h-[430px] mx-auto" v-if="!item.image">
              <img
                alt="noPic"
                class="h-full object-fill"
                :src="require(`@/assets/picture/NoPic.svg`)"
              />
            </div>
            <div class="h-[350px] mx-auto" v-else>
              <img
                alt="profilePicture"
                class="object-cover object-top h-full w-full rounded-lg"
                :src="item.image"
              />
            </div>

            <div class="mt-1 font-semibold">{{ item.title }}</div>
            <div>{{ item.content }}</div>
            <div class="text-sm text-loading-greytxt">
              {{ moment(item.createdAt).locale("id").format("DD-MM-YYYY") }}
              <span>{{
                moment(item.createdAt).locale("id").format("LT")
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InboxLoading from "@/components/InboxLoading.vue"
import TransactionEmpty from "@/components/TransactionEmpty.vue";
import { NotificationController } from "@/controllers/NotificationController.js";
import LoadingBlue from "@/components/LoadingBlue.vue";
import moment from "moment";
import InboxLoadingVue from '@/components/InboxLoading.vue';
export default {
  name: "Inbox",
  components: {
    TransactionEmpty,
    LoadingBlue,InboxLoading
  },
  data() {
    return {
      moment: moment,
      meta: {
        page: 1,
        size: "",
      },
      notif: new NotificationController(false, false, ""),
    };
  },
  computed: {
    isError() {
      return this.notif.error;
    },
    InboxList() {
      return this.notif.inboxLists;
    },
    errorCause() {
      return this.notif.errorCause;
    },

    isLoading() {
      return this.notif.loading;
    },
  },

  mounted() {
    this.getInbox();
  },
  methods: {
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + `...` : str;
    },
    async getInboxlist(page, size) {
      return this.notif.getInboxList(page, size);
    },
    async getInbox() {
      await this.getInboxlist(this.meta.page, this.meta.size);
    },
  },
};
</script>