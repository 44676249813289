<template>
  <div class="relative h-screen">
    <NavBar
      :onClickBack="toLogin"
      title="Register"
      srcPictureLeft="backIcon.svg"
    />

    <div class="mt-4">
      <form>
        <div class="mb-4 mx-[30px]">
          <label
            class="block text-black-form text-lg font-normal mb-2"
            for="nama"
          >
            Nama Lengkap<span class="text-red-star"><sup>*</sup></span>
          </label>

          <input
            class="
              shadow
              appearance-none
              outline outline-1
              rounded
              border-none
              w-full
              py-2
              px-3
              text-grey-darker
              h-11
            "
            :class="{
              'outline-grey-input': !errorMsg.nama,
              'outline-red-star': errorMsg.nama,
            }"
            v-model="name"
            id="nama"
            type="text"
            placeholder="Contoh: John Doe"
          />
          <p v-if="errorMsg.nama" class="text-red-star">
            {{ errorMsg.nama }}
          </p>
        </div>

        <div class="mb-4 mx-[30px]">
          <label
            class="block text-black-form text-lg font-normal mb-2"
            for="password"
          >
            Nomor Telepon<span class="text-red-star"><sup>*</sup></span>
          </label>

          <div class="flex">
            <span
              class="border rounded-l h-11 pt-2 pl-2 flex w-20 bg-[#EDEDED]"
              :class="{
                'border-grey-input': !errorMsg.noTelp,
                'border-red-star': errorMsg.noTelp,
              }"
            >
              +62
              <img
                class="w-4 mx-auto -mt-2"
                src="@/assets/icon/Group 95.svg"
                alt="flag"
              />
            </span>
            <input
              @input="FilterPhoneCharacter()"
              pattern="[0-9]*"
              inputmode="numeric"
              class="
                shadow
                outline outline-1
                rounded-r
                w-full
                border-none
                px-3
                text-grey-darker
                mt-[1px]
                h-[42px]
              "
              :class="{
                'outline-grey-input': !errorMsg.noTelp,
                'outline-red-star': errorMsg.noTelp,
              }"
              v-model="phoneNo"
              id="password"
              type="number"
              placeholder="Contoh: 8123456789"
            />
          </div>
          <p v-if="errorMsg.noTelp" class="text-red-star">
            {{ errorMsg.noTelp }}
          </p>
        </div>

        <div class="mb-4 mx-[30px]">
          <label
            class="block text-black-form text-lg font-normal mb-2"
            for="email"
          >
            Email<span class="text-red-star"><sup>*</sup></span>
          </label>

          <input
            class="
              shadow
              appearance-none
              outline outline-1
              rounded
              border-none
              w-full
              py-2
              px-3
              text-grey-darker
              h-11
            "
            :class="{
              'outline-grey-input': !errorMsg.email,
              'outline-red-star': errorMsg.email,
            }"
            v-model="email"
            id="email"
            type="email"
            placeholder="Contoh: johndoe@email.com"
          />
          <p v-if="errorMsg.email" class="text-red-star">
            {{ errorMsg.email }}
          </p>
        </div>

        <div class="mb-4 mx-[30px]">
          <label
            class="block text-black-form text-lg font-normal mb-2"
            for="namaPerusahaan"
          >
            Nama Perusahaan<span class="text-red-star"><sup>*</sup></span>
          </label>

          <input
            class="
              shadow
              appearance-none
              outline outline-1
              rounded
              border-none
              w-full
              py-2
              px-3
              text-grey-darker
              h-11
            "
            :class="{
              'outline-grey-input': !errorMsg.company,
              'outline-red-star': errorMsg.company,
            }"
            id="namaPerusahaan"
            type="text"
            placeholder="Contoh: PT. Indonesia Jaya"
            v-model="namaPerusahaan"
          />
          <p v-if="errorMsg.company" class="text-red-star">
            {{ errorMsg.company }}
          </p>
        </div>

        <div class="mb-4 mx-[30px]">
          <label
            class="block text-black-form text-lg font-normal mb-2"
            for="namaPerusahaan"
          >
            Jumlah Karyawan<span class="text-red-star"><sup>*</sup></span>
          </label>
          <select
            class="
              shadow
              outline
              border-none
              outline-1
              rounded
              w-full
              py-2
              px-3
              h-11
            "
            :class="{
              'outline-grey-input': !errorMsg.employee,
              'outline-red-star': errorMsg.employee,
            }"
            v-model="selected"
          >
            <option class="text-grey-darker" disabled value="">
              Pilih Jumlah
            </option>
            <option
              v-for="(option, index) in employee"
              :value="option"
              :key="index"
            >
              {{ option }}
            </option>
          </select>
          <p v-if="errorMsg.employee" class="text-red-star">
            {{ errorMsg.employee }}
          </p>
        </div>

        <div class="mb-4 mx-[30px]">
          <label
            class="block text-black-form text-lg font-normal mb-2"
            for="namaPerusahaan"
          >
            Alamat Perusahaan<span class="text-red-star"><sup>*</sup></span>
          </label>

          <textarea
            v-model="alamatPerusahaan"
            @input="FilterCharacter()"
            style="resize: none"
            class="
              shadow
              appearance-none
              outline outline-1
              rounded
              w-full
              border-none
              py-2
              px-3
              text-grey-darker
              h-[75px]
            "
            :class="{
              'outline-grey-input': !errorMsg.address,
              'outline-red-star': errorMsg.address,
            }"
            id="namaPerusahaan"
            type="text"
            placeholder="Max. 150 Karakter"
            @keyup.enter="Regist"
          />
          <p v-if="errorMsg.address" class="text-red-star">
            {{ errorMsg.address }}
          </p>
        </div>
        <div class="mb-4 mx-[30px]">
          <div class="flex">
            <div>
              <input
                class="w-6 h-6 border border-grey-input rounded"
                type="checkbox"
                id="checkbox"
                v-model="checkBox"
              />
            </div>

            <div class="-mt-1 ml-3">
              <label for="checkbox" class="text-base">
                Saya bersedia dihubungi oleh pihak hakku untuk penawaran lebih
                lanjut</label
              >
            </div>
          </div>
          <p v-if="errorMsg.checkBox" class="text-red-star text-center">
            {{ errorMsg.checkBox }}
          </p>
        </div>
        <div class="mx-[30px]">
          <button-primary :isLoading="isLoading" :onClick="Regist"
            >Daftar</button-primary
          >
        </div>
        <div class="text-transparent">p</div>
      </form>
    </div>
  </div>
</template>
<script>
import { AuthControllers } from "@/controllers/AuthControllers.js";
import { HelperController } from "@/controllers/HelperController.js";
import NavBar from "@/components/NavBar.vue";
import ButtonPrimary from "@/components/ButtonPrimary.vue";
export default {
  name: "Register",
  components: {
    NavBar,
    ButtonPrimary,
  },
  data() {
    return {
      errorMsg: {
        nama: "",
        noTelp: "",
        email: "",
        company: "",
        employee: "",
        address: "",
        checkBox: "",
      },
      checkBox: false,
      regist: new AuthControllers(false, false, ""),
      helper: new HelperController(false, false, ""),
      name: "",
      email: "",
      namaPerusahaan: "",
      phoneNo: "",
      selected: "",

      alamatPerusahaan: "",
      maxCharacter: 150,
      minPhoneChar: 9,
      maxPhoneChar: 15,
    };
  },
  computed: {
    isError() {
      return this.regist.error;
    },

    errorCause() {
      return this.regist.errorCause;
    },

    employee() {
      return this.helper.totalEmployees;
    },

    isLoading() {
      return this.regist.loading;
    },
  },
  watch: {
    checkBox(value) {
      this.checkBox = value;
      this.validateCheckBox(value);
    },
    email(value) {
      this.email = value;
      this.validateEmail(value);
    },
    name(value) {
      this.name = value;
      this.validateName(value);
    },
    namaPerusahaan(value) {
      this.namaPerusahaan = value;
      this.validateNamaPerusahaan(value);
    },
    phoneNo(noTelp) {
      this.phoneNo = noTelp;
      this.validatePhoneNo(noTelp);
    },
    selected(value) {
      this.selected = value;
      this.validateSelected(value);
    },
    alamatPerusahaan(value) {
      this.alamatPerusahaan = value;
      this.validateAlamatPerusahaan(value);
    },
  },
  methods: {
    validateAlamatPerusahaan(value) {
      if (value == "") {
        this.errorMsg.address = "Alamat Perusahaan tidak boleh Kosong";
      } else {
        this.errorMsg.address = "";
      }
    },
    validateSelected(value) {
      if (value == "") {
        this.errorMsg.employee = "Jumlah Karyawan tidak boleh kosong";
      } else {
        this.errorMsg.employee = "";
      }
    },
    validateName(value) {
      if (value == "") {
        this.errorMsg.nama = "Nama Lengkap tidak boleh kosong";
      } else {
        this.errorMsg.nama = "";
      }
    },
    validatePhoneNo(noTelp) {
      if (this.phoneNo == "") {
        this.errorMsg.noTelp = "Nomor Telepon tidak boleh kosong";
      } else if (this.phoneNo.charAt(0) == "0") {
        this.errorMsg.noTelp = "Format No Telpon Salah";
      } else if (this.phoneNo.length <= this.minPhoneChar) {
        this.errorMsg.noTelp = "Nomor Telepon minimal 9 karakter";
      } else {
        this.errorMsg.noTelp = "";
      }
    },
    validateNamaPerusahaan(value) {
      if (/^[A-Za-z\s]*$/.test(value)) {
        this.errorMsg.company = "";
      } else {
        this.errorMsg.company = "Nama Perusahaan hanya dapat diisi huruf";
      }
      if (value == "") {
        this.errorMsg.company = "Nama Perusahaan tidak boleh Kosong";
      }
    },
    validateCheckBox(value) {
      if (value == true) {
        this.errorMsg.checkBox = "";
      }
    },
    validateEmail(email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.errorMsg.email = "";
      } else {
        this.errorMsg.email = "Email tidak Valid";
      }
      if (email == "") {
        this.errorMsg.email = "Email tidak boleh Kosong";
      }
    },
    async doRegist(
      fullName,
      email,
      mobileNumber,
      companyName,
      companyTotalEmployee,
      companyAddress
    ) {
      return this.regist.register(
        fullName,
        email,
        mobileNumber,
        companyName,
        companyTotalEmployee,
        companyAddress
      );
    },
    checkZero(evt) {
      if (this.phoneNo.charAt(0) == "0") {
        this.errorMsg.noTelp = "Format No Telpon Salah";
        evt.preventDefault();
      } else {
        this.errorMsg.noTelp = "";
      }
    },
    Regist() {
      if (this.checkBox == false) {
        this.phoneNo.charAt(0), "ini characternya";
        this.errorMsg.checkBox = "Harap Menyetujui persyaratan";
        if (this.name == "") {
          this.errorMsg.nama = "Nama Lengkap tidak boleh ksoong";
        }
        if (this.phoneNo == "") {
          this.errorMsg.noTelp = "Nomor Telepon tidak boleh kosong";
        }
        if (this.email == "") {
          this.errorMsg.email = "Email tidak boleh kosong";
        }
        if (this.namaPerusahaan == "") {
          this.errorMsg.company = "Nama Perusahaan tidak boleh kosong";
        } else {
          this.errorMsg.company = "";
        }
        if (this.selected == "") {
          this.errorMsg.employee = "Jumlah Karyawan tidak boleh kosong";
        }
        if (this.alamatPerusahaan == "") {
          this.errorMsg.address = "Alamat Perusahaan tidak boleh kosong";
        }
      } else if (
        this.errorMsg.email == "Email tidak boleh kosong" ||
        this.errorMsg.email == "Email tidak Valid"
      ) {
      } else if (
        this.errorMsg.noTelp == "Nomor Telepon tidak boleh kosong" ||
        this.errorMsg.noTelp == "Format No Telpon Salah" ||
        this.errorMsg.noTelp == "Nomor Telepon minimal 9 karakter"
      ) {
      } else {
        this.errorMsg.checkBox = "";
        this.registAction();
      }
    },
    async registAction() {
      await this.doRegist(
        this.name,
        this.email,
        this.phoneNo ? `+62 ${this.phoneNo}` : this.phoneNo,
        this.namaPerusahaan,
        this.selected,
        this.alamatPerusahaan
      );
      if (!this.isError) {
        this.$router.push("/register-success");
      } else if (this.errorCause == "Not a valid picFullname") {
        this.errorMsg.nama = "Nama Lengkap tidak boleh ksoong";
        if (this.phoneNo == "") {
          this.errorMsg.noTelp = "Nomor Telepon tidak boleh kosong";
        }
        if (this.email == "") {
          this.errorMsg.email = "Email tidak boleh kosong";
        }
        if (this.namaPerusahaan == "") {
          this.errorMsg.company = "Nama Perusahaan tidak boleh kosong";
        } else {
          this.errorMsg.company = "";
        }
        if (this.selected == "") {
          this.errorMsg.employee = "Jumlah Karyawan tidak boleh kosong";
        }
        if (this.alamatPerusahaan == "") {
          this.errorMsg.address = "Alamat Perusahaan tidak boleh kosong";
        }
      } else if (this.errorCause == "Not a valid picEmail") {
        this.errorMsg.email = "Email tidak boleh kosong";

        if (this.phoneNo == "") {
          this.errorMsg.noTelp = "Nomor Telepon tidak boleh kosong";
        }
        if (this.name == "") {
          this.errorMsg.nama = "Nama Lengkap tidak boleh ksoong";
        }
        if (this.namaPerusahaan == "") {
          this.errorMsg.company = "Nama Perusahaan tidak boleh kosong";
        } else {
          this.errorMsg.company = "";
        }
        if (this.selected == "") {
          this.errorMsg.employee = "Jumlah Karyawan tidak boleh kosong";
        }
        if (this.alamatPerusahaan == "") {
          this.errorMsg.address = "Alamat Perusahaan tidak boleh kosong";
        }
      } else if (this.errorCause == "Not a valid picMobileNumber") {
        this.errorMsg.noTelp = "Nomor Telepon tidak boleh kosong";

        if (this.email == "") {
          this.errorMsg.email = "Email tidak boleh kosong";
        }
        if (this.name == "") {
          this.errorMsg.nama = "Nama Lengkap tidak boleh ksoong";
        }
        if (this.namaPerusahaan == "") {
          this.errorMsg.company = "Nama Perusahaan tidak boleh kosong";
        } else {
          this.errorMsg.company = "";
        }
        if (this.selected == "") {
          this.errorMsg.employee = "Jumlah Karyawan tidak boleh kosong";
        }
        if (this.alamatPerusahaan == "") {
          this.errorMsg.address = "Alamat Perusahaan tidak boleh kosong";
        }
      } else if (this.errorCause == "Not a valid companyName") {
        this.errorMsg.company = "Nama Perusahaan tidak boleh kosong";

        if (this.email == "") {
          this.errorMsg.email = "Email tidak boleh kosong";
        }
        if (this.name == "") {
          this.errorMsg.nama = "Nama Lengkap tidak boleh ksoong";
        }
        if (this.phoneNo == "") {
          this.errorMsg.noTelp = "Nomer Telepon tidak boleh kosong";
        }
        if (this.selected == "") {
          this.errorMsg.employee = "Jumlah Karyawan tidak boleh kosong";
        }
        if (this.alamatPerusahaan == "") {
          this.errorMsg.address = "Alamat Perusahaan tidak boleh kosong";
        }
      } else if (this.errorCause == "Not a valid companyTotalEmployee") {
        this.errorMsg.employee = "Jumlah Karyawan tidak boleh kosong";

        if (this.email == "") {
          this.errorMsg.email = "Email tidak boleh kosong";
        }
        if (this.name == "") {
          this.errorMsg.nama = "Nama Lengkap tidak boleh ksoong";
        }
        if (this.phoneNo == "") {
          this.errorMsg.noTelp = "Nomer Telepon tidak boleh kosong";
        }
        if (this.namaPerusahaan == "") {
          this.errorMsg.company = "Nama Perusahaan tidak boleh kosong";
        } else {
          this.errorMsg.company = "";
        }
        if (this.alamatPerusahaan == "") {
          this.errorMsg.address = "Alamat Perusahaan tidak boleh kosong";
        }
      }else if (this.errorCause == "email is already exist") {
        this.errorMsg.email = "Email Sudah Digunakan";

        if (this.phoneNo == "") {
          this.errorMsg.noTelp = "Nomor Telepon tidak boleh kosong";
        }
        if (this.name == "") {
          this.errorMsg.nama = "Nama Lengkap tidak boleh ksoong";
        }
        if (this.namaPerusahaan == "") {
          this.errorMsg.company = "Nama Perusahaan tidak boleh kosong";
        } else {
          this.errorMsg.company = "";
        }
        if (this.selected == "") {
          this.errorMsg.employee = "Jumlah Karyawan tidak boleh kosong";
        }
        if (this.alamatPerusahaan == "") {
          this.errorMsg.address = "Alamat Perusahaan tidak boleh kosong";
        }
      } else if (this.errorCause == "Not a valid companyAddress") {
        this.errorMsg.address = "Alamat Perusahaan tidak boleh kosong";

        if (this.email == "") {
          this.errorMsg.email = "Email tidak boleh kosong";
        }
        if (this.name == "") {
          this.errorMsg.nama = "Nama Lengkap tidak boleh ksoong";
        }
        if (this.phoneNo == "") {
          this.errorMsg.noTelp = "Nomer Telepon tidak boleh kosong";
        }
        if (this.namaPerusahaan == "") {
          this.errorMsg.company = "Nama Perusahaan tidak boleh kosong";
        } else {
          this.errorMsg.company = "";
        }
        if (this.selected == "") {
          this.errorMsg.employee = "Jumlah Karyawan tidak boleh kosong";
        }
      }
    },
    FilterCharacter() {
      if (this.alamatPerusahaan.length >= this.maxCharacter) {
        this.alamatPerusahaan = this.alamatPerusahaan.substring(
          0,
          this.maxCharacter
        );
      }
    },
    FilterPhoneCharacter() {
      if (this.phoneNo.length >= this.maxPhoneChar) {
        this.phoneNo = this.phoneNo.substring(0, this.maxPhoneChar);
      }
    },
    async getTotalEmployee() {
      return this.helper.getEmployees();
    },
    async getEmployee() {
      await this.getTotalEmployee();
    },
    toLogin() {
      this.$router.push("/login");
    },
    doRegister() {
      location.reload();
    },
  },
  mounted() {
    this.getEmployee();
  },
};
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>